<script setup lang="ts">
import { ref } from "vue";
import useResponsive from "@/utils/responsive-util"
import { useCommunityStore } from '@/store/communityStore'
import { storeToRefs } from "pinia";

//CONST
const { isMobile } = useResponsive()
const currentYear = ref(2024);
const communityStore = useCommunityStore();
const { community } = storeToRefs(communityStore)

</script>

<template>
    <div class="footer">
        <a href="https://www.cocoriko.org" class="no-border" target="_blank">
            <img
                class="footer__logo"
                src="@/assets/logos/logo-cocoriko.svg"
                alt="logo-cocoriko"
            />
        </a>

        <div class="footer__content">
            <div class="footer__content--left-side">
                <div class="footer__content--left-side--title">
                    {{ $t("footer.message") }}
                </div>
                <div class="footer__content--left-side--made-with">
                    {{ $t("footer.made") }}
                </div>
            </div>
            <div class="footer__content--right-side" v-if="!isMobile">
                <div class="footer__content--right-side--title">
                    {{ $t("footer.join-us") }}
                </div>
                <div class="footer__content--right-side--info" v-if="community">
                    <p>{{ community.contactInfo?.phone ?? `+1 819.484.0134` }}</p>
                    <a class="email"
                       :mailto="community.contactInfo?.email ?? 'info@cocoriko.org'">{{
                            community.contactInfo?.email ?? 'info@cocoriko.org'
                        }}</a>
                </div>
            </div>
        </div>
        <div class="footer__links" v-if="!isMobile">
            <span>© {{ currentYear }}</span>
            Cocoriko |
            <a :href="$t('terms.terms-of-service-link')" target="_blank">
                {{ $t("terms.terms-of-service") }}
            </a>
            |
            <a
                :href="$t('terms.privacy-policy-link')"
                target="_blank"
            >
                {{ $t("terms.privacy-policy") }}
            </a>
            |
            <a
                :href="$t('terms.moderation-policy-link')"
                target="_blank"
            >
                {{ $t("terms.moderation-policy") }}
            </a>

        </div>
    </div>

</template>

<style lang="scss">
.footer {
    height: 190px;
    background-color: $color-neutral-white;
    padding: $space-xl $space-3xl;

    &--logo {
        height: 22px;
        margin-bottom: $space-sm;
    }

    &__content {
        display: flex;
        justify-content: space-between;

        &--left-side {
            text-wrap: nowrap;

            &--title {
                font-weight: bold;
                color: $color-neutral-grey-400;
            }

            &--made-with {
                margin: $space-sm 0;

                .q-icon {
                    color: $color-primary-darken !important;
                    margin: 0 $space-xs;
                }
            }
        }

        &--right-side {
            padding-right: $space-3xl;

            &--title {
                font-weight: bold;
            }

            &--info {
                width: 100%;

                a {
                    display: inline-block;
                }
            }
        }
    }
}

@media screen and (max-width: $breakpoint-xl) {
    .footer {
        height: 160px;
        font-size: $small-text;

        &__content {
            &--right-side {
                padding-right: $space-md;
            }
        }
    }
}

@media screen and (max-width: $breakpoint-lg) {
    .footer {
        height: 150px;
        font-size: $xsmall-text;
        padding: $space-lg $space-xl;

        &__content {
            &--right-side {
                padding-right: $space-md;
            }
        }
    }
}

@media screen and (max-width: $breakpoint-md) {
    .footer {
        height: 140px;
        padding: $space-lg $space-xl;

        &__content {
            &--right-side {
                padding-right: $space-md;
            }
        }
    }
}

@media screen and (max-width: $breakpoint-sm) {
    .footer {
        height: 170px;
        padding: $space-lg $space-xl;

        &__links {
            margin-top: $space-xxs;
        }

        &__content {
            flex-wrap: wrap;

            &--left-side {
                &--made-with {
                    margin: $space-xxs 0;
                }
            }

            &--right-side {
                padding: 0;
            }
        }
    }
}
</style>
