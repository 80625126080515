<script setup lang="ts">

import BaseButton from '@/components/base/BaseButton.vue';

import {getTranslation} from "@/utils/data-display-utils";
import {useCommunityStore} from "@/store/communityStore";
import {constant} from '@/constant'
import {storeToRefs} from "pinia";

// PROPS

const communityStore = useCommunityStore();
const {community, customPageLoaded}= storeToRefs(communityStore)
const aboutPage = community.value.customPages.find(p => p.type === constant.Community.CustomPage.About)

</script>

<template >
<div class="about-page">
  <div class="about-page__content">
    <div class="about-page__content-consultation" v-if="customPageLoaded && aboutPage" >
      <div v-html="getTranslation(aboutPage, 'content')"></div>
    </div>
    <q-separator/>
    <div class="about-page__content-cocoriko">
      <BaseButton isOrange class="contact" href="mailto:info@cocoriko.org">{{$t('about.contact')}}</BaseButton>
      <div class="comments">
        {{$t('about.questions-comments')}}
        <a href="mailto:info@cocoriko.org">info@cocoriko.org</a>
      </div>

      <div class="title">{{$t('about.about-us')}} Cocoriko</div>
      <div class="about">{{$t("about.cocoriko-platform", { client: getTranslation(communityStore.community, 'title') }) }}</div>
      <BaseButton primary class="about-button" href="https://www.cocoriko.org/">{{$t('about.about-us')}} Cocoriko</BaseButton>
    </div>
  </div>
   
</div>

</template>

<style lang="scss">
.about-page{
	display: flex;
	flex-direction: row;
  justify-content: center;
  margin-top: $space-xl;
  font-size: $body-text;

  &__content {
    max-width: 720px;

    &-consultation {
      font-size: $header;
      margin-bottom: $space-lg;
    }

    &-cocoriko {
      margin-top: $space-lg;

      a {
        text-decoration: underline;
        color: $color-primary;
      }

      .title {
        font-size: $header;
        padding: $space-sm 0 ;
      }

      .comments {
        padding: $space-sm 0;
      }

      .about {
        padding: $space-sm 0;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-sm) {
.about-page {
  padding: $space-md;
}
}
</style>