import apiService from "@/services/api.service";
import ENDPOINTS from "@/services/endpoints";
import type {Avatar} from "@/store/userStore";

class UploadService {
    async postProfileImage(image: Avatar): Promise<Avatar> {
        try {
            // return await apiService.post(ENDPOINTS.profileImage, image);
            return await new Promise<Avatar>((resolve, reject) => {
                setTimeout(() => {
                    resolve({
                        cropped: "https://images.unsplash.com/photo-1518020382113-a7e8fc38eac9?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1617&q=80",
                        original: "https://images.unsplash.com/photo-1518020382113-a7e8fc38eac9?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1617&q=80"
                    });
                }, 100);
            });
        } catch (error) {
            console.error(error);
            throw error;
        }
    }
}

export const upLoadService = new UploadService();