<script setup lang="ts">
import { useField } from 'vee-validate';

const props = defineProps<{
    name: string,
    label: string,
    disabled?: boolean,
    type?: string,
}>();
const { value: value, errorMessage: errorMessage } = useField<string>(props.name);
</script>

<template>
    <div class="validation-input">   
        <q-input
            v-model="value"
            v-bind="$attrs"
            :error-message="errorMessage"
            :disable="disabled"
            :type="type"
        />
    </div>
</template>
<style lang="scss">
.validation-input {
    input{
        background-color: $color-neutral-white;
        padding: $space-sm;
        border-radius: 4px
    }
}
</style>