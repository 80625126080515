<script setup lang="ts">
import BaseButton from "@/components/base/BaseButton.vue";

// PROPS
const props = defineProps<{
    icon?: string;
    text: string;
}>();

</script>

<template>
    <BaseButton
            has-border
            class="login-button"
            :colorIcon="props.icon" :text="props.text"
    />

</template>

<style lang="scss">

.login-button {
  border: $color-neutral-grey-300 1px solid !important;
  width: 100% !important;
}

</style>