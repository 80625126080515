<script setup lang="ts">

import { QCheckbox } from 'quasar';
import { getTranslation } from "@/utils/data-display-utils";

// PROPS
const props = defineProps<{
    layers: any[];
}>();

// EMITS
const emit = defineEmits<{
    (e: "updateVisibility", layer): void;
}>();

// FUNCTIONS
function updateVisibility(layer) {
    emit('updateVisibility', layer);
}

</script>
<template>
    <div class="map-legend">
        <div class="map-legend__item" v-for="layer in props.layers">
            <div>
                <q-checkbox size="xs" v-model="layer.defaultVisibility" @click="updateVisibility(layer)"/>
            </div>
            <div>{{ getTranslation(layer, "name") }}</div>
            <div class="image">
                <img :src="`https://s3.ca-central-1.amazonaws.com/cocoriko.dev.communities/mapMarkers/png/mapbox_marker_${layer.color}.png`"/>
            </div>
        </div>
    </div>
</template>
<style lang="scss">
.map-legend {
    display: flex;
    flex-direction: column;
    padding-top: $space-sm;
    gap: $space-xs;

    &__item {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: $space-sm;

        .image img {
            height: 25px;
        }
    }
}
</style>