<script setup lang="ts">

import { ref } from 'vue';
import MapToolbar from '../map/MapToolbar.vue';
import Map from '../map/Map.vue';
import FullscreenMap from './FullscreenMap.vue';

// PROPS
const props = defineProps<{
    id: string;
    mapConfig: any;
    mapMarkers: any[];
    consultationIsCompleted: boolean;
}>();

// EMITS
const emit = defineEmits<{
    (e: "submitMarker", marker);
    (e: "verifyUser"): void;
}>();


// CONST
const showInfo = ref<boolean>(false);
const isFullscreen = ref<boolean>(false);

// FUNCTIONS 
function toggleInfo() {
    showInfo.value = !showInfo.value;
}

function openFullscreenMap() {
    isFullscreen.value = true;
}

function exitFullscreenMap() {
    isFullscreen.value = false;
}

function closeInfo() {
    showInfo.value = false;
}

function submitMarker(marker) {
    emit('submitMarker', marker)
}

function verifyUser(){
    emit('verifyUser')
}

</script>
<template>
    <div class="map-container" :class="{'fullscreen': isFullscreen}" id="map-container">
        <MapToolbar :is-fullscreen="isFullscreen" @open-fullscreen="openFullscreenMap" @exit-fullscreen="exitFullscreenMap" @info="toggleInfo"
                    :id="props.id"/>
        <Map @show-info="closeInfo" :show-info="showInfo" :id="props.id" :mapConfig="props.mapConfig"
             :map-markers="props.mapMarkers" :is-fullscreen="isFullscreen" @marker="submitMarker" @verify-user="verifyUser" :consultation-is-completed="consultationIsCompleted"/>
    </div>
</template>
<style lang="scss">
.map-container {
    height: 100%;
    display: flex;
    flex-direction: column;


    &.fullscreen{
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        z-index: 999;
        transition: all 0.3s;
        overflow: hidden;
        padding: 1px;
        background-color: $color-neutral-grey-300;
        padding: 12px;
    }
}
</style>