<script setup lang="ts">

import { ref } from 'vue';
import BaseButton from '../base/BaseButton.vue';

// PROPS
const props = defineProps<{
    isFullscreen?: boolean;
    id: string;
}>();

// EMITS
const emit = defineEmits<{
    (e: "openFullscreen"): void,
    (e: "exitFullscreen"): void,
    (e: "info"): void;
}>();

// CONST
const warningVisible = ref<boolean>(false);

// FUNCTIONS
function openFullscreen() {
    emit('openFullscreen');
}

function exitFullscreen() {
    emit('exitFullscreen');
}

function openInfo() {
    emit('info');
}

function showSearchWarning() {
    warningVisible.value = true;
    setTimeout(() => {
        warningVisible.value = false;
    }, 10000);
}

</script>
<template>
    <div class="map-toolbar" :class="{'fullscreenToolbar': isFullscreen}">
        <Transition name="search-warning">
            <div v-if="warningVisible" class="map-toolbar__warning">
                <q-icon color="orange" name="o_report_problem"/>
                <div>
                    {{ $t('map.search-warning') }}
                </div>
            </div>
        </Transition>
        <div class="map-toolbar__actions">
            <q-btn flat icon="o_info" @click="openInfo"/>
            <div :id="`search-box-container ${props.id}`" class="search-box-container" @click="showSearchWarning"></div>
            <BaseButton v-if="!isFullscreen" isIconOnly icon="fullscreen" @click="openFullscreen"/>
            <BaseButton v-else isIconOnly icon="fullscreen_exit" @click="exitFullscreen"/>
        </div>
    </div>
</template>
<style lang="scss">
.map-toolbar {
    display: flex;
    flex-direction: column;
    gap: $space-sm;
    margin-bottom: $space-md;

    &__warning {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 $space-md;
        gap: $space-sm;

        .q-icon {
            font-size: $h3;
        }
    }

    &__actions {
        display: flex;
        flex-direction: row;
        gap: $space-sm;
    }

    &.fullscreenToolbar {
        z-index: 2;
        position: sticky;
    }

    .q-btn {
        color: $color-primary-darken;
        background-color: $color-neutral-white;
        padding: 0px 8px;
        border-radius: 8px;
    }

    .search-box-container, mapbox-search-box {
        width: 100%;
    }

    .search-box-container > mapbox-search-box > div {
        box-shadow: none !important;
        width: 100%;
        border-radius: 8px;
    }

    .search-warning-enter-active,
    .search-warning-leave-active {
        transition: opacity 0.9s ease;
    }

    .search-warning-enter-from,
    .search-warning-leave-to {
        opacity: 0;
    }
}

</style>