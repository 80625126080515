<script setup lang="ts">
import { computed, ref, watch } from "vue";
import { rules } from "@/utils/validation-rules-util"

// PROPS
const props = withDefaults(defineProps<{
    model: string | number
    placeholder?: string
    disabled?: boolean
    type?: "text" | "number"
    readonly?: boolean
    label?: string
    rules?: string
    lazyRules?: boolean
    mask?: string
    hide?: boolean

}>(), {
    type: "text"
});

// EMITS
const emit = defineEmits<{
    (e: "update", newValue: string | number): void;
    (e: "toggleEye", hide: boolean): void;
}>();


// COMPUTED
const localModelValue = computed({
    get() {
        return modelValue.value;
    },
    set(newValue) {
        modelValue.value = newValue;
        emit("update", newValue);
    }
});

const getMask = computed(() => {
    return props.mask
})

const getRules = computed(() => {
    return rules[props.rules as keyof typeof rules]
})

//FUNCTIONS
function toggleEye() {
    isHidden.value = !isHidden.value
    emit("toggleEye", isHidden.value)
}

// REFS
const modelValue = ref<string | number>(props.model);
const isHidden = ref<boolean>(true)

watch(props, () => {
    if (props.hide) {
        modelValue.value = props.model
    }
});

</script>

<template>
    <q-input
        class="base-inputs"
        outlined
        v-model="localModelValue"
        :rules="getRules"
        :placeholder="props.placeholder"
        :disable="props.disabled"
        :readonly="props.readonly"
        :mask="getMask"
        :lazy-rules="props.lazyRules"
    >
        <template v-slot:append v-if="props.hide">
            <q-icon
                :name="isHidden ? 'visibility_off' : 'visibility'"
                class="cursor-pointer"
                @click="toggleEye()"
            />
        </template>
    </q-input>
</template>

<style lang="scss">
.base-inputs {
    width: 100%;

    &.q-field--readonly {
        .q-field__native {
            cursor: not-allowed !important;
        }

        .q-field__control {
            background-color: $color-neutral-grey-300 !important;
        }
    }

    .q-field {

        &__inner {
            background-color: $color-neutral-white;
            border-radius: 4px;
        }

        &__append {
            height: 44px;
        }

        &__bottom {
            padding: 4px 12px;
        }

        &__control {
            &--readonly {
                background-color: red !important;
            }

            height: 44px !important;
        }

        &__control:before {
            border: none !important;
        }
    }
}

input {
    border: 0 !important;

    &:focus {
        border: 0 !important;
    }
}
</style>