import { Config } from '@/config';
import apiService from "@/services/api.service";
import axios, { AxiosResponse, CancelTokenSource } from 'axios';
import { useCommunityStore } from '@/store/communityStore';
import {Context, DTOContext} from '@/store/context.interface';
import {constant} from "@/constant";

const CancelToken = axios.CancelToken;
let cancelTokenContexts: CancelTokenSource | null = null;

class ContextService {
  async getConsultationContext(parent_id: number): Promise<DTOContext[]> {

    if (cancelTokenContexts) {
      cancelTokenContexts.cancel('Multiple requests.');
    }

    cancelTokenContexts = CancelToken.source();

    const response = await apiService.get(
      `${Config.api.backend.urlV2}/consultations/${parent_id}/contexts`,
    );
    const contextData: any = response.data;
    if (contextData) {
      const communityStore= useCommunityStore();
      communityStore.setConsultationContext(contextData, parent_id);
    }
    return response.data;
  };
  async getSectionContext(consultationId: number, sectionId: number): Promise<DTOContext[]> {

    if (cancelTokenContexts) {
      cancelTokenContexts.cancel('Multiple requests.');
    }

    cancelTokenContexts = CancelToken.source();

    const response = await apiService.get(
      `${Config.api.backend.urlV2}/sections/${sectionId}/contexts`,
    );
    const contextData: any = response.data;

    if (contextData) {
      const communityStore = useCommunityStore();
      communityStore.setSectionContext(contextData, consultationId, sectionId);
    }
    return response.data;
  }
}

export const contextService = new ContextService();