<script setup lang="ts">
import { ref } from "vue";

import BaseDialog from "@/components/base/BaseDialog.vue";
import BaseImageUpload from "@/components/base/BaseImageUpload.vue";

type ImageType = "avatar" | "community" | "card";

const uploadImageDialogRef = ref<InstanceType<typeof BaseDialog>>();

// PROPS
const props = defineProps<{
    title: string
    image: string
    type: ImageType
    disabled?: boolean
}>();

// EMITS
const emit = defineEmits<{
    (e: "remove"): void
    (e: "upload", image: string): void
    (e: "update", image: string): void
}>();

// REFS
const image = ""

// FUNCTIONS
function openDialog() {
    uploadImageDialogRef.value.openDialog();
}

function closeDialog() {
    uploadImageDialogRef.value.closeDialog();
}

function uploadImage(image: string) {
    emit('upload', image)
    closeDialog()
}

function removeImage() {
    emit('remove')
    closeDialog()
}

function updateImage(value: string) {
    emit('update', value)
}

defineExpose({
    openDialog,
    closeDialog,
});
</script>

<template>
    <BaseDialog ref="uploadImageDialogRef" class="upload-dialog">
        <template v-slot:title>
            <div class="upload-dialog__title"> {{ props.title }}</div>
        </template>
        <template v-slot:content>
            <BaseImageUpload
                    :type="props.type"
                    :image="props.image"
                    @remove="removeImage"
                    @upload="uploadImage"
                    @update="updateImage"
                    :disabled="props.disabled"
            />
        </template>

    </BaseDialog>

</template>

<style lang="scss">
.upload-dialog {
  .base-dialog {
    max-width: 800px;
  }

  &__title {
    font-size: $small-title;
    font-weight: bold;
    width: 100%;
    display: flex;
  }
}

</style>