<script setup lang="ts">
import { ref, computed } from "vue";

//REFS
const cardIsOpen = ref<boolean>(false);
const isWhite = ref(true);

// PROPS
const props = defineProps<{
    icon?: string;
    isSmall?: boolean;
    primary?: boolean;
}>();

// FUNCTIONS
function toggleCard() {
    cardIsOpen.value = !cardIsOpen.value
}

// COMPUTED 
const styleClass = computed (() => ({
    'primary' : props.primary === true
}))

</script>

<template>
    <div class="base-collapsable" :class="styleClass">
        <div
          class="base-collapsable__title"
          :class="{'cardIsOpen': cardIsOpen}"
          @click="toggleCard()"
        >
            <q-icon
                v-if="props.icon"
                class="base-collapsable__title--icon"
                :class="{'small-icon' : isSmall}"
                :name= "props.icon"
            />
            <div 
                class="base-collapsable__title--text"
                :class="{'small-title' : isSmall}">
                <slot name="title"></slot>
            </div>
            <div class="base-collapsable__title--expand-icon">
                <div class="icon-container">
                    <q-icon
                        :class="{'rotate-180': !cardIsOpen}"
                        name="expand_more"
                    />
                </div>
            </div>
        </div>

        <div
          class="base-collapsable__description"
          :class="[ !cardIsOpen ? 'card-is-closed' : '', styleClass]"
        >
            <div class="base-collapsable__description--text">
                <slot name="content"></slot>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
.base-collapsable {

    font-size: $small-title;
    background-color: $color-neutral-white;
    padding: $space-md $space-lg;
    border-radius: 8px;
    font-weight: bold;

    &.primary {
        padding: 0 $space-lg;
        background-color: transparent !important;
    }

    &__title {
        display: flex;

        &--expand-icon {
            display: flex;
            flex-grow: 2;
            justify-content: flex-end;
            align-content: center;

            .icon-container{
                display: flex;
                align-items: center;
            }
        }

        &--icon {
            font-size: $large-title !important;
            margin-right: 10px;
            transition: 200ms;
            align-self: center;
        }

        &.cardIsOpen {
            color: $color-primary;
        }
    }

    &__description {
        background-color: $color-neutral-white;
        padding:  0 $space-lg;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        overflow: hidden;
        height: auto;
        max-height: 900px;
        transition: max-height 200ms ease-out;
        
        &--text {
            font-weight: 400;
        }
        &--show-more {
            font-weight: bold;
            margin-top: $space-md;
            width: fit-content;
            cursor: pointer;
            color: $color-neutral-black;
            transition: all 300ms ease;
            text-decoration: none;
            padding-bottom: $space-lg;

            &:hover {
                color: $color-neutral-black;
                border-color: black;
            }
        }

        &.primary {
            padding: 0;
            background-color: transparent !important;
        }
    }

    .border-radius-zero {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    .card-is-closed {
        max-height: 0;
    }
}
.small-title {
    font-size: $sub-header;
}
.small-icon {
    font-size: $medium-title !important;
}
</style>