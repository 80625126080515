<script setup lang="ts">

import BaseInput from "@/components/base/BaseInput.vue";
import BaseButton from "@/components/base/BaseButton.vue";
import { ref } from "vue";

const props = defineProps<{
    maxAnswers?: number;
    selectedResponses: number[];
    answerText: string;
    otherChoiceLabel: string;
}>();


const emit = defineEmits<{
    (e: "otherChoiceReset"): void; // Change event to emit selected responses
    (e: "updateAnswer", value: string): void;
}>();

const otherAnswer = ref<string>("");
const textField = ref<boolean>(false);


const enterOtherChoice = () => {

    if (textField.value) {
        otherAnswer.value = ""
    }
    if (props.selectedResponses.length < props.maxAnswers) {
        textField.value = !textField.value

    }
    emit("otherChoiceReset");
}
const updateAnswerOther = (value) => {
    otherAnswer.value = value;
    emit("updateAnswer", value);
}

</script>

<template>
    <div class="base-question-answers__text">
        <BaseButton
            class="primary-outline"
            :text="props.otherChoiceLabel"
            :is-selected="textField"
            :class="{ active: textField }"
            @click="enterOtherChoice"
            is-aligned="left"/>
        <q-input
            v-if="textField"
            :placeholder="$t('form.otherAnswerPlaceholder')"
            outlined
            @update:modelValue="updateAnswerOther"
            :model-value="otherAnswer"
        />

    </div>
</template>

<style scoped lang="scss">
.base-question-answers {
    &__image {
        width: 100%;

        .q-btn {
            padding: $space-sm 0;
            font-size: $body-text;
        }

        .active {
            background-color: $color-primary-12 !important;
            border: 3px solid $color-primary-50;
            font-weight: 600;
        }
    }

    &__text {
        .base-button {
            span {
                text-align: start !important;
            }
        }

        .active {
            background-color: $color-primary-12 !important;
            border: 3px solid $color-primary-50;
            font-weight: 600;
        }
    }
}
</style>