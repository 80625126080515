<script setup lang="ts">

import BaseTag from './BaseTag.vue';
import ConfirmFlag from '@/components/dialogs/ConfirmFlag.vue';
import LoginDialog from '../dialogs/LoginDialog.vue';

import { computed, ref } from "vue";
import { formatDistanceDateToNow } from "@/utils/date-util";
import { formsService } from '@/services/forms.service';
import { useUserStore } from "@/store/userStore";

// PROPS
const props = defineProps<{
    userComment: {
        admin_moderation_reason: boolean | null;
        comment: string;
        created_at: string;
        deleted_at: string;
        form_id: number;
        id: number;
        isMandatory: boolean;
        status: number;
        summary: string;
        toxicity: number;
        updated_at: string;
        vote: any;
        user: {
            first_name: string | null;
            last_name: string | null;
        }
        user_id: number;
        likes: any[];
        flags: any[];
    },
    isProposal: boolean;
    formId: number;
    consultationIsCompleted: boolean;
}>();
const userStore = useUserStore();


// CONST
const hasLike = ref<boolean>(userStore.isUserLogged ? props.userComment.likes?.findIndex(l => l.user_id === userStore.getUserId) >= 0 : false);
const hasFlag = ref<boolean>(userStore.isUserLogged ? props.userComment.flags?.findIndex(l => l.user_id === userStore.getUserId) >= 0 : false);
const confirmFlagDialogRef = ref(null);
const loginDialogRef = ref(null);
const likesCount = ref<number>(props.userComment.likes?.length || 0);
const flagsCount = ref<number>(props.userComment.flags?.length || 0);


// FUNCTIONS
function flagComment() {
    if (!props.consultationIsCompleted) {
        if (userStore.isUserLogged) {
            confirmFlagDialogRef.value.openDialog();
        } else {
            loginDialogRef.value.openDialog();
        }
    }
}

function submitFlag() {
    if (!props.consultationIsCompleted) {
        if (userStore.isUserLogged) {
            const commentId = props.userComment.id;
            const formId = props.formId;
            if (!hasFlag.value) {
                hasFlag.value = true;
                flagsCount.value++;
                formsService.postCommentAction(formId, commentId, 'flag');
            } else {
                hasFlag.value = false;
                flagsCount.value--;
                formsService.postCommentAction(formId, commentId, 'unflag');
            }
        }
    }

}

function likeComment() {
    if (!props.consultationIsCompleted) {
        if (userStore.isUserLogged) {
            const commentId = props.userComment.id;
            const formId = props.formId;
            if (!hasLike.value) {
                hasLike.value = true;
                likesCount.value++;
                formsService.postCommentAction(formId, commentId, 'like');
            } else {
                hasLike.value = false;
                likesCount.value--;
                formsService.postCommentAction(formId, commentId, 'unlike');
            }
        } else {
            loginDialogRef.value.openDialog();
        }
    }
}


// COMPUTED
const isDisagree = computed(() => {
    if (props.userComment.vote.value_id > 2) {
        return 'disagree';
    } else {
        return 'agree';
    }
})

const hasUser = computed(() => {
    if (props.userComment.user.first_name != null && props.userComment.user.last_name != null) {
        return true;
    } else {
        return false;
    }
})

</script>

<template>
    <div
        class="base-comment-card"
        :class="isProposal ? isDisagree : ''"
    >
        <div class="base-comment-card__comments">
            <div class="base-comment-card__comment">
                <div class="base-comment-card__comment-text">
                    {{ props.userComment.comment }}
                </div>
            </div>
            <div class="base-comment-card__actions">
                <div class="base-comment-card__actions-favorite" @click="likeComment">
                    <div class="base-comment-card__favorite-count">
                        {{ likesCount }}
                        <q-icon :name="hasLike ? 'favorite': 'favorite_border'"/>
                    </div>
                </div>
                <div class="base-comment-card__actions-flag" @click="flagComment">
                    <q-icon :name="hasFlag ? 'flag': 'outlined_flag'" class="count"/>
                </div>
            </div>
        </div>
        <div class="base-comment-card__comments-metadata">
            <div class="base-comment-card__comments-opinion">
                <BaseTag
                    v-if="isProposal"
                >
                    <template v-slot:title v-if="isDisagree === 'agree'"> {{ $t('buttons.agree') }}</template>
                    <template v-slot:title v-else> {{ $t('buttons.disagree') }}</template>
                </BaseTag>
            </div>
            <div class="base-comment-card__comments-right">
                <div class="user" v-if="hasUser">
                    {{ `${props.userComment.user.first_name} ${props.userComment.user.last_name}` }},
                </div>
                <div class="date">
                    <i> {{ formatDistanceDateToNow(new Date(props.userComment.updated_at)) }}</i>
                </div>
            </div>
        </div>
        <confirm-flag ref='confirmFlagDialogRef' @submit="submitFlag"/>
        <LoginDialog ref="loginDialogRef"/>
    </div>
</template>

<style lang="scss">
.base-comment-card {
    display: flex;
    background-color: #f0f0f0;
    border-radius: 8px;
    flex-direction: column;
    margin: 7px 0 7px 0;
    padding: 10px;

    &__comments {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        &-input {
            display: flex;
            background-color: #f0f0f0;
            border-radius: 8px;
            flex-direction: row;
            margin: 7px 0 7px 0;
            padding: 5px;
        }

        &-metadata {
            display: flex;
            flex-direction: row;
            margin: $space-sm 0 0 0;
            justify-content: space-between;
        }

        &-opinion {
            display: flex;
            align-items: center;

        }

        &-right {
            display: flex;
            flex-direction: row;
            gap: $space-xs;

            .user, .date {
                display: flex;
                justify-content: end;

            }
        }
    }

    &__actions {
        display: flex;
        flex-direction: column;
        color: $color-primary-darken;
        cursor: pointer;
        min-width: 10%;
        gap: $space-xs;

        &-favorite {
            display: flex;
            justify-content: center;
        }

        &-flag {
            display: flex;
            justify-content: end;
            padding-right: 9px;
        }
    }

    &__comment {
        display: flex;
        flex-direction: column;

        &-flag {
            cursor: pointer;

        }
    }

    //color bindings for each comment based on opinion
    &.disagree {
        background-color: $color-red-12;
    }

    &.agree {
        background-color: $color-green-12;
    }
}

.base-tag {
    //color bindings for each comment based on opinion
    &.strongly_disagree {
        background-color: $color-red-12;
    }

    &.disagree {
        background-color: $color-red-12;
    }

    &.strongly_agree {
        background-color: $color-green-12;
    }

    &.agree {
        background-color: $color-green-12;
    }

    &.strong {
        &.strongly_disagree {
            background-color: $color-red-50;
        }

        &.disagree {
            background-color: $color-red-50;
        }

        &.strongly_agree {
            background-color: $color-green-50;
        }

        &.agree {
            background-color: $color-green-50;
        }
    }
}


</style>