import { i18n } from "@/plugins/i18n";
import { getCurrentYear } from "@/utils/date-util";

// @ts-ignore
const t = i18n.global.t;

const rules = {
    email: [(val: string) => validateEmail(val) || `${t("errors.valid-field", {field: `${t("errors.type.email")}`})}`],
    phoneNumber: [(val: string) => !(!val || !val.length || val.length < 12) || `${t("errors.valid-field", {field: `${t("errors.type.phone-number")}`})}`],
    required: [(val: string) => (val && val.length > 0) || `${t("errors.required")}`],
    postalCode: [(val: string) => (!val || postalCodeValidation(val)) || `${t("errors.valid-field", {field: `${t("errors.type.postal-code")}`})}`],
    birthYear: [(val: string) => (!val ||parseInt(val) >= 1900) || `${t("errors.birth-date")}`,
                (val: string) => (!val || parseInt(val) <= minAge()) || `${t("errors.minimum-age")}`
                ],
}

function validateEmail(email: string): boolean {
    return /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/.test(email);
}

function minAge() {
    return getCurrentYear() - 13
}

function postalCodeValidation(postalCode: string) {
    return /^[ABCEGHJ-NPRSTVXY][0-9][ABCEGHJ-NPRSTV-Z][ ]?[0-9][ABCEGHJ-NPRSTV-Z][0-9]$/.test(postalCode)
}
export { rules }