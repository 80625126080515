<script setup lang="ts">

// PROPS
const props = defineProps<{
    anchorTop?: boolean;
    anchorBottom?: boolean;
    anchorLegend?: boolean;
}>();

// EMITS
const emit = defineEmits<{
    (e: "close"): void;
}>();

// FUNCTIONS
function closePopup () {
    emit('close');
}

</script>
<template>
    <div class="info-window"
         :class="{'anchor-top': props.anchorTop, 'anchor-bottom': props.anchorBottom, 'anchor-legend': props.anchorLegend}">
        <div class="info-window__content">
            <div class="title">
                <slot name="title"></slot>
            </div>
            <div class="content">
                <slot name="content"></slot>
            </div>
        </div>
        <div class="close">
            <q-btn flat>
                <slot name="close" :close="closePopup">
                </slot>
            </q-btn>
        </div>
    </div>
    
</template>
<style lang="scss">
.info-window {
    display: flex;
    flex-direction: row;
    min-height: 50px;
    width: 100%;
    background-color: $color-neutral-white;
    border-radius: 8px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding: $space-md;
    margin: $space-sm 0 0 0;

    .close {
        display: flex;
        justify-content: center;
        width: auto;
        margin: 0 0 0 8px;

        .q-btn {
            height: 30px;
            width: 30px;
            padding: 0;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        width: 100%;

        .content {
            display: flex;
            flex-direction: column;
            gap: $space-sm;
        }

        .title {
            font-weight: bold;
            font-size: $sub-header;
        }
    }

    &.anchor-top {
        position: absolute;
        z-index: 2;
        width: auto;
        top: 10px;
        left: 10px;
    }

    &.anchor-legend {
        position: absolute;
        z-index: 2;
        width: auto;
        top: 48px;
        left: 10px;
    }

    &.anchor-bottom {
        position: absolute;
        z-index: 2;
        width: auto;
        bottom: 48px;
        right: 10px;
    }

    .q-field {
        &__control {
            &--readonly {
                background-color: red !important;
            }

            height: 44px !important;
        }
    }

    .base-select {
        .q-field__control {
            &:before {
                border: solid 1px rgba(0,0,0, 0.24);
            }
        }
    }
}
</style>