import { Config } from '@/config';
import apiService from "@/services/api.service";
import { User, UserDto, useUserStore } from '@/store/userStore';

class UserService {
    async updateUser(user: User) {
        const userStore = useUserStore();
        try {
            const response = await apiService.put(`${Config.api.backend.urlV2}/user`, user);
            if ("emailConsent" in user) {
                response.data.emailConsent = user.emailConsent ? 1 : 0
            }
            userStore.setUser(response.data)
            return response;
        } catch (error) {
            return error.response.data;
        }
    }
}

export const userService = new UserService();