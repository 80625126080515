<script setup lang="ts">

import { getTranslation } from "@/utils/data-display-utils";
import { QBadge, QLinearProgress } from "quasar";
import { computed } from "vue";
import BaseInput from "@/components/base/BaseInput.vue";

// PROPS
const props = defineProps<{
    choices: any;
    setActiveScreen?: string;
    statistiques: any;
    response?: any[];
    otherChoice: boolean;
    otherChoiceLabel?: string;
}>();

// EMITS
const emit = defineEmits<{
    (e: "change", response: string): void;

}>();

// COMPUTED
const selectedResponses = computed(() => {
    if (props.response) {
        return props.response[0].value_id;
    } else {
        return []
    }
});
const otherAnswer = computed(() => {
    if (props.response) {
        return props.response[0].value_text;
    } else {
        return '';
    }
});

function isChoiceSelected(choiceId: number) {
    return selectedResponses.value.includes(choiceId);
}

function isChoiceOtherSelected() {
    return selectedResponses.value.includes("o");
}
</script>

<template>
    <div v-for="choice in props.choices">
        <div class="label-button">
            <div v-if="statistiques[choice.id]">
                <q-linear-progress size="36px" :value='statistiques[choice.id].percentage/100'
                                   :class="{'selected-response': isChoiceSelected(choice.id)}">
                    <div class="question-labels">
                        <div class="question-response">
                            <q-badge
                                class="text-label"
                                color="transparent"
                                text-color="primary"
                                :label="getTranslation(choice, 'text')"
                            />
                            <div v-if="isChoiceSelected(choice.id) " class="selected-response">
                                <q-icon color="primary" size="sm" name="check_circle"/>
                            </div>
                        </div>
                        <q-badge
                            class="value-label"
                            color="transparent"
                            text-color="primary"
                            :label="`${statistiques[choice.id].percentage}%`"
                        />
                    </div>
                </q-linear-progress>
            </div>
            <div v-else>
                <q-linear-progress size="36px" :value=0>
                    <div class="question-labels">
                        <div class="question-response">
                            <q-badge
                                class="text-label"
                                color="transparent"
                                text-color="primary"
                                :label="getTranslation(choice, 'text')"
                            />
                        </div>
                        <q-badge
                            class="value-label"
                            color="transparent"
                            text-color="primary"
                            :label="$t('form.no-votes')"
                        />
                    </div>
                </q-linear-progress>
            </div>
        </div>
    </div>
    <div class="label-button" v-if="props.otherChoice">
        <q-linear-progress size="36px" :value='statistiques["o"].percentage/100'
                           :class="{'selected-response': isChoiceOtherSelected()}">
            <div class="question-labels">
                <div class="question-response">
                    <q-badge
                        class="text-label"
                        color="transparent"
                        text-color="primary"
                        :label="props.otherChoiceLabel"
                    />
                    <div v-if="isChoiceOtherSelected()" class="selected-response">
                        <q-icon color="primary" size="sm" name="check_circle"/>
                    </div>
                </div>
                <q-badge
                    class="value-label"
                    color="transparent"
                    text-color="primary"
                    :label="`${statistiques['o'].percentage}%`"
                />
            </div>
        </q-linear-progress>
        <q-input v-if="isChoiceOtherSelected()"
                 class="q-mt-sm"
                 :placeholder="$t('form.otherAnswerPlaceholder')"
                 outlined
                 disable
                 :model-value="otherAnswer"
        />
    </div>
</template>

<style lang="scss">
.label-button {
    padding-top: 5px;
    padding-bottom: 5px;

    .q-linear-progress {
        background-color: $color-neutral-grey-200 !important;
        color: $color-primary-12;
        border-radius: 8px !important;
    }

    .q-badge {
        position: relative;
        top: auto;
        right: auto;
        border-radius: 4px !important;
        width: fit-content;
        font-size: $body-text !important;
    }
}

.question-labels {
    display: flex;
    height: 100%;
    flex-direction: row;
    justify-content: space-between;

    .question-response {
        display: flex;
        flex-direction: row;
        max-width: 85%;
    }

    .q-badge {
        align-items: center;

        &.text-label {
            padding-left: 16px;
            width: inherit;
            overflow: hidden;
        }
    }

    .selected-response {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .q-icon {
            font-size: $small-title;
        }
    }

}
</style>