<script setup lang="ts">
import { Cropper, CircleStencil, RectangleStencil } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
import BaseButton from "@/components/base/BaseButton.vue";
import { computed, onMounted, reactive, ref, shallowRef } from "vue";

type ImageType = "avatar" | "community" | "card";
type StencilProps = {
    aspectRatio?: number
    minAspectRatio?: number
    maxAspectRatio?: number
    handlers?: object
    lines?: object
    movable?: boolean,
    resizable?: boolean

}

// PROPS
const props = defineProps<{
    image: string
    type: ImageType
    disabled?: boolean
}>();

// EMITS
const emit = defineEmits<{
    (e: "upload", image: string): void
    (e: "update", image: string): void
    (e: "remove"): void
}>();

// REACTIVE
const image = reactive({
    src: "",
    type: "image/jpg",
});

// REFS
const cropRef = ref();
const openImageButtonRef = ref()
const bannerRatio = ref(6.68);
const setImage = ref(false);
const editMode = ref<boolean>(false);

const selectedStencil = shallowRef(CircleStencil)
const stencilProps = ref<StencilProps>({
    handlers: {},
    lines: {},
    movable: false,
    resizable: false
})

// LIFECYCLE
onMounted(() => {
    image.src = props.image
    selectStencil()
})

// COMPUTED
const hasImageSet = computed(() => {
    return props.image.length !== 0 || setImage.value
})

const isInEditMode = computed(() => {
    return editMode.value && hasImageSet
})

const aspectRatioNumber = computed(() => {
    return bannerRatio.value === 6.68 ? 3.51 : 6.68
})

const isCommunityImage = computed(() => {
    return props.type === 'community'
})

// FUNCTIONS
function uploadImage() {
    if (cropRef.value) {
        const {canvas} = cropRef.value.getResult();
        emit("upload", canvas.toDataURL())
    }
}

function openImage(event: { target: { files: any; }; }) {
    const {files} = event.target;
    if (files && files[0]) {
        if (image.src) {
            URL.revokeObjectURL(image.src);
        }
        image.src = URL.createObjectURL(files[0]);
        image.type = files[0].type;
        setImage.value = true
        toggleEditMode()
    }
}

function toggleEditMode() {
    editMode.value = !editMode.value
    if (editMode.value) {
        stencilProps.value.handlers = {
            eastNorth: true,
            north: true,
            westNorth: true,
            west: true,
            westSouth: true,
            south: true,
            eastSouth: true,
            east: true
        }
        stencilProps.value.lines = {
            north: true,
            west: true,
            south: true,
            east: true,
        }
        stencilProps.value.movable = true
        stencilProps.value.resizable = true
    } else {
        stencilProps.value.handlers = {}
        stencilProps.value.lines = {}
        stencilProps.value.movable = false
        stencilProps.value.resizable = false
    }

}

function removeImage() {
    emit("remove")
    emit('update', "")
}

function selectStencil() {
    switch (props.type) {
        case "card":
            selectedStencil.value = RectangleStencil;
            stencilProps.value.aspectRatio = 3.86;
            break;
        case "community":
            selectedStencil.value = RectangleStencil;
            stencilProps.value.aspectRatio = aspectRatioNumber;
            break;
        default:
            selectedStencil.value = CircleStencil;
            stencilProps.value.aspectRatio = 1;
            break;
    }
}

function changeBannerRatio() {
    bannerRatio.value = bannerRatio.value === 6.68 ? 3.51 : 6.68
}

function openImageDialog() {
    openImageButtonRef.value.openFileDialog()
}

</script>

<template>
    <div class="base-image-upload" v-if="hasImageSet">
        <cropper
                :key="bannerRatio"
                ref="cropRef"
                class="cropper"
                :src="image.src"
                :image-restriction="'fit-area'"
                :stencil-component=selectedStencil
                :stencil-props=stencilProps
        />
        <div class="base-image-upload__buttons">
            <div class="base-image-upload__buttons--edit" v-if="isInEditMode">
                <div class="base-image-upload__buttons--left">
                    <BaseButton
                            @click="toggleEditMode"
                            secondary
                            :text="$t('upload-image-modal.cancel')"
                    />
                </div>
                <div class="base-image-upload__buttons--right">
                    <div class="base-image-upload__buttons--ratio" v-if="isCommunityImage">
                        <div>{{ $t('upload-image-modal.choose-ratio') }}</div>
                        <q-radio
                                v-model="bannerRatio" :val="3.51"
                                :label="$t('upload-image-modal.ratio-small')"
                                @change="changeBannerRatio"
                        />
                        <q-radio v-model="bannerRatio"
                                 :val="6.68"
                                 :label="$t('upload-image-modal.ratio-large')"
                                 @change="changeBannerRatio"
                        />
                    </div>
                    <BaseButton
                            primary
                            @click="uploadImage"
                            :text="$t('upload-image-modal.save')"
                    />
                </div>
            </div>
            <div class="base-image-upload__buttons--modify" v-else>
                <div class="base-image-upload__buttons--left">
                    <BaseButton
                            primary
                            @click="toggleEditMode"
                            :text="$t('upload-image-modal.modify')"
                            right-icon="o_edit"
                    />
                    <BaseButton
                            secondary
                            file-upload
                            :text="$t('upload-image-modal.add-photo')"
                            @upload="openImage($event)"
                            right-icon="o_photo_camera"
                    />
                </div>
                <BaseButton
                        is-transparent
                        :text="$t('upload-image-modal.delete')"
                        @click="removeImage()"
                        right-icon="o_delete"
                />
            </div>

        </div>
    </div>

    <div class="base-image-upload__new" v-else>
        <div class="base-image-upload__new--border" @click="openImageDialog()">
            <div class="base-image-upload__new--text">{{ $t('upload-image-modal.add-photo') }}</div>
        </div>
        <div class="base-image-upload__new--button">
            <BaseButton
                    ref="openImageButtonRef"
                    primary
                    file-upload
                    :text="$t('upload-image-modal.upload-photo')"
                    right-icon="o_photo_camera"
                    @upload="openImage($event)"
            />
        </div>

    </div>


</template>

<style lang="scss">

.base-image-upload {
  width: 750px;
  display: flex;
  flex-direction: column;

  &__new {
    height: 500px;
    width: 750px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;


    &--border {
      border: 2px $color-neutral-grey-200 dashed;
      border-radius: 8px;
      width: 100%;
      height: 100%;
      transition: 200ms;
      cursor: pointer;

      &:hover {
        border-color: $color-primary;
      }
    }

    &--text {
      font-size: $small-title;
      font-weight: bold;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $color-neutral-grey-200;
      margin-bottom: 20px;
      transition: 200ms;

      &:hover {
        color: $color-primary;
      }
    }

    &--button {
      margin-top: $space-md;
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
  }

  &__buttons {
    padding-top: $space-xl;
    display: flex;
    justify-content: space-between;

    &--edit {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }


    &--right {
      display: flex;
    }

    &--ratio {
      display: flex;
      align-items: center;
      font-weight: bold;
      margin-right: $space-lg;
    }

    &--modify {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

    &--left {
      .base-button {
        margin-right: $space-lg
      }
    }
  }
}

.cropper {
  height: 400px;
  width: 800px;
}

.button-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 17px;
}

.button {
  color: white;
  font-size: $sub-header;
  padding: 10px 20px;
  background: $color-primary;
  border-radius: 8px;
  cursor: pointer;
  transition: background 0.5s;
  border: none;

  &:not(:last-of-type) {
    margin-right: 10px;
  }

  &:hover {
    background: #2F2F2F;
  }

  input {
    display: none;
  }
}
</style>