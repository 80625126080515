<script setup lang="ts">

import { getUniqueId, useUserStore } from "@/store/userStore";
import { useRouter } from "vue-router";
import { paths } from "@/utils/paths";
import useResponsive from "@/utils/responsive-util";
import { authService } from "@/services/auth.service";

// STORE
const userStore = useUserStore();

// CONST
const router = useRouter();
const { isMobile } = useResponsive()

// FUNCTIONS
function logout() {
    localStorage.removeItem("accessToken");
    router.push(paths.root);
    authService.logout();
}

function getUserName() {
    if (userStore.user && userStore.user.id) {
        return userStore.getUserName
            ? userStore.getUserName
            : getUniqueId(userStore.user);
    }
}

function goToProfilePage() {
    router.push(paths.profile);
}
</script>

<template>
    <div class="menu-container">
        <div class="menu-container__desktop" v-if="!isMobile">
            <div class="menu-container__user">{{ getUserName() }}</div>
            <q-icon class="menu-container__expand-icon" name="expand_more"/>
            <q-menu
                ref="menu"
                fit
                class="menu-container__account-menu"
                :offset="[0, 8]"
            >
                <div
                    class="menu-container__account-menu--item"
                    @click="goToProfilePage()"
                >
                    {{ $t("account.my-profile") }}
                </div>
                <div class="menu-container__account-menu--item" @click="logout()">
                    {{ $t("account.logout") }}
                </div>
            </q-menu>
        </div>

    </div>
</template>

<style lang="scss">
.menu-container {
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: 200ms;

    &:hover {
        color: $color-primary;
    }

    &__desktop {
        display: flex;
    }

    &__account-menu {
        padding: 16px;

        &--item {
            padding: $space-sm 0;
            cursor: pointer;
            transition: 200ms;
            -webkit-user-select: none; /* Safari */
            -moz-user-select: none; /* Firefox */
            -ms-user-select: none; /* IE10+/Edge */
            user-select: none; /* Standard */

            &:hover {
                color: $color-primary;
            }
        }
    }

    &__user {
        margin: auto;
        font-weight: bold;
        padding-left: $space-sm;
        -webkit-user-select: none; /* Safari */
        -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* IE10+/Edge */
        user-select: none; /* Standard */
    }

    &__expand-icon {
        font-size: $medium-title !important;
        transition: 200ms;

        &:hover {
            color: $color-primary;
        }
    }

    .q-icon {
        font-size: $small-text;
        cursor: pointer;
    }
}

// .q-badge {
// 	position: absolute;
// 	top: 4px;
// 	right: 210px;
// 	width: 12px;
// 	border-radius: 50% !important;
// 	background-color: $color-primary !important;
// }

.profile-done {
    &__icon {
        padding: 0 !important;
        font-size: $xxsmall-text !important;
        right: 4px;
        font-weight: bold !important;
        color: white !important;
    }
}

@media screen and (max-width: $breakpoint-md) {
    .user {
        display: none;
    }
}
</style>
