<script setup lang="ts">

import { ref } from 'vue';
import BaseInput from '@/components/base/BaseInput.vue';
import BaseSelect from '@/components/base/BaseSelect.vue';

import { getTranslation } from "@/utils/data-display-utils";
import { useField } from 'vee-validate';

// CONST
const markerInputCategory = ref(null);

// PROPS
const props = defineProps<{
    mapConfig: any;
}>();

// EMITS
const emit = defineEmits<{
    (e: "changeCategory", category): void;
}>();

// FORM
const { value: comment, errorMessage: errorMessageComment } = useField<number>('comment');

// FUNCTIONS
function changeMarkerCategory (e) {
    emit('changeCategory', e)
}

</script>
<template>
    <div class="marker-questions">
        <div>{{ $t('map.selected-point.content') }}</div>
        <div v-if="mapConfig.categories.length > 1">
            <div>{{ $t('map.selected-point.category') }}</div>
            <base-select
                :ref="markerInputCategory"
                :options="props.mapConfig.categories.map(category => ({label: getTranslation(category, 'name'),value: category.id}))"
                @update="changeMarkerCategory($event)"
            />
        </div>
        <div>
            <div>{{ $t('map.selected-point.comment') }}</div>
            <BaseInput v-model="comment"/>
            <span class="error">{{ errorMessageComment }}</span>
        </div>
    </div>
</template>
<style lang="scss">
.marker-questions {
    display: flex;
    flex-direction: column;
    gap: $space-md;

    .q-field {
        &__control:before {
            border: 1px solid !important;
        }
    }
}
</style>