const root = document.documentElement;
const primaryFetched = "#007DBA";

// Todo: Make API call to get new color values
function setPrimaryColors() {
  root.style.setProperty("--color-primary", primaryFetched);
  root.style.setProperty(
      "--color-primary-50",
      getColorVariation("--color-primary", 50, true)
  );
  root.style.setProperty(
      "--color-primary-12",
      getColorVariation("--color-primary", 12, true)
  );
  root.style.setProperty(
      "--color-primary-8",
      getColorVariation("--color-primary", 8, true)
  );
  root.style.setProperty(
      "--color-primary-darken",
      getColorVariation("--color-primary", 35, false)
  );
}

function getColorVariation(baseColor: string, percentage: number, isLighten: boolean) {
  // const lightness = 100 - percentage;
  const variation = isLighten ? 100 - percentage : percentage;

  // Parse the color string to extract the RGB components
  const hex = getComputedStyle(document.documentElement)
      .getPropertyValue(baseColor)
      .trim()
      .slice(1);

  let r = parseInt(hex.substr(0, 2), 16);
  let g = parseInt(hex.substr(2, 2), 16);
  let b = parseInt(hex.substr(4, 2), 16);

  // Calculate the amount to lighten each color channel
  if (isLighten) {
    r = Math.round(r + (255 - r) * (variation / 100));
    g = Math.round(g + (255 - g) * (variation / 100));
    b = Math.round(b + (255 - b) * (variation / 100));
  } else {
    r = Math.round(r * (1 - variation / 100));
    g = Math.round(g * (1 - variation / 100));
    b = Math.round(b * (1 - variation / 100));

  }

  // Convert the RGB values back to hex and return the new color code
  return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

// Helper function to convert a decimal number to a two-digit hex code
function componentToHex(c: number) {
  const hex = c.toString(16);
  return hex.length == 1 ? "0" + hex : hex;
}


export { setPrimaryColors };
