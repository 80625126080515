<script setup lang="ts">
import { ref, computed, onMounted, nextTick } from "vue";

onMounted(() => {
    nextTick(() => {
        modelValue.value = props.defaultValue ? props.defaultValue : null;
        originalOptions.value = props.options;
    })
});

// EMITS
const emit = defineEmits<{
    (e: "update", newValue: object | null): void;
}>();

// PROPS
const props = defineProps<{
    options: Object[];
    defaultValue?: object
}>();


// REFS
const modelValue = ref<object | null>(null);
const originalOptions = ref();

// COMPUTED
const localModelValue = computed({
    get() {
        return modelValue.value;
    },
    set(newValue) {
        modelValue.value = newValue;
        emit("update", newValue);
    }
});

</script>

<template>
    <q-select
        class="base-select full-width"
        v-model="localModelValue"
        :options="props.options"
        outlined
    >
    </q-select>
</template>

<style lang="scss">

.base-select {
    min-width: 270px;

    .items-center {
        height: 44px;
    }
}

.option {
    &__name {
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}

.base-select.q-field {
    .q-field__native {
        line-height: 9px;
        align-items: inherit;
    }

    .q-field__control {
        background-color: $color-neutral-white;
        padding: 0 6px 0 12px;
        min-height: 44px;
        height: 44px;

        &:before {
            border: none;
        }

        &-container {
            align-items: center;
        }
    }

    .q-field__input {
        position: relative;
        bottom: 1px;
    }
}

.q-item.q-item-type {
    padding: 8px;
    height: 44px;
    min-height: 44px;
}
</style>
