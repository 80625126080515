<script setup lang="ts">
import { ref, watch } from "vue";
import { QDialog } from "quasar";
import BaseButton from "@/components/base/BaseButton.vue";

const props = defineProps<{
	action: string;
	isOpen: boolean;
}>();

const isOpen = ref(props.isOpen);
const emit = defineEmits<{
	(e: "update:isOpen", value: boolean): void;
	(e: "cancel"): void;
	(e: "confirm"): void;
}>();

const updateIsOpen = (value: boolean) => {
	isOpen.value = value;
	emit("update:isOpen", value);
};

const cancelClicked = () => {
	updateIsOpen(false);
	emit("cancel");
};

const continueClicked = () => {
	updateIsOpen(false);
	emit("confirm");
};

watch(
	() => props.isOpen,
	(newValue) => {
		isOpen.value = newValue;
	}
);
</script>

<template>
	<q-dialog :model-value="isOpen" @update:model-value="updateIsOpen" persistent>
		<q-card class="base-popup">
			<q-card-section>
				{{ $t("popup.content") }} {{ action }} ?
				<slot></slot>
			</q-card-section>
			<q-card-actions align="right">
				<BaseButton
					:text="$t('popup.cancel')"
					secondary
					@click="cancelClicked"
				/>
				<BaseButton
					:text="$t('popup.continue')"
					primary
					@click="continueClicked"
				/>
			</q-card-actions>
		</q-card>
	</q-dialog>
</template>

<style lang="scss">
.base-popup {
	display: flex;
	flex-direction: column;

	&.q-card {
		border-radius: 18px;
		padding: $space-md;
	}
}
</style>
