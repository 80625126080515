<script setup lang="ts">
import { ref, watch, onMounted } from 'vue';

const props = defineProps<{
  modelValue: string; // For v-model binding to the parent component
}>();

const emit = defineEmits(['update:modelValue']); // Emit the updated value back to the parent

const code = ref(props.modelValue || ''); // Internal ref to handle the 5-character code

// Watch for changes in the parent `modelValue` and update the internal `code`
watch(() => props.modelValue, (newValue) => {
  code.value = newValue;
});

// FUNCTIONS
const handleInput = (event: Event, index: number) => {
  const input = event.target as HTMLInputElement;
  let newCode = code.value.split('');
  newCode[index] = input.value.slice(-1); // Update the specific character
  code.value = newCode.join(''); // Join the characters back into the full code
  emit('update:modelValue', code.value); // Emit the full code back to the parent

  if (input.value.length === 1 && index < 4) {
    // Focus next input box if there's a character entered and it's not the last input
    const nextInput = document.getElementById(`code-input-${index + 1}`) as HTMLInputElement;
    nextInput?.focus();
  }
};

// Handle pasting the full code into the first box
const handlePaste = (event: ClipboardEvent) => {
  const pasteText = event.clipboardData?.getData('text') || '';
  if (pasteText.length === 5 && /^[0-9]+$/.test(pasteText)) {
    // Ensure the pasted text is exactly 5 digits
    code.value = pasteText; // Set the entire code at once
    emit('update:modelValue', code.value); // Emit the full code back to the parent

    // Automatically distribute the pasted text into the individual inputs
    for (let i = 0; i < 5; i++) {
      const input = document.getElementById(`code-input-${i}`) as HTMLInputElement;
      if (input) {
        input.value = pasteText[i]; // Assign each character to the corresponding input box
      }
    }
    event.preventDefault(); // Prevent default paste behavior
  }
};
</script>

<template>
  <div class="code-input-container">
    <input
      v-for="i in 5"
      :key="i"
      :id="`code-input-${i - 1}`"
      class="code-input"
      :value="code[i - 1]"
      maxlength="1"
      @input="(e) => handleInput(e, i - 1)"
      @paste="(e) => { if (i === 1) handlePaste(e); }"
    />
  </div>
</template>

<style scoped>
.code-input-container {
  display: flex;
  gap: 8px;
}

.code-input {
  width: 40px;
  height: 40px;
  text-align: center;
  font-size: 24px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
</style>
