<script setup lang="ts">

import BaseInput from '@/components/base/BaseInput.vue';
import { useField } from 'vee-validate';
import { useI18n } from 'vue-i18n';
// CONST 
const { t } = useI18n();
const { value: firstName, errorMessage: errorMessageFirst } = useField<number>('firstName');
const { value: lastName, errorMessage: errorMessageLast } = useField<string>('lastName');
</script>

<template>
    <div class="name-questions">
        <div class="names">
            <div class="label">{{ $t('profile.first-name')}}</div>
            <BaseInput v-model="firstName"/>
            <span class="error">{{ errorMessageFirst }}</span>
        </div>
        <div class="names">
            <div class="label">{{ $t('profile.last-name')}}</div>
            <BaseInput v-model="lastName"/>
            <span class="error">{{ errorMessageLast }}</span>
        </div>
    </div>
</template>

<style lang="scss">
.name-questions {
    display: flex;
    flex-direction: row;
    gap: 10px;
    .names {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    .label {
        font-size: $small-text;
        padding: 0 0 $space-xs;
        font-weight: bold;
    }
    
}
</style>