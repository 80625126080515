<script setup lang="ts">

import { provide, ref } from "vue";
import BaseValidationInput from "../base/BaseValidationInput.vue";
import BaseValidationTextArea from "../base/BaseValidationTextArea.vue";
import BaseButton from '@/components/base/BaseButton.vue';

import { useForm, useField } from 'vee-validate';
import * as yup from 'yup';
import { formsService } from "@/services/forms.service";
import { currentLocale } from "@/plugins/i18n";

// PROPS
const props = defineProps<{
    sectionId: number;
}>();


const { handleSubmit, meta } = useForm({
    validationSchema: yup.object({
        title: yup.string().required(),
        content: yup.string().required(),
    }),
    initialValues: {
        title: '',
        content: ''
    }
});

// CONST
const activeLocale = ref<string>('fr');


// EMITS
const emit = defineEmits<{
    (e: "cancel"): void;
    (e: "update", screen: string): void;
    (e: "closeDialog"): void;
}>();

provide('activeLocale', activeLocale);

// FUCNTIONS
function goBack(screen) {
    emit('update', screen);
}

const submitProposition = handleSubmit((values, actions) => {
    let selectedLocale = currentLocale()
    const question = {
        title: {
            [selectedLocale]: values.title
        },
        content: {
            [selectedLocale]: values.content
        }
    }
    console.debug('submitting', values);
    formsService.postForm(question, props.sectionId);
    actions.resetForm();
    emit('update', 'moderation');
});

</script>

<template>
    <div class="proposition-questions">
        <div class="proposition-questions__questions">
            <div class="proposition-questions__questions-title">
                <span> {{ $t('dialog.proposition.fields.title') }} </span>
                <base-validation-input name="title" label="title"/>
            </div>
            <div class="proposition-questions__questions-proposition">
                <span> Proposition </span>
                <BaseValidationTextArea name="content" label="content"/>
            </div>
        </div>
        <div class="proposition-questions__buttons">
            <BaseButton @click="goBack('terms')">{{ $t('buttons.cancel') }}</BaseButton>
            <BaseButton class="action" :disabled="!meta.valid" @click="submitProposition">
                {{ $t('buttons.submit') }}
            </BaseButton>
        </div>
    </div>
</template>

<style lang="scss">
.proposition-questions {
    display: flex;
    flex-direction: column;
    gap: $space-sm;

    &__buttons {
        display: flex;
        justify-content: end;
        gap: $space-md;
    }

    &__lang {
        display: flex;
        justify-content: end;
    }

    &__questions {
        display: flex;
        flex-direction: column;
        gap: $space-md;

        &-title {
            display: flex;
            flex-direction: column;
            width: 100%;
            gap: $space-sm;
        }

        &-proposition {
            display: flex;
            flex-direction: column;
            width: 100%;
            gap: $space-sm;

            textarea {
                border: none;
                min-height: 150px;
                border-radius: 4px;
                padding: $space-md;
                resize: vertical;
            }
        }
    }
}
</style>