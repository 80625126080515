import {format, add, differenceInDays, formatDistanceToNow} from "date-fns";
import { frCA, enCA } from "date-fns/locale";
import { currentLocale } from "@/plugins/i18n";

function todayInTimestamp() {
    return Math.floor(Date.now() / 1000);
}

function formatDate(date: number): string {
    const local = currentLocale() === 'fr' ? frCA : enCA
    const dateFormat = currentLocale() === 'fr' ? 'd MMMM yyyy' : 'yyyy MMMM d'
    return format(new Date(date * 1000), dateFormat, {locale: local});
}

function diffInDays(startTimestamp: any, endTimestamp: any) {
  const startDate: any = new Date(startTimestamp);
  const endDate: any = new Date(endTimestamp);

  const diffInMilliseconds = endDate - startDate;

  const diffInDays = diffInMilliseconds / (1000 * 60 * 60 * 24);

  return Math.round(diffInDays);
}

function addDays(today: number, days: number) {
    return add(new Date(today * 1000), {days: days}).getTime() / 1000;
}

function getCurrentYear() {
    const currentDate = new Date();
    return currentDate.getFullYear();
}

function formatDistanceDateToNow(date: Date) {
  const locale = currentLocale() === 'fr' ? frCA : enCA
  return formatDistanceToNow(date, { locale });
}

export { todayInTimestamp, diffInDays, addDays, format, formatDate, getCurrentYear, formatDistanceDateToNow };