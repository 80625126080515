<script setup lang="ts">

import { computed, onMounted, ref } from "vue";
import BaseTag from './base/BaseTag.vue';
import BaseButton from './base/BaseButton.vue';
import BaseComment from './base/BaseComment.vue';
import BaseMediaCard from "./base/BaseMediaCard.vue";
import BaseFormAnswer from "@/components/base/BaseFormAnswer.vue";
import BaseNotification from '@/components/base/BaseNotification.vue';
import CommentFlagged from "./dialogs/CommentFlagged.vue";
import BaseCommentBox from "./base/BaseCommentBox.vue";

import { constant } from "@/constant";
import { getTranslation } from "@/utils/data-display-utils";
import { formsService } from "@/services/forms.service";
import { authService } from "@/services/auth.service";
import type { Form } from "@/store/form.Interface";
import { useUserStore } from '@/store/userStore';
import { QExpansionItem } from "quasar";

// PROPS
const props = defineProps<{
    consultationId: string,
    consultationIsCompleted: boolean,
    fiche: Form,
    index: number,
    activeSectionId: number,
    sectionCount: number,
    formCount: number
}>();


// REFS
const showComments = ref<boolean>(false);
const showContexts = ref<boolean>(false);
const activeFilter = ref<string>('tous')
//const userComment = ref<string>("");
const answer = ref([]);
const hasComments = ref<boolean>(false);
const hasNotification = ref<boolean>(false);
const notificationStatus = ref<string>('');
const notificationMessage = ref<string>('');
const response = ref<object>();
const commentFlagDialogRef = ref(null);
const userStore = useUserStore();


// LIFECYCLE
onMounted(async () => {
    hasComments.value = props.fiche.comments.length > 0;
})

// EMITS
const emit = defineEmits<{
    (e: "click", index: number): void;
    (e: 'openDemographicDialog'): void;
}>();


// COMPUTED
const isBottomBoxOpen = computed(() => {
    return !(showComments.value === true || showContexts.value === true);
})

const filteredComments = computed(() => {
    if (activeFilter.value === 'tous') {
        return commentData.value;
    } else if (activeFilter.value === 'accord') {
        return commentData.value.filter((comment) => comment.vote.value_id <= 2);
    } else {
        return commentData.value.filter((comment) => comment.vote.value_id > 2);
    }
})

const accordComments = computed(() => {
    return commentData.value.filter((comment) => comment.vote.value_id <= 2);
})

const desaccordComments = computed(() => {
    return commentData.value.filter((comment) => comment.vote.value_id > 2);
})

const commentData = computed(() => {
    return props.fiche.comments;
})

const isFinalQuestion = computed(() => {
    if (props.activeSectionId === props.sectionCount && props.index + 1 === props.formCount) {
        return true
    } else {
        return false
    }
})

// FUNCTIONS
function toggleContent(type: string) {
    if (type === 'content') {
        showComments.value = false;
        showContexts.value = false;
    } else if (type === 'content-box') {
        showComments.value = true;
        showContexts.value = false;
    } else if (type === 'open context') {
        showContexts.value = !showContexts.value;
        showComments.value = false;
    } else if (type === 'open comments') {
        showComments.value = !showComments.value;
        showContexts.value = false;
    }

    let formCard = document.getElementById('fiche_' + (props.fiche.id - 1));
    formCard.scrollIntoView({ block: 'center', behavior: 'smooth' });
}


function catchScrollAction(index: number) {
    emit("click", index);
}

const isUserLogged = computed(() => {
    return userStore.isUserLogged
});

const isUserAllowedToComment = computed(() => {
    return props.fiche.response && isUserAllowedToVote.value && props.fiche.response.length > 0;
});

const userHasDemographicsData = computed(() => {
    return userStore.validateDemographicData;
});

const isUserAllowedToVote = computed(() => {
    const hasDemographicsData = userHasDemographicsData.value;
    return isUserLogged.value && hasDemographicsData;
});

async function submitComment(userComment) {
    if (isUserAllowedToComment.value) {
        var comment: any = userComment.value;
        response.value = await formsService.postComment(comment, props.fiche.id);
        // todo: convertir en helper/composable
        if (response.value.success != true) {
            notificationMessage.value = response.value.message;
            notificationStatus.value = response.value.status;
            hasNotification.value = true;
            setTimeout(() => {
                hasNotification.value = false;
            }, 3000);
        } else {
            userComment.value = '';
            if (response.value.data.flagged) {
                openCommentFlagPopup()
            }
        }
    } else {
        logUserOrAskDemographics();
    }
}

function filterComments(type: string | null) {
    if (type === 'tous') {
        activeFilter.value = 'tous'
    } else if (type === 'accord') {
        activeFilter.value = 'accord'
    } else if (type === 'desaccord') {
        activeFilter.value = 'desaccord'
    }
}

function submit(submittedResponse: any) {
    if (userHasDemographicsData.value) {
        formsService.postFormVote(submittedResponse, props.fiche.id);
    }

    let formCard = document.getElementById('fiche_' + (props.index));
    formCard.scrollIntoView({ block: 'start', behavior: 'smooth' });
}

function openCommentFlagPopup() {
    commentFlagDialogRef.value.openDialog();
}

function logUserOrAskDemographics() {
    if (!isUserLogged.value) {
        authService.login(props.consultationId, props.fiche.sectionId, props.index);
    } else if (!userHasDemographicsData.value) {
        emit('openDemographicDialog');
    }
}

async function submitMarker(marker) {
    formsService.postMapVote(marker, props.fiche)
} 
</script>

<template>
    <div class="base-form shadow" :id="'fiche_' + props.index">
        <div class="base-form__content">
            <q-badge v-if="$props.fiche.is_citizen_proposal" class="q-pa-md q-mb-md">{{ $t('form.citizen-proposal.label') }} {{ $props.fiche.user.first_name }} {{ $props.fiche.user.last_name }}</q-badge>
            <div
                class="base-form__header"
                @click="toggleContent('content')">
                <div class="base-form__header-title">
                    <h3>
                        {{ getTranslation(props.fiche.question, 'title') }}
                    </h3>
                </div>
            </div>
            <div
                class="base-form__description-section"
                @click="toggleContent('content')">
                <span class="base-form__description">
                    {{ getTranslation(props.fiche.question, 'content') }}
                </span>
            </div>
            <div class="base-form__context" v-if="fiche.context?.length>0">
                <q-expansion-item>
                    <template v-slot:header>
                        <q-item-section>
                            <span class="title">{{ $t('form.additional-information') }} [ {{
                                    fiche.context.length
                                }} ]</span>
                        </q-item-section>
                    </template>
                    <q-card v-for="context in fiche.context">
                        <BaseMediaCard
                            :type="context.type"
                            :title="getTranslation(context, 'title')"
                            :content="getTranslation(context, 'content')"
                        />
                    </q-card>
                </q-expansion-item>
            </div>
            <Transition name="banner">
                <div class="base-form__image" v-if="isBottomBoxOpen && fiche.question.image?.url">
                    <img
                        class="base-form__image"
                        :src="fiche.question.image?.url"
                        alt="card-image"
                    />
                </div>
            </Transition>
            <Transition name="answers">
                <div
                    class="base-form__form-section"
                    v-if="isBottomBoxOpen">
                    <BaseFormAnswer
                        :consultation-is-completed="props.consultationIsCompleted"
                        :answers="fiche.answers"
                        :question-response="fiche.response && fiche.response[0]?.votes"
                        :statistique="fiche.statistiques"
                        :is-bottom-box-open="isBottomBoxOpen"
                        :index="index"
                        :form-type="fiche.type"
                        :response="fiche.response || []"
                        :max-answers="fiche.maxAnswers"
                        :is-user-allowed-to-vote="isUserAllowedToVote"
                        :is-final-question="isFinalQuestion"
                        @next-form="catchScrollAction($event)"
                        @submit="submit"
                        @vote-not-allowed="logUserOrAskDemographics"
                        @submit-comment="submitComment($event)"
                        @submit-marker="submitMarker"
                        :i-believe-i-care-enabled="fiche.iBelieveICareEnabled"
                        :are-comments-enabled="fiche.areCommentsEnabled"
                        :map-markers="fiche.mapMarkers"
                    />
                    <q-separator/>
                </div>
            </Transition>

            <div class="base-form__footer">
                <div class="base-form__footer-right" v-if="props.fiche.areCommentsEnabled">
                    <BaseTag
                        class="text-primary tag-button"
                        icon="o_mode_comment"
                        icon-color="primary"
                        iconRight
                        @click="toggleContent('open comments')">
                        <template v-slot:title> {{ $t('consultation.comment', 2) }}</template>
                        <template v-slot:number>{{ commentData ? commentData.length : 0 }}</template>
                    </BaseTag>
                </div>
            </div>

            <Transition name="comment" v-if="props.fiche.type != constant.Form.Type.Map">
                <div class="base-form__comments-container" v-if="showComments">
                    <q-separator/>
                    <div class="base-form__comments-filterbuttons" v-if="fiche.type === constant.Form.Type.Proposal">
                        <BaseButton
                            :class="{ active: activeFilter === 'tous'}"
                            class="blue-secondary"
                            @click="filterComments('tous')">
                            {{ $t('buttons.all') }} [ {{ commentData.length }} ]
                        </BaseButton>
                        <BaseButton
                            :class="{ active: activeFilter === 'accord'}"
                            class="blue-secondary"
                            @click="filterComments('accord')">
                            {{ $t('buttons.agree') }} [ {{ accordComments.length }} ]
                        </BaseButton>
                        <BaseButton
                            :class="{ active: activeFilter === 'desaccord'}"
                            class="blue-secondary"
                            @click="filterComments('desaccord')">
                            {{ $t('buttons.disagree') }} [ {{ desaccordComments.length }} ]
                        </BaseButton>
                    </div>
                    <div class="base-form__comments-section" v-if="showComments">
                        <div v-for="comment in filteredComments">
                            <BaseComment
                                :userComment="comment"
                                :is-proposal="fiche.type === constant.Form.Type.Proposal"
                                :formId="fiche.id"
                                :consultation-is-completed="props.consultationIsCompleted"
                            />
                        </div>
                        <div v-if="filteredComments.length < 1" class="base-form__comments-section-none">
                            {{ $t('consultation.no-comments') }}
                        </div>
                    </div>
                    <div class="base-form__comments-input" v-if="props.fiche.areCommentsEnabled">
                        <base-comment-box v-if="!props.consultationIsCompleted"
                                          @submit-comment="submitComment($event)"
                        />
                    </div>
                </div>
            </Transition>
            <Transition>
                <div class="base-form__contexts-section" v-if="showContexts">
                    <div v-for="context in fiche.context">
                        <BaseMediaCard
                            :type="context.type"
                            :title="getTranslation(context, 'title')"
                            :content="getTranslation(context, 'content')"
                        />
                    </div>
                </div>
            </Transition>
        </div>

        <comment-flagged ref="commentFlagDialogRef"/>
        <BaseNotification v-if="hasNotification" :status="notificationStatus" :message="notificationMessage"/>
    </div>
</template>

<style lang="scss">
.base-form {
    height: 100%;
    width: 100%;
    align-self: start;
    display: flex;
    flex-direction: column;
    background-color: $color-neutral-white;
    border-radius: 8px;
    transition: all 200ms ease;

    &__image {
        position: relative;
        background-color: $color-neutral-grey-300;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        margin: 0 10%;
        padding: 6px;
    }

    &__image {
        max-width: 100%;
    }

    &__fiche-type {
        position: absolute;
        top: $space-md;
        right: $space-lg;
        background-color: $color-neutral-grey-200;
        color: $color-neutral-black;
        padding: 0 $space-xs;
        border-radius: 8px;
    }

    &__tags {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .left-tags, .right-tags {
            display: flex;
            flex-direction: row;
        }

        .base-tag {
            margin-left: 2px;
            margin-right: 2px;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: $space-md;
        width: 100%;
        height: 100%;

        .moderator-comments {
            display: flex;
            flex-direction: column;
            margin: $space-md $space-sm;
            border: solid 4px $color-red-12;

            &__title {
                display: flex;
                justify-content: center;
                background-color: $color-red-12;
                padding: $space-sm;
            }

            &__content {
                display: flex;
                padding: $space-sm;
            }
        }
    }

    .active {
        background-color: $color-primary-50;
    }

    &__header {
        display: flex;
        flex-direction: row;
        color: $color-primary-darken;
        gap: 6px;

        &-icon {
            .q-icon {
                font-size: $h1;
            }
        }

        &-title {
            display: flex;
            align-items: center;


        }
    }

    &__description-section {
        padding: $space-sm 0;
        color: $color-neutral-grey-400;
    }

    &__context {
        padding: $space-sm 0;
    }

    &__title {
        font-size: 16px;
        display: flex;
        font-weight: bold;
    }

    &__form {
        display: flex;
        flex-direction: column;

        &-section {
            display: flex;
            flex-direction: column;
            gap: 2px;
            margin: $space-md $space-sm 0 $space-sm;
        }

        .q-btn {
            margin-top: 5px;
            margin-bottom: 5px;
        }
    }

    &__actions {
        display: flex;
        flex-direction: row;
        margin-top: 10px;
        align-items: flex-end;
        gap: 4px;


        .q-btn {
            margin-top: 5px;
            margin-bottom: 5px;
        }
    }

    &__actions {
        display: flex;
        flex-direction: row;
        margin-top: 10px;
        align-items: flex-end;
        gap: 4px;

        &-poll {
            display: flex;
            flex-direction: row;
            width: 100%;
        }

        &-cancel {
            margin-right: 5px;
            width: 100%;
        }

        &-submit {
            margin-left: 5px;
            width: 100%;
        }

        &-stats {
            width: 100%;
        }

        &-next {
            .q-btn .q-icon {
                font-size: $large-title !important;
            }
        }
    }

    .q-separator {
        margin: $space-sm 0;
    }

    &__footer {
        display: flex;
        flex-direction: row;
        justify-content: end;
        align-items: flex-end;
        margin-top: $space-sm;
        cursor: pointer;

        .tag-button {
            height: 35px;
            padding: 10px;
            border-radius: 8px;
            font-size: $body-text;
        }

        &-left .base-tag {
            min-height: 10px;

            .q-icon {
                padding: 0px 3px;
            }

        }

        &-right {
            display: flex;
            flex-direction: row;

            .q-icon {
                padding: 0px 3px;
            }
        }
    }

    &__comments {
        &-input {
            display: flex;
            flex-direction: row;
            gap: 5px;
            width: 100%;

            input {
                background-color: $color-neutral-grey-200;
                border: 1px solid $color-neutral-grey-200;
                border: none;
                width: 100%;
                padding: 5px;
                border-radius: 8px;

                &:focus {
                    outline: none;
                    border: 1px solid $color-neutral-grey-400 !important;
                }
            }
        }

        &-filterbuttons {
            display: flex;
            justify-content: space-between;
            background-color: $color-neutral-white;
            position: sticky;
            top: 0px;
            gap: 10px;

            button {
                margin: 2px;
                padding: 0.3rem;
                width: 100%;
            }
        }

        &-section {
            overflow-y: scroll;
            overflow-x: hidden;
            max-height: 300px;
            padding: 0 $space-sm;
            margin: $space-sm 0;
            background-color: $color-neutral-grey-100;
            border-radius: 8px;

            &-none {
                padding: $space-sm;
            }

            //scrollbar styles
            &::-webkit-scrollbar {
                border-radius: 5px;
                width: 7px;
            }

            &::-webkit-scrollbar-track {
                background-color: transparent;
            }

            &::-webkit-scrollbar-thumb {
                background-color: $color-neutral-grey-400-50;
                border-radius: 10px;
            }
        }
    }
}


.base-tag {
    &.count {
        padding-right: 0px;
    }

    &.highlight {
        color: $color-primary-bright;
    }
}

.flexgrow {
    flex-grow: 2;
}


//banner
.banner-enter-active,
.banner-leave-active {
    transition: opacity 0.3s ease-in-out;
}

.banner-enter-from,
.banner-leave-to {
    opacity: 0;
}

//answers
.answers-enter-active,
.answers-leave-active {
    transition: opacity 0.3s ease-in-out;
}

.answers-enter-from,
.answers-leave-to {
    opacity: 0;
}

//comment
.comment-enter-active,
.comment-leave-active {
    transition: opacity 0.3s ease-in-out;
}

.comment-enter-from,
.comment-leave-to {
    opacity: 0;
}

@media screen and (min-width: $breakpoint-md) {

    .base-form__content {
        padding: $space-lg;
    }
}

</style>