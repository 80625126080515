<script setup lang="ts">

import { ref } from "vue";
import BaseDialog from "@/components/base/BaseDialog.vue";
import { useI18n } from 'vue-i18n';
import BaseButton from "@/components/base/BaseButton.vue";
import { authService } from "@/services/auth.service";


// PROPS
const props = defineProps<{
    homeLink: boolean;
    needLogin: boolean;
}>();

// REFS
const { t } = useI18n();
const consultationPrivateNoAccessDialogRef = ref<InstanceType<typeof BaseDialog>>();


// FUNCTIONS
function openDialog() {
    consultationPrivateNoAccessDialogRef.value?.openDialog();
}

function closeDialog() {
    consultationPrivateNoAccessDialogRef.value?.closeDialog();
}


defineExpose({
    openDialog,
    closeDialog,
});

function goBackHomeClick() {
    window.location.href = "/"
}

function loginClick() {
    authService.login();
}

</script>

<template>
    <BaseDialog ref="consultationPrivateNoAccessDialogRef">
        <template v-slot:title>
            <div class="consultationPrivateNoAccess-title">
                {{ t('dialog.consultationPrivateNoAccessDialog.title') }}
            </div>
        </template>
        <template v-slot:content>
            <div class="consultationPrivateNoAccess-content">
                <p v-if="needLogin">
                    {{ t('dialog.consultationPrivateNoAccessDialog.contentNeedLogin') }}
                </p>
                <p v-else>
                    {{ t('dialog.consultationPrivateNoAccessDialog.content') }}
                </p>
                <div class="consultationPrivateNoAccess-actions">
                    <BaseButton v-if="homeLink" class="action" @click="goBackHomeClick">{{
                            t('dialog.consultationPrivateNoAccessDialog.home')
                        }}
                    </BaseButton>
                    <BaseButton v-if="needLogin" class="action" @click="loginClick">{{
                            t('dialog.consultationPrivateNoAccessDialog.login')
                        }}
                    </BaseButton>
                </div>


            </div>
        </template>
    </BaseDialog>
</template>

<style lang="scss">
.consultationPrivateNoAccess {


    &-title {
        font-size: $medium-title;
    }

    &-actions {
        display: flex;
        justify-content: end;
        margin-top: $space-md;
    }

}

.base-dialog {
    background-color: $color-neutral-background !important;
}

</style>