<script setup lang="ts">

</script>

<template>
    <div class="base-card__wrapper shadow">
        <slot name="content">
        </slot>
    </div>
</template>

<style lang="scss">

.base-card__wrapper {
    background-color: $color-neutral-white;
    border-radius: 8px;
}
</style>