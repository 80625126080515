import axios, { AxiosRequestConfig } from "axios";
import { getSubdomain } from '@/utils/sub-domain';

function getHeaders() {
    const headers: any = {
        "Content-Type": "application/json"
    };

    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
        headers['Authorization'] = `Bearer ${accessToken}`;
    }
    headers['X-Community-Slug'] = getSubdomain();

    return headers;
}

export async function getRequest(url: string, body?: any, type: AxiosRequestConfig['responseType'] = 'json') {
    const config: AxiosRequestConfig = {
        method: 'get',
        url: url,
        headers: getHeaders(),
        params: body,
        responseType: type,
    };

    return (await request(config)).data;
}

export async function postRequest(url: string, body?: any, type: AxiosRequestConfig['responseType'] = 'json') {

    const config: AxiosRequestConfig = {
        method: 'post',
        url: url,
        headers: getHeaders(),
        data: body,
        responseType: type
    };

    return (await request(config)).data;
}

export async function putRequest(url: string, body?: any, type: AxiosRequestConfig['responseType'] = 'json') {
    const config: AxiosRequestConfig = {
        method: 'put',
        url: url,
        headers: getHeaders(),
        data: body,
        responseType: type
    };

    return (await request(config)).data;
}

export async function patchRequest(url: string, body?: any, type: AxiosRequestConfig['responseType'] = 'json') {
    const config: AxiosRequestConfig = {
        method: 'patch',
        url: url,
        headers: getHeaders(),
        data: body,
        responseType: type
    };

    return (await request(config)).data;
}

export async function deleteRequest(url: string, body?: any, type: AxiosRequestConfig['responseType'] = 'json') {
    const config: AxiosRequestConfig = {
        method: 'delete',
        url: url,
        headers: getHeaders(),
        data: body,
        responseType: type
    };

    return (await request(config)).data;
}

export async function request(config: AxiosRequestConfig): Promise<any> {
    try {
        return await axios(config);
    } catch (err: any) {
        console.error(err);
        throw err;
    }
}

export default {
    get: getRequest,
    post: postRequest,
    put: putRequest,
    patch: patchRequest,
    delete: deleteRequest
};
