<script setup lang="ts">

import { ref, onMounted } from "vue";
import BaseInput from '@/components/base/BaseInput.vue';
import BaseSelect from '@/components/base/BaseSelect.vue';


import { useField } from 'vee-validate';
import { mask } from '@/utils/mask-util';
import { useUserStore } from "@/store/userStore";

// CONST 
const { value: birthYear, errorMessage: errorMessageYear } = useField<number>('birthYear');
const { value: postalCode, errorMessage: errorMessageCode } = useField<string>('postalCode');
const { value: gender, errorMessage: errorMessageGender } = useField<object>('gender');
const userStore = useUserStore();


// LIFECYCLE
onMounted(() => {
    userStore.loadGenders();
});

</script>

<template>
    <div class="demo-questions">
        <div class="demo-questions-year">
            <div class="label">{{ $t('profile.birth-year')}}</div>
            <BaseInput :mask="mask.year" v-model="birthYear"/>
            <span class="error">{{ errorMessageYear }}</span>
        </div>
        <div class="demo-questions-postal">
            <div class="label">{{ $t('profile.postal-code')}}</div>
            <BaseInput :mask="mask.postalCode" v-model="postalCode"/>
            <span class="error">{{ errorMessageCode }}</span>
        </div>
        <div class="demo-questions-gender">
            <div class="label">{{ $t('profile.gender')}}</div>
            <BaseSelect v-model="gender" :options="userStore.genders"/>
            <span class="error">{{ errorMessageGender }}</span>
        </div>
    </div>
</template>

<style lang="scss">
.demo-questions {
    display: flex;
    flex-direction: column;
    gap: 10px;
    
    .label {
        font-size: $small-text;
        padding: 0 0 $space-xs;
        font-weight: bold;
    }
}
</style>