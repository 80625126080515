<script setup lang="ts">
// EMITS
import { ref } from "vue";

const emit = defineEmits<{
	(e: "upload", image: string): void;
}>();

const props = defineProps<{
	text?: string;
	textColor?: string;
	isIconOnly?: boolean;
	isRound?: boolean;
	isSmall?: boolean;
	isTransparent?: boolean;
	disabled?: boolean;
	colorIcon?: string;
	icon?: string;
	leftIcon?: string;
	rightIcon?: string;
	isLoading?: boolean;
	hasBorder?: boolean;
	fullWidth?: boolean;
	primary?: boolean;
	secondary?: boolean;
	danger?: boolean;
	isOrange?: boolean;
	fileUpload?: boolean;
	isAligned?: any;
	isSelected?: boolean;
}>();

const leftPadding =
	(props.leftIcon && !props.isIconOnly) || props.colorIcon ? "6px" : "0px";
const rightPadding = props.rightIcon && !props.isIconOnly ? "6px" : "0px";
const file = ref();

function openFileDialog() {
	if (props.fileUpload) file.value.click();
}

defineExpose({
	openFileDialog,
});
</script>

<template>
	<q-btn
		@click="openFileDialog"
		class="base-button"
		:class="{
			primary: props.primary,
			secondary: props.secondary,
			danger: props.danger,
			fileUpload: props.fileUpload,
			'is-orange': props.isOrange,
			'is-small': props.isSmall,
			'is-round': props.isRound,
			'is-icon-only': props.isIconOnly,
			'is-transparent': props.isTransparent,
			'is-disabled': props.disabled,
			'full-width': props.fullWidth,
		}"
		:loading="props.isLoading"
		:disable="props.disabled"
		:align="props.isAligned"
		unelevated
		no-caps
	>
		<slot>
			<input
				class="base-button__file-upload"
				v-if="props.fileUpload"
				@change="emit('upload', $event)"
				type="file"
				ref="file"
				accept="image/*"
			/>
			<q-icon
				class="base-button__icon-selected"
				name="check_circle_outline"
				v-if="props.isSelected"
			/>
			<q-icon
				class="base-button__icon"
				:name="props.leftIcon"
				v-if="props.leftIcon"
			/>
			<q-icon class="base-button__icon" v-if="colorIcon">
				<img :src="props.colorIcon" alt="button-icon" />
			</q-icon>
			<span class="base-button__text">{{ props.text }}</span>
			<q-icon
				class="base-button__icon"
				:name="props.rightIcon"
				v-if="props.rightIcon"
			/>
			<q-icon
				class="base-button__icon"
				:name="props.icon"
				v-if="props.icon"
			/>
		</slot>
		<slot name="menu" />
	</q-btn>
</template>

<style lang="scss">
.q-btn.base-button {
	color: $color-neutral-black;
	border-radius: 8px;
	background-color: $color-neutral-grey-100;
	line-height: normal;

	&:hover {
		background-color: $color-neutral-hover;
	}

	.q-spinner {
		font-size: $header;
	}
}

.q-btn .row {
	flex-wrap: nowrap;
}

.base-button {
	transition: 200ms;

	&__file-upload {
		display: none;
	}

	.q-icon {
		padding-left: v-bind(rightPadding);
		padding-right: v-bind(leftPadding);
		transition: 200ms;
	}

	&.full-width {
		width: 100%;
	}

	&__icon {
		font-size: $medium-title !important;

		&-selected {
			margin-right: $space-sm;
		}
	}

	&__text {
		font-size: $body-text !important;
	}

	&.is-round {
		border-radius: 18px;
	}

	&.is-transparent {
		background: transparent;
	}

	&.is-small {
		min-height: 30px !important;
	}

	&.is-disabled {
		color: $color-neutral-grey-400-50 !important;
		background-color: $color-neutral-grey-300 !important;

		&:hover {
			background-color: $color-neutral-grey-300;
		}
	}

	&.primary {
		background-color: $color-primary;
		color: $color-neutral-white;

		&:hover {
			background-color: $color-primary-darken;
		}
	}

	&.primary-outline{
		border: 2px solid $color-neutral-grey-300;
		background-color: $color-neutral-white;
		color: $color-primary-darken;

		&:hover{
			background-color: $color-neutral-grey-100;
			font-weight: bold;
		}

		&:active{
			border: 1px solid $color-primary;
		}
	}

	&.blue{
		background-color: $color-primary-12;
		color: $color-primary-bright;
		border: 1px solid $color-primary-bright;
		font-weight: 500;
		box-shadow: 0px 4px 8px 0px #80BEDD1A;

		&:hover {
			background-color: $color-primary-12;
		}
	}

	&.blue-secondary{
		background-color: $color-primary-12;
		color: $color-neutral-black;
		font-weight: 400;

		&:active {
			background-color: $color-primary;
		}
	}

	&.secondary {
		background-color: $color-neutral-white;
		border: 1px solid $color-neutral-grey-400;
		color: $color-neutral-grey-400;

		&:hover {
			background-color: $color-neutral-grey-100;
		}
	}

	&.is-orange {
		background-color: $color-neutral-white;
		border: 3px solid $color-orange;
		color: $color-orange;
		font-weight: bold;
		text-decoration: none;

		&:hover {
			background-color: $color-neutral-grey-100;
		}
	}

	&.danger {
		background-color: $color-red-50;
		color: $color-neutral-white;

		&:hover {
			background-color: $color-red;
		}
	}

	&.light-red {
		background-color: $color-red-50;
	}

	&.light-green {
		background-color: $color-green-50;
	}

	&.primary--text {
		color: $color-primary;
	}

	&.action {
		background-color: $color-primary;
		color: $color-neutral-white;

		&:hover {
			background-color: $color-primary-50;
		}
	}
}

.q-icon {
	padding-left: v-bind(rightPadding);
	padding-right: v-bind(leftPadding);
	transition: 200ms;
}
</style>
