<script setup lang="ts">

import { ref } from "vue";
import mobileMenu from "@/components/mobile/mobileMenu.vue";
import LoginDialog from "@/components/dialogs/LoginDialog.vue";
import BaseDialog from "@/components/base/BaseDialog.vue";
import AccountSection from "@/components/AccountSection.vue";
import LocaleSelect from "@/components/LocaleSelect.vue";

import { useUserStore } from "@/store/userStore";
import { useCommunityStore } from '@/store/communityStore';
import { paths } from "@/utils/paths";
import { authService } from "@/services/auth.service";
import useResponsive from "@/utils/responsive-util"
import { Config } from '@/config'
import { useRoute } from "vue-router";

// STORE
const userStore = useUserStore();
const communityStore = useCommunityStore();

// REFS
const loginDialogRef = ref<InstanceType<typeof BaseDialog>>();

// CONST
const { isMobile } = useResponsive()
const results = ref(false)
const route = useRoute();

// EMITS
const emit = defineEmits<{
	(e: "results", value): void;
}>();

// FUNCTIONS
function onLogin() {
    authService.login();
}

function removeTimestamp(url: string): string {
    if (url) {
        return url.split("&t=")[0];
    }
    return '';
}

const openAdmin = () => {
    window.open(`${Config.admin.url}`, '_blank')
}

function openResults() {
    results.value = !results.value;
    emit('results', results.value)
}

function shouldDisplayAdmin() {
    return authService.isAdmin() || authService.isEditor();
}

function shouldDisplayResults() {
    if (!route.path.includes('consultation')) {
        return false;
    } else if (authService.isAdmin()) {
        return true;
    }
    const slug = route.params.slug as string;
    const consultation = communityStore.community.consultations.find(x => x.slug === slug);
    return authService.isEditor(consultation?.id);
}

</script>

<template>
    <div class="header">
        <div class="header__items">
            <router-link class="header__logo" :to="paths.root">
                <img :src="removeTimestamp(communityStore.community.logoFile.url)" alt="logo"/>
            </router-link>

            <template v-if="isMobile">
                <div class="header__mobile">
                    <AccountSection v-if="userStore.isUserLogged"/>
                    <mobile-menu @open-login-dialog="onLogin()"
                                 :language-enabled="communityStore.community.languageEnabled"
                    />
                </div>

            </template>

            <template v-else>
                <div class="header__navigation">
                    <q-badge v-if="shouldDisplayResults()" color="secondary" class="q-mr-md cursor-pointer"
                             @click="openResults">{{$t('consultation.results')}}
                    </q-badge>
                    <q-badge v-if="shouldDisplayAdmin()" color="primary" class="q-mr-md cursor-pointer"
                             @click="openAdmin">admin
                    </q-badge>
                    <router-link class="header__navigation--item" :to="paths.about">
                        {{ $t("about-us") }}
                    </router-link>
                    <q-separator vertical/>
                    <div class="header__navigation--locale">
                        <LocaleSelect v-if="communityStore.community.languageEnabled.length > 1"
                                      :language-enabled="communityStore.community.languageEnabled"/>
                    </div>
                    <q-separator v-if="communityStore.community.languageEnabled.length > 1" vertical/>
                    <AccountSection v-if="userStore.isUserLogged"/>
                    <div v-else class="header__navigation--item" @click="onLogin()">
                        {{ $t("account.login") }}
                    </div>
                </div>
            </template>
        </div>
    </div>
    <LoginDialog ref="loginDialogRef"/>
</template>

<style lang="scss">
.header {
    z-index: 2;
    position: -webkit-sticky;
    position: sticky !important;
    top: 0;
    align-items: center;
    background-color: $color-neutral-white;
    box-shadow: 0 2px 10px rgba(1, 0, 0, 0.1);
    min-height: $header-height;

    &__items {
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 32px;
    }

    &__logo {
        img {
            height: 30px;
        }

        &:hover {
            border-bottom: 1px solid transparent;
        }
    }


    &__navigation {
        display: flex;
        align-items: center;

        &--item {
            text-decoration: none;
            border-bottom: 1px solid transparent;
            color: $color-neutral-black;
            transition: all 200ms ease;
            cursor: pointer;

            &:hover {
                color: $color-primary;
                border-bottom: 1px solid transparent;
                transition: all 200ms ease;
            }
        }

        &--icon {
            margin-left: 24px;
            height: 24px !important;
            width: 24px !important;
        }
    }

    .q-separator {
        margin: $space-xs $space-md;
        height: 32px;
    }

    &__menu {
        .q-item__section {
            margin: 0 16px;
        }
    }
}

@media screen and (max-width: $breakpoint-xl) {
    .header {
        &__mobile {
            display: flex;
        }

        &__items {
            margin: 0 20px;
        }
    }
}

@media screen and (max-width: $breakpoint-sm) {
    .header {

        &__items {
            margin: 0 10px;
        }
    }
}
</style>
