import { computed, onMounted, ref, onUnmounted } from 'vue';

export default function useResponsive() {
    const windowWidth = ref(window.innerWidth);

    const breakPoints = {
        mobile: 768,
        tablet: 1024,
        desktop: 1440,
    }

    const isDesktop = computed((): boolean => {
        return windowWidth.value >= breakPoints.desktop;
    });

    const isTablet = computed((): boolean => {
        return windowWidth.value > breakPoints.mobile && windowWidth.value < breakPoints.desktop;
    });

    const isMobile = computed((): boolean => {
        return windowWidth.value <= breakPoints.mobile;
    });

    function onResize() {
        windowWidth.value = window.innerWidth
    }

    onMounted(() => {
        window.addEventListener('resize', onResize);
    });


    onUnmounted(() => {
        window.removeEventListener('resize', onResize);
    })

    return {
        isMobile,
        isTablet,
        isDesktop
    };
}