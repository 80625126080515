<script setup lang="ts">

import { getTranslation } from "@/utils/data-display-utils";
import { useCommunityStore } from "@/store/communityStore";
import { storeToRefs } from "pinia";
import { useRoute } from 'vue-router';
import CustomPagesMenu from '@/components/CustomPagesMenu.vue';
import { computed } from 'vue';
import { QLayout, QPageContainer, QPage } from "quasar";

const route = useRoute();

const communityStore = useCommunityStore();
const { community, customPageLoaded } = storeToRefs(communityStore);

const page = computed(() => {
  return community.value.customPages.find(p => p.slug === route.params.slug);
});

const getBanner = computed(() => {
  return communityStore.community.bannerFile.url;
})
</script>

<template>
  <q-layout>
    <q-page-container>
      <q-page>
        <div class="consultation__banner">
          <img :src="getBanner"
               alt="theme-banner" />
        </div>
        <div class="custom-page">
          <div class="custom-page__menu">
            <custom-pages-menu />
          </div>
          <div class="custom-page__content">
            <div class="custom-page__content-consultation"
                 v-if="customPageLoaded && page">
              <div v-html="getTranslation(page, 'content')"></div>
            </div>
          </div>
        </div>
      </q-page>
    </q-page-container>
  </q-layout>
</template>

<style lang="scss">
.custom-page {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: $space-xl;
  margin-top: $space-xl;
  font-size: $body-text;

  &__content {
    max-width: 720px;

    &-consultation {
      font-size: $header;
      margin-bottom: $space-lg;
    }

  }
}

@media screen and (max-width: $breakpoint-sm) {
  .about-page {
    padding: $space-md;
  }
}
</style>