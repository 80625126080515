<script setup lang="ts">

import { ref } from 'vue';
import BaseButton from './BaseButton.vue';

// CONST
const userComment = ref<string>("");

// EMITS
const emit = defineEmits<{
    (e: "submitComment", userComment): void;
}>();


// FUNCTIONS 
function submitComment() {
    emit('submitComment', userComment);
}

</script>
<template>
    <div class="base-form__comments-input">
        <input
            type="text"
            v-model="userComment"
            name="comment"
            :placeholder="$t('form.comment-placeholder')"
        >
        <BaseButton
            class="action"
            icon="o_send"
            @click="submitComment">
        </BaseButton>
    </div>
</template>
<style lang="scss">
.base-form__comments-input {
    .q-icon {
        font-size: 20px !important;
    }
}
</style>