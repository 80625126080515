import { Notify } from "quasar";

type Type = "success" | "error" | "info"

function showToaster(message: string, type: Type) {
    Notify.create({
        message: message,
        classes: `toaster__${type}`
    });
}

export { showToaster };
