<script setup lang="ts">
import { computed, defineProps } from "vue";
import { useRoute } from "vue-router";

const route = useRoute();

// COMPUTED
const getPropositions = computed(() => {
    return route.params.id;
})

</script>

<template>
    <div>

        <div>Propositions #{{ getPropositions }}</div>
    </div>
</template>

<style lang="scss">
</style>