import { Config } from '@/config';
import apiService from "@/services/api.service";
import axios, { AxiosResponse, CancelTokenSource } from 'axios';
import { useCommunityStore } from '@/store/communityStore';
import {Consultation} from '@/store/consultation.interface';

const CancelToken = axios.CancelToken;
let cancelTokenConsultations: CancelTokenSource | null = null;

class ConsultationsService {
  async getConsultations(): Promise<Consultation[]> {
    if (cancelTokenConsultations) {
      cancelTokenConsultations.cancel('Multiple requests.');
    }

    cancelTokenConsultations = CancelToken.source();
    const response = await apiService.get(
      `${Config.api.backend.urlV2}/consultations`
    );
    const consultationsData: any = response.data;
	if (consultationsData) {
		const communityStore= useCommunityStore();
		communityStore.setConsultation(consultationsData);
	}
    return response.data;
  }
}

export const consultationsService = new ConsultationsService();