import { Config } from '@/config';
import apiService from "@/services/api.service";
import { useCommunityStore } from '@/store/communityStore';
import { useUserStore } from '@/store/userStore';
import { jwtDecode, type JwtPayload } from 'jwt-decode';
import { currentLocale } from "@/plugins/i18n";
import { constant } from '@/constant';

interface getUser {
    token: string;
}

interface AccessToken extends JwtPayload {
    user_id: number;
    community: number;
    rank: number;
    consultationIds: number[] | null;
}

class AuthService {
    async login(consultationId: string = null, section: number = null, form: number = null) {
        try {
            const communityStore = useCommunityStore();
            const communityId = communityStore.community.id;
            const redirectionConsultation = consultationId ? `&consultation=${consultationId}` : ""
            const redirectSection = section ? `&section=${section}` : "";
            const redirectForm = form ? `&form=${form}` : "";
            window.location.href = `${Config.api.backend.urlAuth}/login?lang=${currentLocale()}&communityId=${communityId}&redirect_uri=${window.location.protocol}//${window.location.host}/login${redirectionConsultation}${redirectSection}${redirectForm}`;
        } catch (error) {
            console.error(error);
        }
    }

    async logout() {
        try {
            window.location.href = `${Config.api.backend.urlAuth}/login/logout?redirect_uri=${window.location.protocol}//${window.location.host}/logout`;
        } catch (error) {
            console.error(error);
        }
    }

    async loginUser(token: string) {
        const communityStore = useCommunityStore();
        localStorage.setItem("accessToken", token);
        // TODO: authorize user to community and get an updated token
        const authorizeCommunityResponse = await apiService.get(`${Config.api.backend.urlAuth}/login/authorize-community`);
        localStorage.setItem("accessToken", authorizeCommunityResponse.data.accessToken);
        const response = await apiService.get(`${Config.api.backend.urlV2}/user`);
        const userStore = useUserStore();
        userStore.setUser(response.data.user);
    }

    async logoutUser() {
        localStorage.removeItem("accessToken");
        const userStore = useUserStore();
        userStore.setUser(null);
    }

    async initUser() {
        const accessToken = localStorage.getItem("accessToken");
        console.debug(`authService.initUser: accessToken=${accessToken}`);
        if (accessToken) {
            await this.getUser();
        }
    }

    async getUser() {
        try {
            const me = await apiService.get(`${Config.api.backend.urlV2}/user`);
            const authorize = await apiService.get(`${Config.api.backend.urlAuth}/login/authorize-community`);
            localStorage.setItem("accessToken", authorize.data.accessToken);
            const userStore = useUserStore();
            userStore.setUser(me.data.user);
            userStore.setConsentEmail(authorize.data.emailConsent === 1);
            userStore.setTermsApproval(authorize.data.terms_approval === 1);
            userStore.setCommunityMailingListMember(authorize.data.isCommunityMailingListMember === 1);
        } catch (error) {
            console.error('API Error:', error);
        }
    }

    async setTermsAndEmailConsent(termsApproval: number, emailConsent: number) {
        const userStore = useUserStore();

        const requestBody: any = {
            terms_approval: termsApproval,
            email_consent: emailConsent,
        };
        try {
            const response = await apiService.post(`${Config.api.backend.urlV2}/user/:set-consents`, requestBody);

            // TODO - should get the user data from the response
            userStore.setConsentEmail(emailConsent === 1)
            userStore.setTermsApproval(termsApproval === 1);
            userStore.setCommunityMailingListMember(emailConsent === 1);

            return response;

        } catch (error) {
            console.error('API Error:', error);
        }
    }

    isAdmin() {
        const token = localStorage.getItem("accessToken");
        if (token) {
            const decoded = jwtDecode<AccessToken>(token);
            return decoded.rank === 1;
        }
        return false;
    }

    isEditor(consultationId: number | null = null) {
        const token = localStorage.getItem("accessToken");
        if (token) {
            const decoded = jwtDecode<AccessToken>(token);
            return decoded.rank === constant.User.Role.EDITOR &&
                (!consultationId || decoded.consultationIds?.includes(consultationId));
        }
        return false;
    }
}

export const authService = new AuthService();
