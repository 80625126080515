<script setup lang="ts">

import { ref } from 'vue';
import FullscreenMap from '@/components/map/FullscreenMap.vue';
import MapContainer from '../map/MapContainer.vue';

// PROPS
const props = defineProps<{
    id: string;
    mapConfig: any;
    mapMarkers: any[];
    consultationIsCompleted: boolean;
}>();

// EMITS
const emit = defineEmits<{
    (e: "submitMarker", marker),
    (e: "verifyUser"): void;
}>();


// CONST
const fullscreenMapRef = ref(null);
const showInfo = ref<boolean>(false);

// FUNCTIONS
function toggleInfo() {
    showInfo.value = !showInfo.value;
}

function openFullscreenMap() {
    fullscreenMapRef.value.openDialog();
}

function closeInfo() {
    showInfo.value = false;
}

function submitMarker(marker) {
    emit('submitMarker', marker)
}

function verifyUser(){
    emit('verifyUser')
}

</script>
<template>
    <div class="map-question">
        <MapContainer :id="props.id" :mapConfig="props.mapConfig" :mapMarkers="props.mapMarkers" @submit-marker="submitMarker" @fullscreen-map="openFullscreenMap" @verify-user="verifyUser"  :consultation-is-completed="consultationIsCompleted"/>
    </div>
</template>
<style lang="scss">
.map-question {
    background-color: $color-neutral-grey-300;
    padding: 12px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;

    .map {
        width: 100%;

        .mapboxgl-ctrl-group:not(:empty) {
            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

            button {
                color: $color-primary-darken;
            }
        }
    }
}
</style>
