<script setup lang="ts">
import BaseDialog from "@/components/base/BaseDialog.vue";
import BaseButton from "@/components/base/BaseButton.vue";
import { useI18n } from "vue-i18n";
import { ref, computed, watchEffect, watch, onMounted, nextTick } from "vue";
import Facebook from "@/assets/icons/facebook_icon.svg";
import Google from "@/assets/icons/google_icon.svg";
import LoginButton from "@/components/base/BaseLoginButton.vue";
import { useUserStore } from "@/store/userStore";
import BaseInput from "@/components/base/BaseInput.vue";
import { authService } from "@/services/auth.service";
import { useCommunityStore, Community } from '@/store/communityStore';
import { groupsService } from "@/services/groups.service";
import { currentLocale } from "@/plugins/i18n";
import {getTranslation} from "@/utils/data-display-utils";

// STORE
const userStore = useUserStore();
const communityStore = useCommunityStore();

// REFS
const loginDialogRef = ref<InstanceType<typeof BaseDialog>>();
const slide = ref<string>("start");
const currentSlide = ref<string>("");
const width = ref<string>("600px");
const ready = ref<boolean>(true);
const identificationType = ref<string>("email");
const otpError = ref<boolean>(false);
const code = ref<string[]>(["", "", "", "", ""]);
const formattedCode = ref<number>(0);
const email = ref<string>("");
const phoneNumber = ref<string>("");
const loginForm = ref<QForm | null>(null);
const isFormValid = ref(false as boolean | undefined);
const verificationCode = ref<string[]>(["", "", "", "", ""]);

//CONST
const { t } = useI18n();
const fakeCode = 9876;
const slideNames = ["start", "identification", "validation"];

// WATCHER
watchEffect(() => {
	formattedCode.value = parseInt(code.value.join("")) || 0;
});
watch([email, phoneNumber], async () => {
	if (email.value !== "" || phoneNumber.value !== "") {
		isFormValid.value = await loginForm.value?.validate();
	} else {
		isFormValid.value = false;
	}
});

// COMPUTED
const onMainPage = computed(() => {
	return slide.value === "start";
});

const method = computed(() => {
	return identificationType.value === "email" ? email.value : phoneNumber.value;
});

// FUNCTIONS
function openDialog() {
	loginDialogRef.value?.openDialog();
}

function closeDialog() {
	resetDialog();
	loginDialogRef.value?.closeDialog();
}


function digitValidateVerificationCode(
  event: { target: { value: string; nextElementSibling: any } },
  index: number
) {
  event.target.value = event.target.value.replace(/[^0-9]/g, "");
  const inputValue = event.target.value;
  verificationCode.value[index] = inputValue;
  if (index === 4) validateVerificationCode();

  if (inputValue !== "" && index < verificationCode.value.length - 1) {
    const nextInput = event.target.nextElementSibling;
    if (nextInput) {
      nextInput.focus();
    }
  }
}

function tabChangeVerificationCode(
  event: { key: string; target: { previousElementSibling: any } },
  index: number
) {
  if (event.key === "Backspace" && index > 0) {
    const previousInput = event.target.previousElementSibling;
    if (previousInput) {
      previousInput.focus();
    }
  }
}

async function validateVerificationCode() {
  ready.value = false;
  otpError.value = false;
  setTimeout(async () => {
    const enteredVerificationCode = parseInt(verificationCode.value.join(""));
	const response: any = await authService.checkOtpCode(method.value, enteredVerificationCode.toString());
    if (!response) {
      otpError.value = true;
      resetVerificationCodeInput();
    } else {
      closeDialog();

      userStore.logInUser(response.data);
      const { token } = response.data;
      const communityStore = useCommunityStore();
    }
    ready.value = true;
  }, 1000);
}

function resetVerificationCodeInput() {
  verificationCode.value = ["", "", "", "", ""];
  const inputs = document.querySelectorAll(".otp");
  inputs[0].focus();
}

async function sendOtpCode(type: string, method: string) {
  try {
    await authService.handleOtpLogin(method, type);
    goToValidationPage();
  } catch (error) {
    console.error('Error sending OTP code:', error);
  }
}

async function loginFacebook() {
	const response = await authService.signInWithFacebook();
}

async function goToIdentificationPage(type: string) {
	slide.value = "identification";
	identificationType.value = type;
}

function goToValidationPage() {
	ready.value = false;
	setTimeout(() => {
		slide.value = "validation";
		ready.value = true;
	}, 1000);
}

function goToPreviousSlide() {
	const index = slideNames.indexOf(currentSlide.value);
	slide.value = slideNames[index - 1];
	email.value = "";
	phoneNumber.value = "";
	otpError.value = false;
}

function storeSlideInfo(slideName: string) {
	currentSlide.value = slideNames.find((slide) => slide === slideName);
	if (currentSlide.value === slideNames[2]) {
		firstInputFocus();
	}
}

function updateEmailInput(value: string) {
	email.value = value;
}

function updatePhoneNumberInput(value: string) {
	phoneNumber.value = value;
}

const getCommunityTranslation = (community: Community, field: "name" | "context"): string => {
  let language_code = currentLocale();
  language_code = language_code === "fr" ? "fr-CA" : "en-CA";
  const translation = community.translations.find(
    (t) => t.language_code === language_code
  );
  return translation ? translation[field] : "";
};

function removeTimestamp(url: string): string {
  if (url) {
	return url.split("&t=")[0];
  }
  return '';
}

function resetDialog() {
	slide.value = slideNames[0];
	email.value = "";
	phoneNumber.value = "";
	code.value = ["", "", "", ""];
	otpError.value = false;
}

function resetCodeInput() {
	code.value = ["", "", "", ""];
	const inputs = document.querySelectorAll("input");
	inputs[0].focus();
}

function firstInputFocus() {
	setTimeout(() => {
		const inputs = document.querySelectorAll("input");
		inputs[0].focus();
	}, 250);
}

defineExpose({
	openDialog,
	closeDialog,
});
</script>

<template>
	<BaseDialog ref="loginDialogRef" @close="resetDialog()">
		<template v-slot:title>
			<div class="login-dialog__header">
				<img class="login-dialog__header--logo" :src="removeTimestamp(communityStore.community.logoUrl)" alt="logo" />
				<div class="login-dialog__header__content">
					<div class="login-dialog__header__content--title">
						{{ $t("login-modal.welcome", { client: getTranslation(communityStore.community, 'title') }) }}
					</div>
				</div>
				<BaseButton
					class="login-dialog__header--button"
					v-if="!onMainPage"
					is-transparent
					left-icon="o_arrow_backward"
					:text="$t('buttons.back')"
					@click="goToPreviousSlide()"
				/>
			</div>
		</template>
		<template v-slot:content>
			<q-carousel
				ref="carousel"
				v-model="slide"
				transition-prev="slide-right"
				transition-next="slide-left"
				animated
				control-color="primary"
				class="rounded-borders"
				transition-duration="200"
				@before-transition="storeSlideInfo"
			>
				<q-carousel-slide :name="slideNames[0]">
					<div class="login-dialog__text">
						<div
							class="login-dialog__text--link"
							v-html="$t('login-modal.acceptation-text')"
						/>
					</div>
					<div class="login-dialog__buttons">
						<LoginButton
							class="login-dialog__button"
							:text="$t('login-modal.login-modal', { social: 'Facebook' })"
							:icon="Facebook"
							@click="loginFacebook()"
						/>
						<LoginButton
							class="login-dialog__button"
							:text="$t('login-modal.login-modal', { social: 'Google' })"
							:icon="Google"
						/>
					</div>
					<div class="login-dialog__transition">{{ $t("login-modal.or") }}</div>
					<div class="login-dialog__code">
						<div class="login-dialog__code--text">
							{{ $t("login-modal.send-me-code-by") }}
						</div>
						<LoginButton
							class="login-dialog__button"
							:text="$t('login-modal.email')"
							@click="goToIdentificationPage('email')"
						/>
						<LoginButton
							class="login-dialog__button"
							:text="$t('login-modal.phone')"
							@click="goToIdentificationPage('phone')"
						/>
					</div>
				</q-carousel-slide>
				<q-carousel-slide :name="slideNames[1]">
					<div class="slide">
						<div class="slide__code">
							<div class="slide__code--title">
								{{ $t("login-modal.code-identification") }}
							</div>
							<div
								class="slide__code--text"
								v-if="identificationType === 'email'"
							>
								{{ $t("login-modal.enter-email") }}
							</div>
							<div class="slide__code--text" v-else>
								{{ $t("login-modal.enter-phone-number") }}
							</div>
							<q-form ref="loginForm" @submit="goToValidationPage">
								<div
									class="slide__code--identification-type"
									v-if="identificationType === 'email'"
								>
									<BaseInput
										class="slide__code--identification"
										:model="email"
										@update="updateEmailInput"
										placeholder="XXX@xxx.com"
										rules="email"
									/>
								</div>
								<div class="slide__code--identification-type" v-else>
									<BaseInput
										class="slide__code--identification"
										:model="phoneNumber"
										@update="updatePhoneNumberInput"
										placeholder="### ###-####"
										rules="phoneNumber"
										mask="phoneNumber"
									/>
								</div>
								<BaseButton
									type="submit"
									:disabled="!isFormValid"
									primary
									full-width
									:is-loading="!ready"
									class="slide__button"
									:text="$t('login-modal.send-code')"
									@click="sendOtpCode(identificationType, method)"
								/>
							</q-form>
						</div>
					</div>
				</q-carousel-slide>
				<q-carousel-slide :name="slideNames[2]">
					<div class="slide">
						<form class="slide__code">
							<div class="slide__code--title">
								{{ $t("login-modal.code-identification") }}
							</div>
							<div class="slide__code--text">
								{{ $t("login-modal.enter-received-code", { method: method }) }}
							</div>
							<div class="slide__code--inputs">
								<input
								class="otp"
								v-model="verificationCode[0]"
								@input="digitValidateVerificationCode($event, 0)"
								@keyup="tabChangeVerificationCode($event, 0)"
								maxlength="1"
								/>
								<input
								class="otp"
								v-model="verificationCode[1]"
								@input="digitValidateVerificationCode($event, 1)"
								@keyup="tabChangeVerificationCode($event, 1)"
								maxlength="1"
								/>
								<input
								class="otp"
								v-model="verificationCode[2]"
								@input="digitValidateVerificationCode($event, 2)"
								@keyup="tabChangeVerificationCode($event, 2)"
								maxlength="1"
								/>
								<input
								class="otp"
								v-model="verificationCode[3]"
								@input="digitValidateVerificationCode($event, 3)"
								@keyup="tabChangeVerificationCode($event, 3)"
								maxlength="1"
								/>
								<input
								class="otp"
								v-model="verificationCode[4]"
								@input="digitValidateVerificationCode($event, 4)"
								@keyup="tabChangeVerificationCode($event, 4)"
								maxlength="1"
								/>
								<div class="countdown"></div>
								<div class="error-message" v-if="otpError">
									{{ $t("errors.wrong-otp") }}
								</div>
							</div>
							<BaseButton
								is-transparent
								grey
								full-width
								:is-loading="!ready"
								class="slide__button--grey"
								:text="$t('login-modal.resend-code')"
								@click="goToValidationPage()"
							/>
						</form>
					</div>
				</q-carousel-slide>
			</q-carousel>
		</template>
	</BaseDialog>
</template>

<style lang="scss">
.q-panel {
	overflow: hidden !important;
}

.login-dialog {
	&__header {
		display: flex;
		flex-direction: column;
		justify-content: center;

		&--logo {
			display: block;
			margin: $space-md auto;
			height: 60px;
		}

		&__content {
			margin: auto 0;

			&--title {
				line-height: normal;
				font-size: $medium-title;
				font-weight: 500;
				text-align: center;
				padding: 0 32px;
			}
		}

		&--button {
			margin-top: $space-md;
			color: $color-neutral-grey-400-50 !important;

			.q-icon {
				transition: 10ms !important;
			}

			&:hover {
				background-color: $color-neutral-grey-100 !important;
				color: $color-neutral-black !important;
			}
		}
	}

	&__transition {
		text-align: center;
		font-weight: bold;
		color: $color-neutral-grey-400-50;
	}

	&__code {
		background-color: $color-neutral-grey-200;
		border-radius: 8px;
		padding: 12px;
		margin-top: 12px;

		&--text {
			font-weight: bold;
			margin-bottom: 6px;
			text-align: center;
		}
	}

	&__text {
		text-align: center;
		width: 400px;
		margin: $space-sm auto;
		color: $color-neutral-grey-400-50;
		font-size: $small-text;

		a {
			color: $color-neutral-grey-400-50;
			border-bottom: 1px solid transparent;
		}

		a:hover {
			color: $color-neutral-grey-400-50;
			border-color: $color-neutral-grey-400-50;
			transition: all 300ms ease;
		}
	}

	&__button {
		margin: 6px 0;
	}
}

.slide {
	display: flex;
	flex-direction: column;
	justify-content: center;

	&__button {
		&--grey {
			color: $color-neutral-grey-400-50 !important;

			.q-icon {
				transition: 10ms !important;
			}

			&:hover {
				color: $color-neutral-black !important;
			}
		}
	}

	&__code {
		background-color: $color-neutral-grey-200;
		border-radius: 8px;
		padding: $space-md 12px;
		margin-top: 12px;
		display: flex;
		flex-direction: column;
		align-items: center;

		&--title {
			font-size: $sub-header;
			font-weight: bold;
		}

		&--text {
			text-align: center;
			color: $color-neutral-grey-400;
		}

		&--identification-type {
			height: 80px;
		}

		&--identification {
			width: 100%;
			margin: 12px 0 0;
		}

		&--inputs {
			height: 68px;
			margin: 12px 0 0 0;
		}

		.otp {
			caret-color: transparent;
			width: 36px;
			height: 44px;
			text-align: center;
			font-size: $h2;
			background-color: $color-neutral-code-input;
			transition: 200ms;
			margin: 2px;
			border: none;
			outline: none;
		}

		input:focus {
			outline: 2px solid $color-primary;
		}

		Form {
			width: 100%;

			input::-webkit-outer-spin-button,
			input::-webkit-inner-spin-button {
				-webkit-appearance: none;
				margin: 0;
			}
		}

		.error-message {
			color: red;
			font-size: $small-text;
		}
	}
}

.q-carousel {
	height: unset !important;

	&__slide {
		padding: 0 !important;
	}
}

@media screen and (max-width: $breakpoint-md) {
	.login-dialog {
		&__header {
			&__content {
				margin: auto 0;

				&--title {
					line-height: normal;
					font-size: $header;
					font-weight: 500;
					text-align: center;
				}
			}
		}

		&__text {
            width: 100%;
			&--link {
				font-size: $xsmall-text;
                a {
                    font-weight: bold;
                }
			}
		}
	}
}
</style>
