<script setup lang="ts">
import BaseTag from "@/components/base/BaseTag.vue";

const props = defineProps<{
	timeLeft: number;
}>();

function getColorClass() {
	const { timeLeft } = props;
	if (timeLeft < 3) {
		return "red";
	} else if (timeLeft < 7) {
		return "orange";
	} else return "green";
}
</script>

<template>
	<div class="time-left-tag">
		<BaseTag icon="schedule" :color="getColorClass()">
			<template v-slot:title>{{ props.timeLeft }} {{ $t("day-left", { count: props.timeLeft }) }}</template>
		</BaseTag>
	</div>
</template>
