<script setup lang="ts"></script>

<template>
    <div class="base-context-box">
        <div class="base-context-box__title">
            <slot name="title"/>
        </div>
        <div class="base-context-box__content">
            <slot name="content"/>
        </div>
    </div>
</template>

<style lang="scss">
.base-context-box {
    max-width: 760px;
    background-color: $color-neutral-white;
    border-radius: 8px;
    padding: $space-md;

    &__title {
        font-weight: bold;
    }

    &__content {
        margin-top: $space-sm;
    }
}

@media screen and (max-width: $breakpoint-md) {
    .base-context-box {
        margin: 0 16px;

        &__content {
            font-size: $small-text;
        }
    }
}
</style>
