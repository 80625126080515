<script setup lang="ts">

import { useRoute, useRouter } from 'vue-router';
import { computed, nextTick, onMounted, onUnmounted, ref, watch, inject } from "vue";
import { useI18n } from "vue-i18n";
import { QLayout, QPage, QPageContainer, QPageSticky, QSkeleton } from 'quasar';
import consultationHeader from "@/components/consultationHeader.vue";
import FormCard from '@/components/FormCard.vue';
import BaseCard from '@/components/base/BaseCard.vue';
import SectionContainer from '@/components/SectionContainer.vue';
import ContextMenu from '@/components/ContextMenu.vue';
import consultationMenu from '@/components/mobile/consultationMenu.vue';
import PropositionDialog from '@/components/dialogs/PropositionDialog.vue';
import BaseButton from '@/components/base/BaseButton.vue';
import FormResults from '@/components/FormResults.vue';

import { sectionsService } from "@/services/sections.service";
import { Section } from '@/store/section.interface';
import { formsService } from "@/services/forms.service";
import { contextService } from "@/services/context.service";
import { Consultation } from '@/store/consultation.interface';
import { useCommunityStore } from "@/store/communityStore";
import { getTranslation, removeTimestamp } from "@/utils/data-display-utils";
import { storeToRefs } from "pinia";
import DemographicDialog from "@/components/dialogs/DemographicDialog.vue";
import ConsultationPrivateNoAccessDialog from "@/components/dialogs/ConsultationPrivateNoAccessDialog.vue";
import { authService } from "@/services/auth.service";
import { useUserStore } from "@/store/userStore";
import useResponsive from "@/utils/responsive-util";
import { constant } from "@/constant"
import ConfirmEmailSubscriptionDialog from "@/components/dialogs/ConfirmEmailSubscribtionDialog.vue";
import {mailingListService} from "@/services/mailingList.service";

// REFS
const containerWidth = ref<string>('0px');
const activeSection = ref<Section | null>(null);
const stickyHeight = ref<string>('');
const stickyHeaderRef = ref<HTMLElement>(document.createElement('div'));
const headerRef = ref<HTMLElement>(document.createElement('div'));
const isHeaderVisible = ref<boolean>(true);
const propositionDialogRef = ref(null);
const demographicDialogRef = ref(null);
const consultationPrivateNoAccessDialogRef = ref(null);
const confirmEmailSubscriptionDialogRef = ref(null);
const emailSubscribed = ref(null);
const results = inject('results');

// CONST
const router = useRouter();
const route = useRoute();
const { t } = useI18n();
const consultation_id = parseInt(route.params.id as string);
const userToken = localStorage.getItem('token');
const { isMobile } = useResponsive()

// STORE
const communityStore = useCommunityStore();
const { sectionLoaded, getSections, getSectionContext, formsLoaded, community } = storeToRefs(communityStore);
const userStore = useUserStore();

const userIsAllowed = ref({ allowed: false });
// LIFECYCLE
onMounted(async () => {
    if (getConsultation.value) {
        userIsAllowed.value = communityStore.isUserAllowedAccessConsultation(getConsultation.value, userStore.user)
        if (userIsAllowed.value.allowed) {
            await contextService.getConsultationContext(getConsultation.value.id);
            await sectionsService.getSections(getConsultation.value.id, userToken);
            window.addEventListener('scroll', handleStickyHeight);
            activeSection.value = getSections.value(getConsultation.value.id)[0];
            setNextActiveTheme(activeSection.value.orderNum);
            document.title = getTranslation(community.value, 'title') + ' - ' + getTranslation(getConsultation.value, 'title');
        } else if (userIsAllowed.value.reason === 'NotAllowedByAdmin') {
            openConsultationPrivateNoAccessPopup();
        } else if (userIsAllowed.value.reason === 'login') {
            openConsultationPrivateNoAccessPopup();
        }
    } else {
        openConsultationPrivateNoAccessPopup();
    }
})

watch(sectionLoaded, (newValue, oldValue) => {
    if ('section' in route.query) {
        nextTick(() => {
            setActiveTheme(parseInt(route.query.section));
        })
    }
})
watch(formsLoaded, (newValue, oldValue) => {
    if ('form' in route.query) {
        nextTick(() => {
            scrollToQuestion(parseInt(route.query.form));
        })
    }

})

onUnmounted(() => {
    window.removeEventListener('scroll', handleStickyHeight);
    communityStore.sectionLoaded = false;
    communityStore.formsLoaded = false;
})


// COMPUTED
const getConsultation: Consultation = computed(() => {
    const slug = route.params.slug as string
    return communityStore.community.consultations.find(consultation => consultation.slug == slug)
})

const getBanner = computed(() => {
    if (getConsultation) {
        return getConsultation && getConsultation.value.bannerFile ? getConsultation.value.bannerFile.url : removeTimestamp(communityStore.community.bannerFile.url)
    }
})
const consultationIsCompleted: boolean = computed(() => {
    return results.value || (getConsultation.value.status === constant.Consultation.Status.Completed || (getConsultation.value.status === constant.Consultation.Status.Active && getConsultation.value.endDate && new Date(getConsultation.value.endDate) <= new Date()))
})

// FUNCTIONS
async function setActiveTheme(id: number) {
    const sections = getSections.value(getConsultation.value.id);
    const section = sections.find(s => s.id === id)
    activeSection.value = section;
    await contextService.getSectionContext(getConsultation.value.id, section.id);
    await formsService.getForms(section);
}

async function setNextActiveTheme(next: number) {
    const sections = getSections.value(getConsultation.value.id);
    const section = sections.find(s => s.orderNum === next)
    activeSection.value = section;
    await contextService.getSectionContext(getConsultation.value.id, section.id);
    await formsService.getForms(section);
}


function handleStickyHeight() {
    if (isHeaderVisible.value === true) {
        var header = headerRef.value.getBoundingClientRect();
        if (header.bottom < 56) {
            isHeaderVisible.value = false;
        }
    } else {
        setTimeout(() => {
            var stickyHeader = stickyHeaderRef.value.getBoundingClientRect();
            if (stickyHeader.top > 56) {
                isHeaderVisible.value = true;
            }
        }, 400);
    }
}

function scrollToQuestion(index: number) {
    let nextForm = document.getElementById('fiche_' + index);
    if (nextForm != undefined) {
        nextForm.scrollIntoView({ block: 'center', behavior: 'smooth' });
    } else {
        const orderNum = activeSection.value.orderNum + 1;
        try {
            nextSection(orderNum);
        } catch {
            scrollSections();
        }
    }
}

function changeSection(id: number) {
    setActiveTheme(id);
    scrollSections();
}

function nextSection(next: number) {
    let sections = getSections.value(getConsultation.value.id)
    let orderNum = null;
    let end = 100;
    for (let i = next; i < end; i++) {
        let list = sections.find(s => s.orderNum === i)
        if (list != undefined) {
            orderNum = i;
            i = 101;
            setNextActiveTheme(orderNum);
            scrollSections();
        }
    }
}


function scrollNextQuestion(index: number) {
    let nextIndex = index + 1;
    scrollToQuestion(nextIndex)
}

function scrollSections() {
    if (isMobile) {
        window.scrollTo({ top: 20, behavior: 'smooth' })
    } else {
        window.scrollTo({ top: 80, behavior: 'smooth' })
    }

}

function scrollQuestions() {
    window.scrollTo({ top: 300, behavior: 'smooth' })
}

function scrollConsultation() {
    window.scrollTo({ top: 0, behavior: 'smooth' })
}

function openDialog() {
    if (!isUserLogged.value) {
        // console.log(getConsultation.value.slug, activeSection.value ? activeSection.value.id : null)
        authService.login(getConsultation.value.slug, activeSection.value ? activeSection.value.id : null);
    } else if (!userHasDemographicsData.value) {
        openDemographicPopup();
    } else {
        propositionDialogRef.value.openDialog();
    }
}

const isUserLogged = computed(() => {
    return userStore.isUserLogged
});

const userHasDemographicsData = computed(() => {
    return userStore.validateDemographicData;
});

const isLastSection = computed(() => {
    let sections = getSections.value(getConsultation.value.id)
    let list = sections.find(s => s.id === activeSection.value.id)
    let index = sections.indexOf(list);
    return index + 1 === getSections.value(getConsultation.value.id).length ? false : true;
})

function openDemographicPopup() {
    demographicDialogRef.value.openDialog();
}

function openConsultationPrivateNoAccessPopup() {
    consultationPrivateNoAccessDialogRef.value.openDialog()
}

function subscription(email: string){
  const user = userStore.user;
  if(user?.email !== email){
    mailingListService.createVerificationCode(email);
    emailSubscribed.value = email
    confirmEmailSubscriptionDialogRef.value.openDialog();
  }else{
    mailingListService.subscribe('consultation', getConsultation.value.id, user.id)
  }

}

</script>

<template>
    <div class="consultation">

        <q-layout>
            <q-page-container>
                <q-page v-if="getConsultation && userIsAllowed.allowed">
                    <div class="consultation__banner">
                        <img
                            :src="getBanner"
                            alt="theme-banner"
                        />
                    </div>
                    <div class="consultation__container">
                        <div v-if="!isHeaderVisible" class="consultation__header-mobile" ref="stickyHeaderRef"
                             @click="handleStickyHeight()">

                            <consultation-menu
                                @scroll-questions="scrollQuestions"
                                @scroll-sections="scrollSections"
                                @scroll-consultation="scrollConsultation"
                                :title="getTranslation(getConsultation, 'title')"
                            />
                        </div>
                        <div v-else class="consultation__header" ref="headerRef" @click="handleStickyHeight()">
                            <BaseCard>
                                <template v-slot:content>
                                    <consultation-header
                                        :tagText="'Consultation'"
                                        :tagColor="'grey'"
                                        :icon="'edit'"
                                        :start-date="getConsultation.beginDate"
                                        :end-date="getConsultation.endDate"
                                        :isMultiTheme="getSections(getConsultation.id).length >= 1"
                                        :is-closed="getConsultation.isConcluded"
                                        :description="getTranslation(getConsultation, 'title')"
                                        :key-information="getConsultation.summary"
                                        id="consultation-container"
                                    />
                                    <ContextMenu
                                        :sections="getSections(getConsultation.id)"
                                        :context="getTranslation(getConsultation, 'description')"
                                        :context-attachment="getConsultation.context"
                                        @changeSection="setActiveTheme($event)"
                                        :active-section="activeSection"
                                        group="consultation"
                                        :video="getConsultation.welcomeVideo ? getTranslation(getConsultation, 'welcomeVideo') : ''"
                                        :mailing-list="getConsultation.options.mailingList"
                                        @subscription="subscription($event)"
                                    />
                                </template>
                            </BaseCard>
                        </div>
                        <div class="section__container">
                            <div v-if="sectionLoaded && activeSection && getSections(getConsultation.id).length > 1"
                                 class="sections-heading">
                                <h2>{{ $t('consultation.sections', getSections(getConsultation.id).length) }}</h2>
                            </div>

                            <div v-if="sectionLoaded && activeSection && getSections(getConsultation.id).length > 1"
                                 class="section__header"
                                 id="section-container"
                            >
                                <SectionContainer
                                    :sections="getSections(getConsultation.id)"
                                    @changeSection="setNextActiveTheme($event)"
                                    :active-section="activeSection"
                                    :consultation-banner="getBanner"
                                    :context="getTranslation(activeSection, 'description')"
                                    :context-attachment="activeSection.context"
                                    :context-opened="activeSection.contextOpened"
                                />
                            </div>
                            <div
                                v-else-if="!sectionLoaded && activeSection && getSections(getConsultation.id).length > 1"
                                class="section__container-section-skeleton">
                                <q-skeleton animation="none" width="100%" height="280px"/>
                            </div>
                            <div v-if="formsLoaded && activeSection?.form.length >= 1"
                                 class="question-container">
                                <div class="questions-heading">
                                    <h2 class="questions" v-if="!consultationIsCompleted">{{
                                            $t('consultation.questions')
                                        }}</h2>
                                    <h2 class="questions" v-else>{{ $t('consultation.results') }}</h2>
                                </div>
                                <div v-for="(fiche, index) in activeSection.form">
                                    <div class="consultation__proposals--card">
                                        <FormCard
                                            :key="fiche.id"
                                            :consultationId="getConsultation.slug"
                                            :fiche="fiche"
                                            :index="index"
                                            :active-section-id="activeSection.orderNum"
                                            :section-count="getSections(getConsultation.id).length "
                                            :form-count="activeSection.form.length"
                                            @click="scrollNextQuestion(index)"
                                            @openDemographicDialog="openDemographicPopup"
                                            :consultation-is-completed="consultationIsCompleted"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div v-else class="section__container-form-skeleton">
                                <q-skeleton animation="fade" width="100%" height="500px"/>
                            </div>

                            <div class="actions"
                                 v-if="formsLoaded && isLastSection">
                                <BaseButton
                                    class="action"
                                    icon="keyboard_arrow_right"
                                    :label="$t('buttons.next-section')"
                                    @click="nextSection(activeSection.orderNum + 1)"/>
                            </div>
                        </div>
                    </div>
                    <q-page-sticky position="bottom-right" :offset="[18, 18]"
                                   v-if="activeSection && activeSection.citizenProposalEnabled && !consultationIsCompleted">
                        <q-btn fab color="secondary" @click="openDialog">
                            <q-icon name="add" size="26px"/>
                            <span class="q-mx-sm">{{ $t('dialog.proposition.ajouter') }}</span>
                        </q-btn>
                    </q-page-sticky>

                </q-page>
            </q-page-container>
        </q-layout>
        <DemographicDialog ref="demographicDialogRef"/>
        <PropositionDialog :section-id="activeSection && activeSection.id" ref="propositionDialogRef"/>
        <ConsultationPrivateNoAccessDialog ref="consultationPrivateNoAccessDialogRef"
                                           :home-link="userStore.isUserLogged"
                                           :need-login="!userStore.isUserLogged"/>
      <ConfirmEmailSubscriptionDialog ref="confirmEmailSubscriptionDialogRef"
                                           :email="emailSubscribed"
                                        :consultation_id="getConsultation.id"
      />

    </div>
</template>

<style lang="scss">

.container {
    display: flex;
    overflow-x: auto;
}

.container img {
    margin-right: 15px;
    width: 225px;
}

.container::-webkit-scrollbar {
    display: none;
}

.consultation__container {
    display: flex;
}

.section__container {
    display: flex;
    width: 60%;
    flex-direction: column;
    justify-content: flex-start;

    .section__submenu {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 0px 10% 0px 10%;
    }

    .form-container {
        display: flex;
        flex-direction: column;
        gap: $space-md;
    }

    &-section-skeleton {
        margin: 0 10% $space-md 10%;

        .q-skeleton {
            border-radius: 8px;
        }
    }

    &-form-skeleton {
        margin: 0 10% $space-md 10%;

        .q-skeleton {
            border-radius: 8px;
        }
    }

    .actions {
        margin: $space-md 10%;
        display: flex;
        justify-content: center;

        .q-btn {
            font-size: 16px;
            font-weight: 500;
        }
    }

    .question-container {
        display: flex;
        flex-direction: column;
        gap: $space-md;
    }

    .admin__results {
        h2 {
            margin: $space-md 10% !important;
        }
    }
}

.isSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.consultation {
    &__banner {
        max-height: 360px;

        img {
            width: 100%;
            height: 100%;
            max-height: 360px;
        }
    }

    &__header {
        width: 40%;
        position: sticky !important;
        z-index: 1;
        height: 100%;
        top: 90px;
    }

    .consultation-card {

        cursor: default;

        &:hover {
            box-shadow: none;
        }

        &__title {
            display: flex;
        }

        &__completion {
            font-weight: bold;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            font-size: 10px;
            margin-bottom: $space-sm;

            .q-linear-progress {
                color: $color-primary;
            }
        }

        &__key-info {
            color: $color-neutral-grey-400-50;
        }
    }

    &__container {
        max-width: 1100px;
        margin: $space-xl auto;
        display: flex;
        flex-direction: row;
    }

    &__themes {

        &--title {
            display: flex;
            align-items: center;
            font-size: 16px;
            font-weight: bold;
            padding: 16px;

            .q-icon {
                margin-right: $space-sm;
                font-size: 28px;
            }
        }

        &--cards {
            display: flex;
            justify-content: space-around;
            margin-bottom: $space-xl;
        }

        &--card {
            border-radius: 8px;
            padding: $space-sm;
            background-color: $color-neutral-white;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            &.active {
                outline: 2px solid $color-primary;
            }

            &:hover {
                outline: 2px solid $color-primary;
                transition: all 200ms ease;
            }
        }
    }

    &__proposals {

        &--card {
            margin: 0 10%;
            border-radius: $space-sm;
            background-color: $color-neutral-white;
            display: flex;
            align-items: center;
            justify-content: center;

            &.completed {
                background-color: $color-red-12;
            }
        }
    }

    .sections-heading {
        margin: 0 10% $space-md 10%;
    }

    .questions-heading {
        margin: $space-md 10%;

        .questions {
            margin-top: $space-md !important;
        }
    }
}

@media screen and (max-width: $breakpoint-xl) {
    .consultation {
        &__container {
            margin-top: $space-md;
        }

        &__themes {
            &--title {
                padding: $space-md;
            }
        }
    }
}

@media screen and (max-width: $breakpoint-lg) {
    .consultation {
        &__banner {
            height: 72px;
        }

        &__themes {
            &--cards {
                width: v-bind(containerWidth);
                overflow-x: auto;
                justify-content: initial;
                gap: $space-sm;
            }

            &--cards::-webkit-scrollbar {
                display: none;
            }
        }
    }
}

@media screen and (max-width: $breakpoint-md) {
    .consultation {
        &__themes {
            &--title {
                position: sticky;
                top: v-bind(stickyHeight);
                z-index: 1;
            }

            &--title.is-sticky {
                border-bottom: 1px solid $color-neutral-grey-300;
                background: $color-neutral-white;
            }
        }
    }
}

@media screen and (max-width: $breakpoint-sm) {
    .consultation {
        &__banner {
            height: 72px;
        }

        &__container {
            flex-direction: column;
        }

        &__header {
            margin: 0 $space-md $space-sm $space-md;
            width: auto;
            top: 0px;
            position: relative !important;

            &-mobile {
                width: 100%;
                position: sticky !important;
                z-index: 1;
                top: 55px;
            }
        }


        &__proposals--card {
            margin: $space-md 0;
        }
    }

    .section__container {
        width: auto;
        margin: 0 $space-md $space-sm $space-md;

        .section__submenu {
            margin: $space-sm $space-md;
        }

        .form-container {
            display: flex;
            flex-direction: column;
            gap: 0px;
        }

        .section__header {
            margin: $space-sm 0;
            width: 100%;
        }

        .sections-heading {
            margin-top: $space-md;
        }

        .question-container {
            display: flex;
            flex-direction: column;
            gap: 0;
        }
    }

}
</style>