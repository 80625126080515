<script setup lang="ts">

import { getTranslation } from "@/utils/data-display-utils";
import { computed, onMounted, ref, watch } from "vue";
import { QSlider } from "quasar";

// PROPS
const props = defineProps<{
    slider: any,
    results?: boolean,
    average?: number,
}>();

// REFS
const slider_value = ref<number>(5);
onMounted(() => {
    changeValue({ valueId: slider_value.value, answerId: props.slider.answerId })
});

// EMITS
const emit = defineEmits<{
    (e: "change", response: number): void;
}>();

// FUNCTIONS
function changeValue(value: any) {
    emit("change", value);
}

// WATCH
watch(slider_value, (newValue) => {
        changeValue({ valueId: newValue, answerId: props.slider.answerId })
    }
);
</script>

<template>

    <div v-if="!results" class="slider">
        <span class="label q-mt-md">{{ getTranslation(slider, 'name') }}</span>
        <q-slider
            v-model="slider_value"
            :min="slider.range[0]"
            :max="slider.range[1]"
            markers
            marker-labels

            thumb-color="secondary"
            thumb-size="20px"
            inner-track-color="grey"
            track-color="grey"
            selection-color="grey"
        />

    </div>
    <div v-else class="results q-mb-md">
        <div class="slider-container__results-data">
            <span class="label">{{ getTranslation(slider, 'name') }}</span>
            <span>{{ Math.round(average * 10) / 10 + `/${slider.range[1]}` }}</span>
        </div>
        <q-linear-progress
            :value="average / props.slider.range[1]"
            color="secondary"
            rounded
        />
    </div>

</template>

<style lang="scss">

</style>