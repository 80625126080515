<script setup lang="ts">

import { ref, onMounted, onBeforeUnmount, computed, watch } from 'vue';
import InfoWindow from '../map/InfoWindow.vue';
import BaseButton from '../base/BaseButton.vue';
import Legend from '../map/Legend.vue';
import BaseSelect from "@/components/base/BaseSelect.vue";
import BottomWindows from "@/components/map/BottomWindows.vue";
import { useI18n } from "vue-i18n";

import mapboxgl from 'mapbox-gl';
import { MapboxSearchBox } from '@mapbox/search-js-web'
import * as turf from "@turf/turf";
import { getTranslation } from "@/utils/data-display-utils";
import { createPointGeoJson } from "@/utils/map-utils";
import useResponsive from "@/utils/responsive-util";
import { useCurrentLocale } from "@/plugins/i18n";
import { useUserStore } from "@/store/userStore";
import { formsService } from "@/services/forms.service";
import { Config } from '@/config';

// PROPS
const props = defineProps<{
    showInfo?: boolean;
    id: string;
    mapConfig: any;
    mapMarkers: any[];
    isFullscreen?: boolean;
    consultationIsCompleted: boolean
}>();

// EMITS
const emit = defineEmits<{
    (e: "showInfo"): void;
    (e: "marker", marker: any): void;
    (e: "verifyUser"): void;
}>();

// CONST
const accessToken = Config.mapbox.accessToken;
const userEditing = ref<boolean>(false);
const addingData = ref<boolean>(false);
const legendVisible = ref<boolean>(false);
const userComment = ref<string>();
const markerCategory = ref(null);
const markerInputCategory = ref(null);
const userMarkerCoordinates = ref(null);
const layerIds = [];
const { t } = useI18n();
const { isMobile } = useResponsive();
const locale = useCurrentLocale();
const userStore = useUserStore();
const bounds = [
    props.mapConfig.bounds.southWest, // SouthWest coordinate [Longitude, Latitude]
    props.mapConfig.bounds.northEast  // NorthEast coordinate [Longitude, Latitude]
];
// Flatten your bounds into a bbox array [minLng, minLat, maxLng, maxLat]
const bbox = [
    bounds[0][0], // minLng (SouthWest Longitude)
    bounds[0][1], // minLat (SouthWest Latitude)
    bounds[1][0], // maxLng (NorthEast Longitude)
    bounds[1][1]  // maxLat (NorthEast Latitude)
];
const center = turf.getCoord(turf.center(turf.bboxPolygon(bbox)));
const initialZoom = props.mapConfig.zoom

let map;
let searchBox;
let markerLocation;
let userMarker;
let tempMarker;
const markerSelected = ref(null);

function addMarkerToMap(coord: any[]) {
    userEditing.value = false;
    addingData.value = true;
    const el = document.createElement('div');
        el.className = 'tempMarker';
        el.style.backgroundImage = 'url(https://s3.ca-central-1.amazonaws.com/cocoriko.dev.communities/mapMarkers/png/mapbox_marker_gray.png)';
        el.style.width = '30px';
        el.style.height = '40px';
        el.style.backgroundSize = '100%';

    //todo: get category and add to geojson of this category
    tempMarker = new mapboxgl.Marker(el)
        .setLngLat(coord)
        .addTo(map);
   
    
    markerLocation = coord;
}

const markerGeoJSON = ref(createPointGeoJson(props.mapConfig.categories, props.mapMarkers));

// LIFECYCLE
onMounted(() => {
    initMap();

    document.addEventListener('keydown', handleOutsideClick)
    document.addEventListener('click', handleOutsideClick)

    map.on('click', (e) => {
        if (legendVisible.value || props.showInfo) {
            resetMapWindows();
        }
    })

    map.on('load', (e) => {
        map.resize();
        setMapLang(locale.value);
        props.mapConfig.categories.forEach(category => {
            const imageURL = `https://s3.ca-central-1.amazonaws.com/cocoriko.dev.communities/mapMarkers/png/mapbox_marker_${category.color}.png?t=${new Date().getTime()}`;
            map.loadImage(
                imageURL,
                (error, image) => {
                    if (error) throw error;
                    map.addImage(`marker_${category.color}`, image);
                }
            )
        })

        let clusterEnabled = false;
        if(props.mapConfig.clusterEnabled){
            clusterEnabled = true
        }
        markerGeoJSON.value.forEach(marker => {
            const sourceId = `points_${marker.category.id}`;
            const clusterLayerId = `clusters_${marker.category.id}`;
            const clusterCountLayerId = `cluster-count_${marker.category.id}`;
            const unclusteredPointLayerId = `unclustered-point_${marker.category.id}`;
            const category_marker = `marker_${marker.category.color}`
            layerIds.push(unclusteredPointLayerId);
            map.addSource(sourceId, {
                'type': 'geojson',
                'data': marker.geoJson,
                cluster: clusterEnabled,
                clusterMaxZoom:  14, // Max zoom to cluster points on
                clusterRadius:  20 // Radius of each cluster when clustering points (defaults to 50)
            });
            if(clusterEnabled){
                map.addLayer({
                id: clusterLayerId,
                type: 'circle',
                source: sourceId,
                filter: ['has', 'point_count'],
                paint: {
                    // Use step expressions (https://docs.mapbox.com/style-spec/reference/expressions/#step)
                    // with three steps to implement three types of circles:
                    //   * Blue, 20px circles when point count is less than 100
                    //   * Yellow, 30px circles when point count is between 100 and 750
                    //   * Pink, 40px circles when point count is greater than or equal to 750
                    'circle-color': [
                        'step',
                        ['get', 'point_count'],
                        '#51bbd6',
                        100,
                        '#f1f075',
                        750,
                        '#f28cb1'
                    ],
                    'circle-radius': [
                        'step',
                        ['get', 'point_count'],
                        20,
                        100,
                        30,
                        750,
                        40
                    ]
                },
                layout:{
                    'visibility': 'visible'
                }
            });
                map.addLayer({
                id: clusterCountLayerId,
                type: 'symbol',
                source: sourceId,
                filter: ['has', 'point_count'],
                layout: {
                    'text-field': ['get', 'point_count_abbreviated'],
                    'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
                    'text-size': 12,
                    'visibility': 'visible'
                }
            });
            }

            map.addLayer({
                'id': unclusteredPointLayerId,
                'type': 'symbol',
                'source': sourceId,
                filter: ['!', ['has', 'point_count']],
                layout: {
                    'visibility': 'visible',
                    'icon-image': category_marker,
                    'icon-size': 1,
                    'icon-allow-overlap': true
                }
            });
            map.on('click', unclusteredPointLayerId, (e) => {
                // Display popup or handle click event
                resetMapWindows()
                resetMapMarkers()
                markerSelected.value = e.features;
            });

        })
    });
    
    map.on('mouseenter', layerIds, (e)=> {
        map.getCanvas().style.cursor = 'pointer';
    })

    map.on('mouseleave', layerIds, () => {
        map.getCanvas().style.cursor = '';
    });

    userMarker = new mapboxgl.Marker({
        color:'#808080',
        draggable: true
    })
    
    userMarker.on('dragend', onDragEnd);

    map.addControl(new mapboxgl.NavigationControl());
    map.addControl(new mapboxgl.AttributionControl(), 'bottom-left');

    
    if( isMobile.value ){
        map.scrollZoom.disable()
    }
})

onBeforeUnmount(() => {
    if (map) {
        map.remove();
    }
    if (searchBox) {
        searchBox.remove();
    }
});

// FUNCTIONS 
function initMap() {
    map = new mapboxgl.Map({
        accessToken: accessToken,
        container: props.id,
        style: props.mapConfig.mapStyle,
        center: center, // can also pull location out of browser
        zoom: initialZoom,
        attributionControl: false,
        bounds: bounds,
        maxBounds: bounds
    });

    map.setMinZoom(initialZoom);

    initSearch();
}

function initSearch(lang = '') {
    if(lang === ''){
        lang = locale.value
    }
    if (searchBox) {
        searchBox.remove();
        searchBox = null;
    }
    searchBox = new MapboxSearchBox()
    searchBox.accessToken = accessToken;
    searchBox.options = {
        language: lang,
        bbox: bounds
    }
    searchBox.mapboxgl = mapboxgl
    searchBox.marker = true
    searchBox.bindMap(map.container)

    searchBox.addEventListener('retrieve', (event) => {
        userMarker.remove();
        const addressPoint = turf.getCoord(turf.center(event.detail))
        if (userEditing.value) {
            addMarkerToMap(addressPoint)
            map.flyTo({ center: addressPoint, zoom: 14 })
        } else {
            map.flyTo({ center: addressPoint, zoom: 14 })
        }

    });
    document.getElementById(`search-box-container ${props.id}`).appendChild(searchBox)
}

const userHasDemographicsData = computed(() => {
    return userStore.validateDemographicData;
});

function addMarkerBtnClick() {
    if (userStore.isUserLogged && userHasDemographicsData.value) {
        userEditing.value = !userEditing.value
        if(!userEditing.value){
            userMarker.remove()
        } else {
            userMarkerCoordinates.value = [map.getCenter().lng, map.getCenter().lat];
            userMarker.setLngLat(map.getCenter()).addTo(map);
            resetMapWindows();
            userEditing.value = true;
            if (props.showInfo) {
                emit('showInfo');
            }
        }

        if(userMarkerCoordinates.value && addingData.value){
            resetMapMarkers()
            addingData.value = !addingData.value;
            userEditing.value = false;
        }
    } else {
        emit('verifyUser')
    }
}

function onDragEnd() {
    let coords = userMarker.getLngLat();
    userMarkerCoordinates.value = [coords.lng, coords.lat];
}

function toggleLegend() {
    legendVisible.value = !legendVisible.value;
}

function panHome () {
    map.flyTo({ center: center, zoom: initialZoom })
}

function handleOutsideClick(event) {
    const mapDiv = document.getElementById(props.id); 

    if (event.key === "Escape") {
        resetMapMarkers()
        resetMapWindows()
    }

    if (event.type === "click" && !mapDiv.contains(event.target)) {
        if (legendVisible.value){
            legendVisible.value = false;
        }
    }
}

function resetMapWindows() {
    legendVisible.value = false;
    addingData.value = false;
    userEditing.value = false;
    markerSelected.value = false;
    emit('showInfo');
}

function resetMapMarkers () {
    if (userMarker) {
        userMarker.remove();
    } 
    if (tempMarker) {
        tempMarker.remove();
    }
}

function resetMarkerData() {
    userComment.value = '';
    markerCategory.value = null;
}

function changeMarkerCategory(e) {
    const category = props.mapConfig.categories.find(c => c.id === e.value)
    markerCategory.value = category.id;
    // markerGeoJSON.value.features[markerGeoJSON.value.features.length - 1].properties["marker-color"] = category.color;
    // map.getSource('points').setData(markerGeoJSON.value);
}

function setUserComment(comment) {
    userComment.value = comment
    submitData()
}

function submitData () {
    if (props.mapConfig.categories.length === 1) {
        markerCategory.value = props.mapConfig.categories[0].id
    }

    const marker = {
        marker_location: markerLocation,
        marker_category: markerCategory.value,
        marker_text: userComment.value,
        marker_approved: {}
    }
    const category = props.mapConfig.categories.find(category => category.id === marker.marker_category)
    const categoryObject = markerGeoJSON.value.findIndex(item => item.category.id === marker.marker_category);
    markerGeoJSON.value[categoryObject].geoJson.features.push({
            type: 'Feature',
            geometry: {
                type: 'Point',
                coordinates: marker.marker_location
            },
            properties: {
                title: marker.marker_text,
                'markerCategoryId': category.id,
                'markerColor': category.color,
                'markerSize': 'medium',
                'markerLike': [],
                'markerDislike': [],
                'markerUser': userStore.getUserName
            }
        }
    )
    map.getSource(`points_${category.id}`).setData(markerGeoJSON.value[categoryObject].geoJson);
    const tempMarker = document.getElementsByClassName('tempMarker')
    Array.from(tempMarker).forEach((element) => {
        element.remove();
    });
    //need to pass valid JSON object into 'setData'
    //set data only exists for geojson sources, create source with type geojson

    emit('marker', marker);
    addingData.value = false;
    resetMarkerData();
}

function setUserMarker() {
    if (userMarkerCoordinates.value === null) {
        userMarkerCoordinates.value = [map.getCenter().lng, map.getCenter().lat]
    }
    addingData.value = true;
    userEditing.value = false;
    userMarker.remove();
    addMarkerToMap(userMarkerCoordinates.value)
}

const toggleLayerVisibility = (legendLayer) => {
    if (map) {
        const layer = `unclustered-point_${legendLayer.id}`;
        const visibility = map.getLayoutProperty(layer, 'visibility')
        
        if (visibility === 'visible') {
            map.setLayoutProperty(layer, 'visibility', 'none');
        } else {
            map.setLayoutProperty(layer, 'visibility', 'visible');
        }
    }
};

function resizeMap() {
    map.resize()
}

function setMapLang(lang) {
    map.getStyle().layers.forEach((layer) => {
        if (layer.id.endsWith('-label')) {
            map.setLayoutProperty(layer.id, 'text-field', [
                'coalesce',
                ['get', `name_${lang}`],
                ['get', 'name'],
            ]);
        }
    });
}

function closeMarkerPopup () {
    markerSelected.value = null;
}

function closeInfoPopup () {
    emit('showInfo');
}

function closeAddingPopup () {
    addingData.value = false;
    resetMapMarkers()
}

function closeEditingPopup () {
    userEditing.value = false;
    resetMapMarkers()
}

// WATCH
watch(() => props.isFullscreen, () => {
        setTimeout(() => {
            resizeMap()
        }, 200);
    }
);

watch(locale, (newValue) => {
    setMapLang(newValue)
    initSearch(newValue);
});

watch(() => props.showInfo, () => {
        if(props.showInfo) {
            markerSelected.value = null;
            addingData.value = false;
            legendVisible.value = false;
            userEditing.value = false;
            resetMapMarkers()
        }
    }
);

const likeAction = ()=>{
    const user_id = userStore.getUserId
    const markerProperties = markerSelected.value[0].properties;
    const like = JSON.parse(markerProperties.markerLike)
    const hasLike = like.find(l => l.user_id === user_id)

    if (!props.consultationIsCompleted) {
        if (userStore.isUserLogged) {
            const commentId = markerProperties.markerCommentId;
            const formId = markerProperties.markerFormId;
            if (!hasLike) {
                like.push({action: 1, user_id: user_id})
                formsService.postCommentAction(formId, commentId, 'like');
            } else {
                like.splice(like.findIndex(l => l.user_id === user_id), 1)
                formsService.postCommentAction(formId, commentId, 'unlike');
            }
            markerProperties.markerLike = JSON.stringify(like)
            updateGeoJson(markerSelected.value[0].properties);
        } else {
            emit('verifyUser')
        }
    }
}

function updateGeoJson(marker){
    const geoJsonData = map.getSource(`points_${marker.markerCategoryId}`)._data;
    const markerToUpdate = geoJsonData.features.find(m => m.properties.markerCommentId === marker.markerCommentId)
    markerToUpdate.properties.markerLike = marker.markerLike
    map.getSource(`points_${marker.markerCategoryId}`).setData(geoJsonData)
}

</script>
<template>
    <div :id="props.id" class="map base-map" :class="{'fullscreenMap' : props.isFullscreen}">
        <div class="top-controls">
            <BaseButton class="top" isIconOnly icon="layers" @click="toggleLegend"/>
        </div>
        <div class="home-control">
            <BaseButton class="top" isIconOnly icon="o_home" @click="panHome"/>
        </div>
        <InfoWindow v-show="legendVisible" anchor-legend >
            <template v-slot:title>{{ $t('map.legend') }}</template>
            <template v-slot:content>
                <div id="menu">
                    <Legend :layers="mapConfig.categories" @update-visibility="toggleLayerVisibility($event)"/>
                </div>
            </template>
        </InfoWindow>
        <InfoWindow v-if="props.isFullscreen && props.showInfo" anchor-top @close="closeInfoPopup">
            <template v-slot:title>{{ $t('map.instructions.title') }}</template>
            <template v-slot:content>
                <div>{{ $t('map.instructions.content') }}</div>
            </template>
            <template v-slot:close>
                <q-icon name="close" @click="closeInfoPopup"/>
            </template>
        </InfoWindow>
        <InfoWindow v-if="props.isFullscreen && userEditing && !props.showInfo" anchor-bottom @close="closeEditingPopup">
            <template v-slot:title>{{ $t('map.add-marker.title') }}</template>
            <template v-slot:content>
                <div>{{ $t('map.add-marker.content') }}</div>
                <BaseButton id="set-location" class="action set-location" icon="location_searching" :label="$t('map.add-marker.button')" @click="setUserMarker"/>
            </template>
            <template v-slot:close>
                <q-icon name="close" @click="closeEditingPopup"/>
            </template>
        </InfoWindow>
        <InfoWindow v-if="props.isFullscreen && addingData && !props.showInfo" anchor-bottom @close="closeAddingPopup">
            <template v-slot:title>{{ $t('map.selected-point.title') }}</template>
            <template v-slot:content>
                <div>{{ $t('map.selected-point.content') }}</div>
                <div v-if="mapConfig.categories.length > 1">
                    <div>{{ $t('map.selected-point.category') }}</div>
                    <base-select
                        :ref="markerInputCategory"
                        :options="mapConfig.categories.map(category => ({label: getTranslation(category, 'name'),value: category.id}))"
                        @update="changeMarkerCategory($event)"
                    />
                </div>
                <div>
                    <div>{{ $t('map.selected-point.comment') }}</div>
                    <q-input v-model="userComment" outlined/>
                </div>
                <BaseButton class="action" :label="$t('buttons.submit')" @click="submitData"/>
            </template>
            <template v-slot:close>
                <q-icon name="close" @click="closeAddingPopup"/>
            </template>
        </InfoWindow>
        <div class="bottom-controls">
            <q-btn class="bottom-controls__button" no-caps flat :label="$t('map.add-marker.title')"
                   icon="o_add_location" @click="addMarkerBtnClick"/>
        </div>
    </div>
    <BottomWindows 
        :is-fullscreen="props.isFullscreen" 
        :info-visible="props.showInfo" 
        :user-editing="userEditing" 
        :adding-data="addingData" 
        :marker-selected="markerSelected" 
        :map-config="props.mapConfig"
        @set-marker="setUserMarker"
        @set-user-comment="setUserComment($event)"
        @change-category="changeMarkerCategory($event)"
        @close-marker="closeMarkerPopup"
        @close-info="closeInfoPopup"
        @close-adding="closeAddingPopup"
        @close-editing="closeEditingPopup"
        @like-action="likeAction"
    />
</template>
<style lang="scss">
.marker {
    display: block;
    border: none;
    cursor: pointer;
    padding: 0;
}
.map {
    width: 100%;
    position: relative;
    overflow: hidden;

    &.base-map {
        height: 400px;
    }

    &.fullscreenMap {
        height: 92vh !important;
    }

    .mapboxgl-ctrl-top-right, .mapboxgl-ctrl-bottom-left {
        z-index: 1;
    }

    .home-control {
        display: flex;
        flex-direction: row;
        position: absolute;
        justify-content: center;
        align-items: center;
        top: 96px;
        right: 10px;
        width: 29px;
        z-index: 1;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

        .q-btn {
            width: 100%;
            padding: 0;
            background-color: $color-neutral-white;
            border-radius: 5px !important;

            .q-icon {
                font-size: 20px !important;
            }
        }
    }

    .top-controls {
        display: flex;
        flex-direction: row;
        position: absolute;
        top: 10px;
        left: 10px;
        width: fit-content;
        background-color: $color-neutral-white;
        z-index: 1;
        border-radius: 6px;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

        button {
            width: 40px;
            color: $color-primary-darken;

            &:hover {
                background-color: transparent;
            }
        }

        .q-separator {
            z-index: 2;
        }
    }

    .bottom-controls {
        display: flex;
        flex-direction: row;
        position: absolute;
        bottom: 10px;
        right: 10px;
        width: fit-content;
        z-index: 1;
        border-radius: 6px;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

        &__button {
            padding: 0px 8px;
            background-color: $color-neutral-white;
            color: $color-primary-darken;
            border-radius: 8px;

            .q-icon {
                margin-right: 8px;
            }
        }
    }

    .set-location {
        background-color: $color-primary;
        color: $color-neutral-white;
        border-radius: 8px;
        padding: 0px 8px;

        .q-icon{
            margin: $space-sm 0;
        }
    }

    .mapboxgl-ctrl-group:not(:empty) {
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

        button {
            color: $color-primary-darken;
        }
    }

    .type_select {
        border: 1px solid black;
    }
}
.infosMarker_wrapper{ 
    display: flex;
    flex-direction: row;
    width: 100%;

    .infosMarker {
        width: 100%;

        &_content {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            &-actions {
                display: flex;
                flex-direction: column;

                .q-btn {
                    padding: 0;
                }

                .q-icon {
                    margin: 0 0 0 $space-sm;
                }
            }
        }

        &_infos {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            p {
                margin: 0;
            }
        }
    }

    .close {
        display: flex;
        justify-content: center;
        width: auto;
        margin: 0 0 0 $space-sm;

        .q-btn {
            height: 30px;
            width: 36px;
            padding: 0;
        }
    }
}
</style>