<script setup lang="ts">

import BaseCollapsableCard from '@/components/base/BaseCollapsableCard.vue';
import { getTranslation, isOverflown } from "@/utils/data-display-utils";

import { ref } from "vue";

// PROPS
const props = defineProps<{
    title: string;
}>();

// EMITS
const emit = defineEmits<{
    (e: "scrollSections"): void;
    (e: "scrollQuestions"): void;
    (e: "scrollConsultation"): void;
}>();

// FUNCTIONS
function scrollSections () {
    emit('scrollSections');
}

function scrollQuestions () {
    emit('scrollQuestions');
}

function scrollConsultation () {
    emit('scrollConsultation');
}

</script>

<template>
    <div class="consultation-menu">
        <div class="consultation-menu__title">
            <BaseCollapsableCard primary>
                <template v-slot:title >
                    <div class="title">
                        {{ props.title}}
                    </div>
                </template>
                <template v-slot:content>
                    <div class="consultation-menu__content">
                        <div class="consultation-menu__sections" @click="scrollSections">
                            <q-icon name="o_folder"/>
                            {{ $t('consultation.sections', 2) }}
                        </div>
                        <div class="consultation-menu__questions" @click="scrollQuestions">
                            <q-icon name="format_list_bulleted"/>
                            {{ $t('consultation.surveys', 2) }}
                        </div>
                    </div>
                </template>
            </BaseCollapsableCard>
        </div>
    </div>
</template>

<style lang="scss">
.consultation-menu{
    width: inherit;
    background-color: rgba( $color-neutral-grey-300, 0.9);
    color: rgba($color-neutral-black, 1);
    padding: $space-sm 0;
    font-weight: bold;

    &__content {
        font-size: $body-text;
    }

    &__sections {
        display: flex;
        align-items: center;
        padding: $space-sm 0;
    }

    &__questions {
        display: flex;
        align-items: center;
        padding: $space-sm 0;
    }

    .q-icon {
        font-size: $medium-title;
        margin: 0 $space-sm;
    }

    .base-collapsable__title--text{
        max-width: 85vw;
    }

    .title {
        display: block;
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
</style>