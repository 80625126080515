<script setup lang="ts">

import { ref } from "vue";
import BaseDialog from "@/components/base/BaseDialog.vue";
import BaseButton from '@/components/base/BaseButton.vue';
import { authService } from '@/services/auth.service'
import { useForm } from 'vee-validate';
import { useI18n } from 'vue-i18n';
import BaseCheckbox from "@/components/base/BaseCheckbox.vue";

const props = defineProps<{
    communityTitle: string;
    organizationName: string;
}>();

// REFS
const { t } = useI18n();
const consentEmailDialog = ref<InstanceType<typeof BaseDialog>>();


const { handleSubmit, meta } = useForm();
const consentCheckbox = ref();
const confirmationCheckbox = ref(false);


function update(checkboxValue: boolean) {
    consentCheckbox.value = checkboxValue;
}

function updateConfirmation(checkboxValue: boolean) {
    confirmationCheckbox.value = checkboxValue;
}

// FUNCTIONS
function openDialog() {
    consentEmailDialog.value?.openDialog();
}

function closeDialog() {
    consentEmailDialog.value?.closeDialog();
}

const submit = handleSubmit(() => {
    console.log(consentCheckbox.value)
    authService.setTermsAndEmailConsent(confirmationCheckbox ? 1 : 0, consentCheckbox.value ? 1 : 0)
    closeDialog();
});


defineExpose({
    openDialog,
    closeDialog,
});

</script>

<template>
    <BaseDialog ref="consentEmailDialog" :have-close-button="false" :persistent="true">
        <template v-slot:title>
            <div class="demo-title">
                {{ t('dialog.consent.consent') }}
            </div>
        </template>
        <template v-slot:content>
            <div class="consent-email-dialog-content">
                <p class="consent-email-dialog-content__title">{{ t('dialog.consent.title', { organizationName }) }}</p>
                <div class="consent-email-dialog-content__terms">
                    <base-checkbox :model="confirmationCheckbox" :label="t('dialog.consent.confirmationCheckboxLabel')" @update="updateConfirmation"/>
                </div>

                <div class="consent-email-dialog-content__email">
                    <base-checkbox :model="consentCheckbox" :label="t('dialog.consent.checkBoxLabel', { organizationName })"
                                :default-value="true" @update="update"/>
                </div>
            </div>

            <div class="row no-wrap justify-end q-gutter-md">
                <BaseButton color="primary" @click="submit" :disabled="!confirmationCheckbox"> {{ t('buttons.submit') }}</BaseButton>

            </div>
        </template>
    </BaseDialog>
</template>

<style lang="scss">
.demo {


    &-title {
        font-size: $medium-title;
    }

}

.base-dialog {
    background-color: $color-neutral-background !important;
}

.consent-email-dialog-content {
    font-size: $body-text;

    a {
        color: $color-primary-darken;
    }

    p {
        margin: 0px 0px 0px 8px;
    }
    
    .consent-email-dialog-content__title {
        font-weight: normal;
    }

    .q-checkbox__label {
        margin-left: 8px;
    }

    &__terms {
        margin-top: 24px;
    }

    &__email {
        margin: 24px 0px;
    }
}
</style>