<script setup lang="ts">

import { ref, computed } from 'vue';
import InfoWindow from '../map/InfoWindow.vue';
import BaseSelect from '@/components/base/BaseSelect.vue';
import BaseButton from '../base/BaseButton.vue';
import BaseInput from '@/components/base/BaseInput.vue'
import MarkerQuestions from '@/components/map/MarkerQuestions.vue';
import { useI18n } from "vue-i18n";
import { getTranslation } from "@/utils/data-display-utils";
import { useForm, useField, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';
import MarkerInfo from "@/components/map/MarkerInfo.vue";

// PROPS
const props = defineProps<{
    infoVisible?: boolean;
    userEditing?: boolean;
    addingData?: boolean;
    markerSelected?: boolean;
    isFullscreen?: boolean;
    mapConfig: any;
}>();

// EMITS
const emit = defineEmits<{
    (e: "setMarker"): void;
    (e: "setUserComment", comment): void;
    (e: "changeCategory", category): void;
    (e: "closeMarker"): void;
    (e: "closeInfo"): void;
    (e: "closeEditing"): void;
    (e: "closeAdding"): void;
    (e: "likeAction"): void;
}>();

// CONST
const { t } = useI18n();

// FORM
const validationSchema = yup.object().shape({
    comment: yup.string().required(() => t('errors.required')),
})
const { handleSubmit, meta, errors } = useForm({
    validationSchema,
});


// COMPUTED
const categoryName = computed(() => {
    return props.mapConfig.categories.find(category => category.id === props.markerSelected[0].properties.markerCategoryId)
})

const marker = computed(()=>{
    return props.markerSelected[0].properties
})


// FUNCTIONS
function setUserMarker () {
    emit('setMarker');
}

const setUserComment = handleSubmit(async (values) => {
    emit('setUserComment', values.comment)
});

function changeMarkerCategory (e) {
    emit('changeCategory', e)
}

function closeMarker () {
    emit('closeMarker')
}

function closeInfo () {
    emit('closeInfo')
}

function closeEditing () {
    emit('closeEditing')
}

function closeAdding () {
    emit('closeAdding')
}

function likeAction () {
    emit('likeAction')
}

</script>
<template>
    <div  :class="['bottom-windows', { fullscreenInfo: props.isFullscreen }]">
        <InfoWindow v-if="!props.isFullscreen && props.infoVisible" @close="closeInfo">
            <template v-slot:title>{{ $t('map.instructions.title') }}</template>
            <template v-slot:content>
                <div>{{ $t('map.instructions.content') }}</div>
            </template>
            <template v-slot:close>
                <q-icon name="close" @click="closeInfo"/>
            </template>
        </InfoWindow>
        <InfoWindow v-if="!props.isFullscreen && userEditing && !props.infoVisible" @close="closeEditing">
            <template v-slot:title>{{ $t('map.add-marker.title') }}</template>
            <template v-slot:content>
                <div>{{ $t('map.add-marker.content') }}</div>
                <BaseButton class="action set-location" icon="location_searching" :label="$t('map.add-marker.button')" @click="setUserMarker"/>
            </template>
            <template v-slot:close>
                <q-icon name="close" @click="closeEditing"/>
            </template>
        </InfoWindow>
        <InfoWindow v-if="!props.isFullscreen && addingData && !props.infoVisible" @close="closeAdding">
            <template v-slot:title>{{ $t('map.selected-point.title') }}</template>
            <template v-slot:content>
                <MarkerQuestions @changeCategory="changeMarkerCategory($event)" :mapConfig="props.mapConfig"/>
                <BaseButton class="action" :label="$t('buttons.submit')" @click="setUserComment" :disabled="!meta.valid"/>
            </template>
            <template v-slot:close>
                <q-icon name="close" @click="closeAdding"/>
            </template>
        </InfoWindow>
        <InfoWindow v-if="markerSelected && !props.infoVisible" @close="closeMarker">
            <template v-slot:content>
                <MarkerInfo :marker="marker" :category="getTranslation(categoryName, 'name')" @like-action="likeAction"/>
            </template>
            <template v-slot:close>
                <q-icon name="close" @click="closeMarker"/>
            </template>
        </InfoWindow>
    </div>
</template>
<style lang="scss">
.bottom-windows{
    display: flex;
}
.fullscreenInfo{
    width: 50%;
    bottom: 40px;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
}
</style>