<script setup lang="ts">
import { RouterLink } from 'vue-router';
import { useCommunityStore } from '@/store/communityStore';
import { getTranslation } from "@/utils/data-display-utils";

const communityStore = useCommunityStore();
const pages = communityStore.community.customPages.filter(page => page.type != 1);
</script>

<template>
    <div class="custom-pages-menu"
         v-if="pages.length > 0">
        <ul>
            <li v-for="page in pages"
                :key="page.slug">
                <a v-if="page.type == 3"
                   :href="getTranslation(page, 'content')">
                    {{ getTranslation(page, 'title') }}
                </a>
                <router-link v-else
                             :to="{ name: 'custom-page', params: { slug: page.slug } }">
                    {{ getTranslation(page, 'title') }}
                </router-link>
            </li>
        </ul>
    </div>
</template>

<style lang="scss">
.custom-pages-menu {
    // min-width: 200px;
    // margin-left: auto;
    // margin-top: $space-lg;
    // padding: 0 20px;

    // display: flex;
    // justify-content: center;

    // background-color: white;
    // border: 1px solid $color-primary-50;
    // border-radius: 8px;

    ul {
        list-style-type: none;
        padding: 0;
        margin-bottom: 0;
    }

    li {
        margin-bottom: $space-sm;
        font-size: $header;
    }
}
</style>