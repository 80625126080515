<template>
	<h4>Test Page</h4>
	<div class="component">
		<p>Button Toggle</p>
<!--		<BaseButtonToggle :left-state="opened" :right-state="closed" />-->
	</div>

	<div class="component">
		<p>Base Tag</p>
		<BaseTag icon="mail">
			<template v-slot:title>Consultation</template>
		</BaseTag>
	</div>

	<div class="component">
		<p>Time left tag</p>
		<TimeLeftTag :time-left="timeLeft" />
	</div>

	<div class="component">
		<p>Context Box</p>
		<BaseContextBox>
			<template v-slot:title>
				<div>{{ contextBoxTitle }}</div>
			</template>
			<template v-slot:content>
				<div>
					{{ contextBoxText }}
				</div>
			</template>
		</BaseContextBox>
	</div>

	<div class="component">
		<p>Key Info</p>
		<KeyInfo type="consultation" :key-information="keyInformationTest1" />
		<KeyInfo
			is-small
			type="consultation"
			:key-information="keyInformationTest2"
		/>
	</div>
	<div class="component">
		<p>Base Button</p>
		<div class="buttons">
			<BaseButton text="Basic full width" full-width />
			<BaseButton text="Primary" primary />
			<BaseButton text="Secondary" secondary />
			<BaseButton is-transparent text="Transparent" />
			<BaseButton is-round text="Round" />
			<BaseButton disabled text="Disabled" />
			<BaseButton is-loading text="Loading" />
			<BaseButton has-border text="Border" />
			<BaseButton left-icon="close" text="left icon" />
			<BaseButton right-icon="close" text="right icon" />
		</div>
	</div>

	<div class="component">
		<p>Toaster</p>
		<div class="buttons">
			<BaseButton
				:left-icon="'o_notifications'"
				text="Information"
				@click="sendInfoToaster()"
			/>
			<BaseButton
				success-class
				:left-icon="'check'"
				text="Success"
				@click="sendSuccessToaster()"
			/>
			<BaseButton
				danger-class
				:left-icon="'close'"
				text="Error"
				@click="sendErrorToaster()"
			/>
		</div>
	</div>
	<div class="component">
		<p>Base Checkbox</p>
		<BaseCheckbox :model="checkbox1" label="Basic" />
		<BaseCheckbox :model="checkbox2" label="Disable" disabled />
	</div>

	<div class="component">
		<p>Base Popup</p>
		<div class="buttons">
			<BaseButton primary text="Show Popup" @click="openPopup" />
		</div>
		<BasePopup :isOpen="isPopupOpen" :action="popupAction" @cancel="closePopup" @continue="handleContinue" />
	</div>


	<div class="component">
		<p>Base Select</p>
		<div class="select">
			<BaseSelect :options="selectOptions" />
			<BaseSelect :options="selectOptions" multiple />
			<BaseSelect
				:options="selectOptions"
				filter
				search
				@filter="filterOptions"
				@update="setNewModelValue"
			/>
			<BaseSelect :options="selectOptions" disabled />
		</div>
	</div>

	<div class="component">
		<p>Base Input</p>
		<div class="inputs">
			<BaseInput
				:model="basicInput"
				@update="updateBasicInput"
				placeholder="XXX@xxx.com"
				rules="email"
			/>
			<BaseInput
				:model="basicInput"
				@update="updateBasicInput"
				placeholder="### ###-####"
				rules="phoneNumber"
				mask="phoneNumber"
			/>
			<BaseInput
				:model="disabledInput"
				@update="updateBasicInput"
				placeholder="XXX@xxx.com"
				rules="email"
				disabled
			/>
			<BaseInput
				:model="readOnlyInput"
				@update="updateBasicInput"
				placeholder="XXX@xxx.com"
				rules="email"
				readonly
			/>
		</div>
	</div>

	<div class="component">
		<p>Base link</p>
		<p>
			<BaseLink
				destination="https://www.cocoriko.org/conditions"
				text="terms.terms-of-service"
			/>
		</p>
		<p>
			<BaseLink
				destination="https://www.cocoriko.org/conditions"
				text="terms.terms-of-service"
				is-disabled
			/>
		</p>
		<p>
			<BaseLink
				destination="https://www.cocoriko.org/conditions"
				text="terms.terms-of-service"
				is-small
			/>
		</p>
		<p>
			<BaseLink
				destination="https://www.cocoriko.org/conditions"
				text="terms.terms-of-service"
				is-small
				is-disabled
			/>
		</p>
	</div>
	<div class="component">
		<p>Upload Component</p>
		<div class="buttons">
			<BaseButton
				primary
				text="Changer mon image de profil"
				@click="openAvatarImageDialog"
			/>
			<BaseButton
				primary
				text="Changer l'image de la communautée"
				@click="openCommunityImageDialog"
			/>
			<BaseButton
				primary
				text="Changer l'image de la carte"
				@click="openCardImageDialog"
			/>
		</div>

		<UploadImageDialog
			ref="uploadAvatarImageDialogRef"
			title="Photo de profil"
			:image="userAvatar"
			:type="'avatar'"
			@remove="removeImage"
			@upload="uploadImage"
			@update="updateUserAvatar"
			:disabled="userAvatar.length === 0"
		/>
		<UploadImageDialog
			ref="uploadCommunityImageDialogRef"
			title="Photo de bannière"
			:image="communityImage"
			:type="'community'"
			@remove="removeImage"
			@upload="uploadImage"
			:disabled="userAvatar.length === 0"
		/>
		<UploadImageDialog
			ref="uploadCardImageDialogRef"
			title="Photo de bannière"
			:image="cardImage"
			:type="'card'"
			@remove="removeImage"
			@upload="uploadImage"
			:disabled="userAvatar.length === 0"
		/>
	</div>
	<div class="component">
		<p>GENERATED COLORS</p>
		<div style="display: flex"></div>
		<div style="display: flex">
			<div class="test-color"></div>
			<div class="test-color-50"></div>
			<div class="test-color-12"></div>
			<div class="test-color-8"></div>
			<div class="test-color-darken"></div>
		</div>
		<p>REEL COLORS</p>
		<div style="display: flex">
			<div class="color-primary"></div>
			<div class="color-primary-50"></div>
			<div class="color-primary-12"></div>
			<div class="color-primary-8"></div>
			<div class="color-primary-darken"></div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { onMounted, Ref, ref } from "vue";
import { showToaster } from "@/utils/toaster-util";
import BaseButtonToggle from "@/components/base/BaseButtonToggle.vue";
import BaseContextBox from "@/components/base/BaseContextBox.vue";
import BaseTag from "@/components/base/BaseTag.vue";
import TimeLeftTag from "@/components/TimeLeftTag.vue";
import KeyInfo from "@/components/KeyInfo.vue";
import BaseLink from "@/components/base/BaseLink.vue";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseInput from "@/components/base/BaseInput.vue";
import BaseSelect from "@/components/base/BaseSelect.vue";
import UploadImageDialog from "@/components/dialogs/UploadImageDialog.vue";
import BaseDialog from "@/components/base/BaseDialog.vue";
import BasePopup from "@/components/base/BasePopup.vue";
import { useUserStore } from "@/store/userStore";
import { base64ToFile, downloadFile } from "@/utils/file-util";

onMounted(() => {
	getUserImage();
});

// STORE
const userStore = useUserStore();
import BaseCheckbox from "@/components/base/BaseCheckbox.vue";

const timeLeft = ref(9);
const opened = { name: "consultation-state.opened", icon: "lock_open" };
const closed = { name: "consultation-state.closed", icon: "lock_clock" };
const contextBoxTitle = ref<string>("A propos");
const contextBoxText = ref<string>(
	" Lorem Ipsum is simply dummy text of the printing and typesetting\n" +
		"          industry. Lorem Ipsum has been the industry's standard dummy text ever\n" +
		"          since the 1500s, when an unknown printer took a galley of type and\n" +
		"          scrambled it to make a type specimen book. It has survived not only five\n" +
		"          centuries, but also the leap into electronic typesetting, remaining\n" +
		"          essentially unchanged. It was popularised in the 1960s with the release\n" +
		"          of Letraset sheets containing Lorem Ipsum passages, and more recently\n" +
		"          with desktop publishing software like Aldus PageMaker including versions\n" +
		"          of Lorem Ipsum."
);
const description = ref<string>(
	"Consultation publique sur l'aménagement d'un espace citoyen au port de Rimouski"
);

const isPopupOpen = ref<boolean>(false);
const popupAction = ref<string>("");

const openPopup = () => {
  isPopupOpen.value = true;
  popupAction.value = "Example Action";
};

const closePopup = () => {
  isPopupOpen.value = false;
  popupAction.value = ""; // Clear the action when closing the popup
};

const handleContinue = () => {
  // Perform actions when the user clicks "Continue"
  closePopup();
};

const keyInformationTest1 = ref<object>({
	consultation: 0,
	proposal: 230,
	participant: 21,
	vote: 3760,
});
const keyInformationTest2 = ref<object>({
	question: 0,
	like: 4,
	text: 12,
	whatever: 31760,
});
const basicInput = ref<string>("");
const readOnlyInput = ref<string>("readOnly@email.com");
const disabledInput = ref<string>("Disabled Input");
const userAvatar = ref<string>("");
const communityImage = ref<string>("");
const cardImage = ref<string>("");
const uploadAvatarImageDialogRef = ref<InstanceType<typeof BaseDialog>>();
const uploadCommunityImageDialogRef = ref<InstanceType<typeof BaseDialog>>();
const uploadCardImageDialogRef = ref<InstanceType<typeof BaseDialog>>();

// FUNCTION

function getUserImage() {
	const user = userStore.user;
	const avatar = user?.avatar;
	userAvatar.value =
		user && avatar && !avatar.cropped!.includes("blank") ? avatar.cropped! : "";
}

const selectOptions: Ref<object[]> = ref([
	{ id: 1, name: "Value One" },
	{ id: 2, name: "Value Two" },
	{
		id: 3,
		name: "Value Three",
	},
]);

function updateUserAvatar(image: string) {
	userAvatar.value = image;
}

const checkbox1 = ref<boolean>(false);
const checkbox2 = ref<boolean>(false);

function updateBasicInput(value: string) {
	basicInput.value = value;
}

function sendInfoToaster() {
	showToaster("This is an information toaster", "info");
}

function sendSuccessToaster() {
	showToaster("This is a success toaster", "success");
}

function sendErrorToaster() {
	showToaster("This is an error toaster", "error");
}

function filterOptions(values: object[]) {
	selectOptions.value = values;
}

function setNewModelValue(value: object) {
	return value;
}

function openAvatarImageDialog() {
	uploadAvatarImageDialogRef.value.openDialog();
}
function openCommunityImageDialog() {
	uploadCommunityImageDialogRef.value.openDialog();
}
function openCardImageDialog() {
	uploadCardImageDialogRef.value.openDialog();
}

function uploadImage(image: string) {
	const file = base64ToFile(image, "avatar.jpg");
	// downloadFile((file))
	userStore.setUserAvatar(image);
	userAvatar.value = userStore.user!.avatar.cropped!;
}

function removeImage() {
	userStore.removeAvatar();
}
</script>

<style lang="scss">
.test-page {
	padding: 16px;
}

.colors {
	margin-top: 24px;
}

.component {
	margin-top: 16px;
	padding: 32px;
	border: #1d1d1d 1px solid;
	border-radius: 8px;

	p {
		font-size: $sub-header;
		font-weight: bold;
	}

	.buttons {
		.q-btn {
			margin: 0 $space-sm;
		}

		.full-width {
			margin-bottom: $space-md;
		}
	}

	.inputs {
		display: flex;
	}
}

.base-input {
	margin-right: $space-lg !important;
}

.select {
	display: flex;
	width: 100%;

	.base-select {
		margin-right: 16px;
	}
}

.test-color {
	height: 100px;
	width: 100px;
	background-color: $color-primary;
}

.test-color-50 {
	height: 100px;
	width: 100px;
	background-color: $color-primary-50;
}

.test-color-12 {
	height: 100px;
	width: 100px;
	background-color: $color-primary-12;
}

.test-color-8 {
	height: 100px;
	width: 100px;
	background-color: $color-primary-8;
}

.test-color-darken {
	height: 100px;
	width: 100px;
	background-color: $color-primary-darken;
}

.color-primary {
	height: 100px;
	width: 100px;
	background-color: #007dba;
}

.color-primary-50 {
	height: 100px;
	width: 100px;
	background-color: #7fbddc;
}

.color-primary-12 {
	height: 100px;
	width: 100px;
	background-color: #e0eff6;
}

.color-primary-8 {
	height: 100px;
	width: 100px;
	background-color: #ebf5fa;
}

.color-primary-darken {
	height: 100px;
	width: 100px;
	background-color: #005178;
}
</style>
