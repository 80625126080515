<script setup lang="ts">

import { computed, onMounted, ref, watchEffect } from "vue";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseInput from "@/components/base/BaseInput.vue";
import BaseLink from "@/components/base/BaseLink.vue";
import BaseSelect from "@/components/base/BaseSelect.vue";
import BaseCheckbox from "@/components/base/BaseCheckbox.vue";

import { QForm } from "quasar";
import { useUserStore, getUniqueId, maskEmail } from "@/store/userStore";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { paths } from "@/utils/paths";
import { userService } from "@/services/user.service";
import { authService } from "@/services/auth.service";
import { mask } from '@/utils/mask-util';


// LIFECYCLE
onMounted(() => {
    userStore.loadLanguages();
    userStore.loadGenders();
});

// STORE
const userStore = useUserStore();

// CONST
const { t } = useI18n();
const router = useRouter();

// REFS
const email = ref<string>(maskEmail(userStore.user.email));
const uniqueId = ref<string>(getUniqueId(userStore.user));
const idIsHidden = ref<boolean>(true);

interface InitialInformation {
    first_name: string;
    last_name: string;
    language_code: string;
    gender: string;
    year_birth: string;
    postal_code: string;
    email: string;
    emailConsent: boolean;
    isCommunityMailingListMember: boolean;

    [key: string]: string | boolean;
}

function computeInitialInformation(): InitialInformation {
    return {
        first_name: userStore.user.firstName,
        last_name: userStore.user.lastName,
        language_code: userStore.user.preferredLanguage,
        gender: userStore.user.gender,
        year_birth: userStore.user.birthYear,
        postal_code: userStore.user.postalCode,
        email: userStore.user.email,
        emailConsent: userStore.user.emailConsent ? true : false,
        isCommunityMailingListMember: userStore.user.isCommunityMailingListMember ? true : false,
    };
}


const initialInformation = computed(computeInitialInformation);

const userInformation = ref({ ...initialInformation.value });

const isFormChanged = computed(() => {
    for (const key in initialInformation.value) {
        if (Object.prototype.hasOwnProperty.call(initialInformation.value, key)) {
            if (initialInformation.value[key] !== userInformation.value[key]) {
                return true;
            }
        }
    }
    return false;
});

// Watch for changes in userStore.user and update userInformation
watchEffect(() => {
    const updatedInitialInfo = computeInitialInformation();
    for (const key in updatedInitialInfo) {
        if (Object.prototype.hasOwnProperty.call(updatedInitialInfo, key)) {
            userInformation.value[key] = updatedInitialInfo[key];
        }
    }
});

// FUNCTIONS
function updateValue<K extends keyof Information>(key: K, newValue: any) {
    if (key === 'gender')   {
        userInformation.value[key] = newValue.value;
    } else if (key === 'year_birth' || key === 'first_name' || key === 'last_name' || key === 'postal_code') {
        userInformation.value[key] = newValue;
    } else {
        userInformation.value[key] = newValue.value;
    }
}

function updateUser() {
    userService.updateUser(userInformation.value);
}

function getUserInformations() {
    return ref(Object.assign({}, initialInformation));
}

function getUserName() {
    return userStore.getUserName;
}

function displayedEmail(emailIsHidden: boolean): void {
    if (userStore.user && userStore.user.email) {
        email.value = emailIsHidden ? maskEmail(email.value) : userStore.user.email;
    }
}

function toggleEye() {
    idIsHidden.value = !idIsHidden.value;
}

function displayedId() {
    if (userStore.user && userStore.user.uniqueId) {
        return (uniqueId.value = idIsHidden.value
            ? getUniqueId(userStore.user)
            : userStore.user.uniqueId.value);
    }
}

function logout() {
    router.push(paths.root);
    authService.logout();
}

function getDefaultSelectedLanguage() {
    if (userInformation.value.language_code) {
        return userStore.languages.find((language) => language.value === userInformation.value.language_code);
    } else {
        return userStore.languages.find((language) => language.value === 1)
    }
}

function getGender() {
    return userStore.genders.find((gender) => gender.value === parseInt(userInformation.value.gender))
}
</script>

<template>
    <div class="my-profile">
        <div class="my-profile__container">
            <div class="my-profile__title">
                <h1>{{ $t("profile.my-account") }}</h1>
            </div>
            <div class="my-profile__information">
                <div class="my-profile__information--picture">
                </div>
                <div class="my-profile__information--user">
                    <div class="my-profile__information--name" v-if="getUserName()">
                        {{ getUserName() }}
                    </div>
                    <div class="my-profile__information--id">
                        <div>{{ displayedId() }}</div>
                        <q-icon
                            :name="idIsHidden ? 'visibility_off' : 'visibility'"
                            class="cursor-pointer"
                            color="black"
                            @click="toggleEye()"
                        />
                    </div>
                </div>
            </div>

            <div class="my-profile__information--action">
                <BaseButton
                    isSmall
                    isOrange
                    class="primary"
                    :text="$t('profile.logout')"
                    @click="logout()"
                />
            </div>

            <q-separator/>

            <q-form ref="profileForm" class="my-profile__form">
                <div class="my-profile__form--info">
                    <div class="my-profile__form--name">
                        <div class="my-profile__form--firstname">
                            <label for="first-name" class="my-profile__form--label">
                                {{ $t("profile.first-name") }}
                                <BaseInput
                                    class="my-profile__form--input"
                                    :placeholder="$t('profile.first-name')"
                                    :model="userInformation.first_name"
                                    type="text"
                                    name="first-name"
                                    @update="updateValue('first_name', $event)"
                                />
                            </label>
                        </div>
                        <div class="my-profile__form--lastname">
                            <label for="last-name" class="my-profile__form--label">
                                {{ $t("profile.last-name") }}
                                <BaseInput
                                    class="my-profile__form--input"
                                    :placeholder="$t('profile.last-name')"
                                    :model="userInformation.last_name"
                                    type="text"
                                    name="last-name"
                                    @update="updateValue('last_name', $event)"
                                />
                            </label>
                        </div>
                    </div>
                    <label for="preferred-language" class="my-profile__form--label">
                        {{ $t("profile.preferred-language") }}
                        <BaseSelect
                            class="my-profile__form--input"
                            :options="userStore.languages"
                            :default-value="getDefaultSelectedLanguage()"
                            type="select"
                            name="preferred-language"
                            @update="updateValue('language_code', $event)"
                        />
                    </label>
                    <label for="gender" class="my-profile__form--label">
                        {{ $t("profile.gender") }}
                        <BaseSelect
                            class="my-profile__form--input"
                            :options="userStore.genders"
                            :default-value="getGender()"
                            type="select"
                            name="gender"
                            @update="updateValue('gender', $event)"
                        />
                    </label>
                    <label for="year" class="my-profile__form--label">
                        {{ $t("profile.birth-year") }}
                        <BaseInput
                            class="my-profile__form--input"
                            placeholder="AAAA"
                            :mask="mask.year"
                            :model="userInformation.year_birth"
                            type="text"
                            name="year"
                            @update="updateValue('year_birth', $event)"
                        />
                    </label>
                    <label for="postal-code" class="my-profile__form--label">
                        {{ $t("profile.postal-code") }}
                        <BaseInput
                            class="my-profile__form--input"
                            placeholder="A1A 1A1"
                            :model="userInformation.postal_code"
                            :mask="mask.postalCode"
                            type="text"
                            name="postal-code"
                            @update="updateValue('postal_code', $event)"
                        />
                    </label>
                </div>

                <div class="my-profile__form--notification">
                    <div class="my-profile__form--title">
                        {{ $t("profile.email-notification") }}
                    </div>
                    <label for="readonly-email" class="my-profile__form--label">
                        {{ $t("profile.email") }}
                        <BaseInput
                            class="my-profile__form--input"
                            readonly
                            hide
                            :model="email"
                            type="text"
                            name="readonly-email"
                            @update="updateValue('email', $event)"
                            @toggleEye="displayedEmail"
                        />
                    </label>
                    <div class="my-profile__form--checkboxes">
                        <BaseCheckbox
                            :model="userInformation.emailConsent"
                            :label="$t('profile.email-permission')"
                            type="checkbox"
                            @update="updateValue('emailConsent', $event)"
                        />
                      <BaseCheckbox
                            :model="userInformation.isCommunityMailingListMember"
                            :label="$t('profile.mailing-list-member')"
                            type="checkbox"
                            @update="updateValue('isCommunityMailingListMember', $event)"
                        />
                    </div>
                </div>
            </q-form>

            <div class="my-profile__button--save">
                <BaseButton
                    :disabled="!isFormChanged"
                    :text="$t('profile.save-changes')"
                    primary
                    @click="updateUser"
                />
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
.my-profile {
    height: 100%;
    display: flex;

    &__container {
        display: flex;
        flex-direction: column;
        margin: $space-2xl auto;
        width: 1000px;
    }

    &__title {

        h1 {
            font-size: $large-title;
            font-weight: bold;
            padding: $space-2xl 0 $space-lg 0;
            line-height: 100%;
            margin: 0;
        }
    }

    &__button {
        &--save {
            padding: $space-md 0;
        }
    }

    &__information {
        display: flex;
        margin-bottom: $space-xl;
        align-items: center;

        &--picture {
            display: flex;
            flex-direction: column;
            align-items: center;

            .base-link {
                margin-top: $space-sm;
            }
        }

        &--picture-upload {
            margin-top: $space-sm;
            cursor: pointer;
            transition: all 300ms ease;
            text-decoration: none;
            border-bottom: 1px solid transparent;

            &:hover {
                color: $color-neutral-black;
                border-color: black;
                transition: all 300ms ease;
            }
        }

        &--action {
            display: flex;
            flex-direction: column;
            max-width: 160px;
            align-items: flex-start;
            margin-bottom: $space-lg;

            .base-button {
                margin-bottom: $space-sm;
            }
        }

        &--user {
            display: flex;
            flex-direction: column;
            margin-left: $space-xl;
        }

        &--name {
            font-size: $large-title;
        }

        &--id {
            display: flex;
            align-items: center;
            font-size: $small-title;
            font-weight: 300;

            .q-icon {
                color: $color-neutral-grey-400-50;
                margin-left: $space-sm;
                font-size: $large-title;
            }
        }
    }

    &__form {
        margin-top: $space-xl;
        min-height: 300px;
        display: flex;

        &--title {
            font-size: $large-title;
            margin-bottom: $space-md;
        }

        .base-button {
            margin-top: $space-lg;
        }

        &--name {
            display: flex;
            width: 100%;
        }

        &--firstname {
            width: 100%;
            padding-right: $space-lg;
        }

        &--lastname {
            width: 100%;
        }

        &--input {
            padding: $space-sm 0 $space-lg 0;
        }

        &--checkboxes {
            display: flex;
            flex-direction: column;
        }

        &--notification {
            width: 100%;
            padding-left: $space-xl;
        }

        &--info {
            width: 100%;
            padding-right: $space-xl;
        }
    }
}

@media screen and (max-width: $breakpoint-xl) {
    .my-profile {
        &__container {
            width: 900px;
        }
    }
}

@media screen and (max-width: $breakpoint-lg) {
    .my-profile {
        &__container {
            width: 700px;
        }

        &__title {
            padding: 32px 0;
            margin: auto;
        }

        &__information {
            margin: auto;
            align-items: center;

            &--action {
                padding-top: 32px;
                max-width: 100%;
                margin: auto;
                align-items: center;
                justify-content: center;
            }
        }

        &__button {
            &--save {
                .base-button {
                    width: 100%;
                }
            }
        }

        &__form {
            flex-wrap: wrap;

            &--notification {
                padding-left: 0;
            }

            &--info {
                padding-right: 0;
            }
        }
    }
}

@media screen and (max-width: $breakpoint-md) {
    .my-profile {
        &__container {
            width: 500px;
        }

        &__form {
            &--name {
                display: inherit;
            }

            &--firstname {
                padding: 0;
            }
        }
    }
}

@media screen and (max-width: $breakpoint-sm) {
    .my-profile {
        &__container {
            width: 400px;
        }
    }
}
</style>
