<script setup lang="ts">
import { currentLocale } from "@/plugins/i18n";
import { type Community } from "@/store/communityStore";
import { computed } from "vue";

const props = defineProps<{
    community: Community;
}>();

const appLocale = computed(() => currentLocale())

const isSubjectToLaw96 = computed(() => {
    return props.community.options.loi96 ?? false;
})

</script>

<template>
    <div class="loi-96" v-if="appLocale === 'en' && isSubjectToLaw96">
        <span>The<em><a target="_blank"
                   href="https://www.quebec.ca/en/government/policies-orientations/french-language/modernization-charter-french-language">
                    Charter of the French language </a></em>and its regulations govern the consultation of
            English-language content.</span>
    </div>
</template>

<style lang="scss">
.loi-96 {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 40px;
    background-color: $color-primary-darken;
    color: $color-neutral-white;

    a {
        color: $color-neutral-white;
    }
}
</style>