<script setup lang="ts">
import { computed, ref } from "vue";
import { useUserStore } from "@/store/userStore";
import { formsService } from "@/services/forms.service";
// PROPS
const props = defineProps<{
    marker: any;
    category: string;
}>();

const userStore = useUserStore();

// EMITS
const emit = defineEmits<{
    (e: "likeAction"): void;
}>();

//CONST
const likes = computed(()=>{
    return JSON.parse(props.marker.markerLike)
})

const hasLike = computed(()=>{
    return userStore.isUserLogged ? likes.value?.findIndex(l => l.user_id === userStore.getUserId) >= 0 : false
})

//FUNCTION
const likeAction = ()=>{
    emit('likeAction')
}

</script>

<template>
<div class="infosMarker_wrapper" v-if="marker">
    <div class="infosMarker">
        <div class="infosMarker_content">
            <p>{{ marker.title }}</p>
            <div class="infosMarker_content-actions">
                <q-btn flat @click="likeAction">
                    {{ likes.length }}
                    <q-icon name="o_thumb_up" :color="hasLike && 'blue'"/>
                </q-btn>
            </div>
        </div>
        <div class="infosMarker_infos">
            <p>{{ category }} </p>
            <p>{{ marker.markerUser }}</p>
        </div>
    </div>
</div>
</template>

<style scoped lang="scss">

</style>