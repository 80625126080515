<script setup lang="ts">

import useResponsive from "@/utils/responsive-util";

// CONST
const { isMobile } = useResponsive()

// PROPS
const props = defineProps<{
	isAdmin?: boolean,
	openState: {
		name: string;
		icon: string;
		quantity: number;
	};
	closedState?: {
		name: string;
		icon: string;
		quantity: number;
	};
	preLaunchedState?: {
		name: string;
		icon: string;
		quantity: number;
	};
	archivedState?: {
		name: string;
		icon: string;
		quantity: number;
	};
}>();

// MODEL
const model = defineModel();
</script>

<template>
	<div class="base-button-toggle">
		<q-btn-toggle v-model="model"
					  no-caps
					  unelevated
					  toggle-text-color="selected"
					  class="full-width-toggle"
					  :options="[
					  	{ value: 'openValue', slot: 'open' },
					  	{ value: 'closedValue', slot: 'closed' },
					  	...(props.isAdmin
					  		? [
					  			{ value: 'preLaunchedValue', slot: 'preLaunched' },
					  			{ value: 'archivedValue', slot: 'archived' },
					  		]
					  		: []),
					  ]">
			<template v-slot:open>
				<q-icon v-if="!isMobile":name="openState.icon" />
				{{ $t(openState.name) }} [{{ openState.quantity }}]
			</template>

			<template v-slot:closed>
				<q-icon v-if="!isMobile" :name="closedState.icon" />
				{{ $t(closedState.name) }} [{{ closedState.quantity }}]
			</template>

			<template v-slot:preLaunched>
				<q-icon v-if="!isMobile" :name="preLaunchedState.icon" />
				{{ $t(preLaunchedState.name) }} [{{ preLaunchedState.quantity }}]
			</template>

			<template v-slot:archived>
				<q-icon v-if="!isMobile" :name="archivedState.icon" />
				{{ $t(archivedState.name) }} [{{ archivedState.quantity }}]
			</template>
		</q-btn-toggle>
	</div>
</template>

<style lang="scss">
.base-button-toggle {

	.q-icon {
		margin-right: 8px;
	}

	@media screen and (max-width: $breakpoint-md) {
		.q-btn {
			width: 100%;
		}

		.full-width-toggle {
			width: 100%;
		}
	}

	.q-btn-group {
		border-radius: 6px;
		background-color: $color-neutral-white;

		.text-selected {
			font-weight: bold;
		}
	}

	.bg-primary {
		background-color: $color-primary-12 !important;
		color: $color-primary;
		border: solid 1px $color-primary;
	}
	@media screen and (max-width: $breakpoint-sm) {
		.q-btn {
			padding: $space-sm;
		}

		.full-width-toggle {
			width: 100%;
		}
	}
}
</style>
