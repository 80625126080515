import { Config } from '@/config';
import apiService from "@/services/api.service";
import axios, { AxiosResponse, CancelTokenSource } from 'axios';
import { useCommunityStore } from '@/store/communityStore';
import {Section} from '@/store/section.interface';

const CancelToken = axios.CancelToken;
let cancelTokenSections: CancelTokenSource | null = null;

class SectionsService {
  async getSections(consultation_id: number): Promise<Section[]> {
    const communityStore = useCommunityStore();

    if (cancelTokenSections) {
      cancelTokenSections.cancel('Multiple requests.');
    }

    cancelTokenSections = CancelToken.source();

    const response = await apiService.get(
      `${Config.api.backend.urlV2}/consultations/${consultation_id}/sections`,
    );
    const sectionsData: any = response.data;
    if (sectionsData) {
      communityStore.setSections(sectionsData, consultation_id);
    }
    return response.data;
  }
}

export const sectionsService = new SectionsService();