<script setup lang="ts">

import { ref } from "vue";
import BaseDialog from "@/components/base/BaseDialog.vue";
import BaseButton from "../base/BaseButton.vue";

import { useI18n } from 'vue-i18n';


// REFS
const { t } = useI18n();
const confirmFlagDialog = ref<InstanceType<typeof BaseDialog>>();

// EMITS
const emit = defineEmits<{
    (e: "submit"): void;
}>();


// FUNCTIONS
function openDialog() {
    confirmFlagDialog.value?.openDialog();
}

function closeDialog() {
    confirmFlagDialog.value?.closeDialog();
}

function submitFlag() {
    emit('submit');
}

defineExpose({
    openDialog,
    closeDialog,
});

</script>

<template>
    <BaseDialog ref="confirmFlagDialog">
        <template v-slot:title>
            <div class="confirm-title">
                {{ t('dialog.confirmFlag.title') }}
            </div>
        </template>
        <template v-slot:content>
            <div class="confirm-content">
                <div class="confirm-content__text">
                    {{ t('dialog.confirmFlag.content') }}
                </div>
                <div class="confirm-content__actions">
                    <BaseButton @click="closeDialog">{{ t('buttons.cancel') }}</BaseButton>
                    <BaseButton class="action" @click="submitFlag">{{ t('buttons.submit') }}</BaseButton>
                </div>
            </div>
        </template>
    </BaseDialog>
</template>

<style lang="scss">
.confirm {
    

    &-title {
        font-size: $small-title;
    }

    &-content {
        display: flex;
        flex-direction: column;
        gap: $space-sm;
        font-size: $body-text;

        &__text {
            display: flex;
            justify-content: center;
        }

        &__actions {
            display: flex;
            justify-content: end;
            gap: $space-md;
        }
    }
}
.base-dialog {
    background-color: $color-neutral-background !important;
}
</style>