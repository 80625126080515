<script setup lang="ts">

import { ref } from "vue";
import BaseDialog from "@/components/base/BaseDialog.vue";
import BaseButton from "../base/BaseButton.vue";
import termsQuestion from "../questions/termsQuestion.vue";
import propositionQuestions from '@/components/questions/propositionQuestions.vue';

import { useI18n } from 'vue-i18n';


// PROPS
const props = defineProps<{
    sectionId: number;
}>();


// REFS 
const { t } = useI18n();
const propositionDialogRef = ref<InstanceType<typeof BaseDialog>>();
const activeScreen = ref<string>('terms');
const acceptConditions = ref<boolean>(false);


// FUNCTIONS
function openDialog() {
    propositionDialogRef.value?.openDialog();
}

function closeDialog() {
    activeScreen.value = 'terms'
    propositionDialogRef.value?.closeDialog();
    resetDialog();
}

function resetDialog() {
    acceptConditions.value = false;
}

function updateView(value) {
    activeScreen.value = value
}

function updateConditions(value) {
    acceptConditions.value = value;
}

defineExpose({
    openDialog,
    closeDialog,
});
</script>

<template>
    <BaseDialog class="proposition" ref="propositionDialogRef">
        <template v-slot:title>
            <div class="proposition-title">
                {{ $t('dialog.proposition.title') }}
            </div>
        </template>
        <template v-slot:content>
            <Form class="proposition-data">
                <div class="proposition-data__conditions" v-if="activeScreen === 'terms'">
                    <terms-question
                        :accept-conditions="acceptConditions"
                        @cancel="closeDialog"
                        @update="updateView($event)"
                        @conditions="updateConditions"
                    />
                </div>
                <div class="proposition-data__proposition" v-if="activeScreen === 'proposition'">
                    <proposition-questions
                        :section-id="props.sectionId"
                        @update="updateView($event)"
                    />
                </div>
                <div class="proposition-data__moderation" v-if="activeScreen === 'moderation'">
                    <div class="proposition-data__moderation-text">   
                        <p>{{ $t('dialog.proposition.moderation') }}</p>
                    </div>
                    <div class="proposition-data__moderation-actions">
                        <BaseButton class="action" @click="closeDialog">
                            {{ $t('buttons.close') }}
                        </BaseButton>
                    </div>
                    
                </div>
            </Form>
        </template>
    </BaseDialog>
</template>

<style lang="scss">
.proposition {
    &-title {
        font-size: $small-title;
    }

    &-data {
        &__conditions {
            display: flex;
            flex-direction: column;
            gap: $space-sm;
        }

        &__moderation {
            &-text {
                display: flex;
                justify-content: center;
            }

            &-actions {
                display: flex;
                justify-content: end;
            }   
        }
    }
}
</style>