<script setup lang="ts">
import { useField } from 'vee-validate';

const props = defineProps<{
    name: string,
    label: string,
    disabled?: boolean,
    type?: string,
}>();

const { value: value, errorMessage: errorMessage } = useField<string>(props.name);

</script>

<template>
    <textarea
        v-model="value"
        :label="props.label"
        v-bind="$attrs"
        :error="!!errorMessage"
        :error-message="errorMessage"
        :disable="disabled"
        :type="type"
    ></textarea>
</template>