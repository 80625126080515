<script setup lang="ts">

// EMITS
import { computed, onBeforeUnmount, onMounted, ref } from "vue";

const emit = defineEmits<{
    (e: "update", newValue: boolean): void;
}>();

// PROPS
const props = withDefaults(defineProps<{
    model: boolean;
    label: string;
    disabled?: boolean;
    defaultValue?: boolean
}>(), {
    defaultValue: false
});

// REFS
const modelValue = ref(props.defaultValue);
const checkboxTextRef = ref(null);

// COMPUTED
const localModelValue = computed({
    get() {
        if(props.model === undefined){ 
            return false;
        } else {
            return props.model;
        }
    },
    set(newValue: boolean) {
        modelValue.value = newValue;
        emit("update", newValue);
    }
});

const stopPropagation = (event) => {
  event.stopPropagation();
};

onMounted(() => {
  const links = checkboxTextRef.value.querySelectorAll('a');
  links.forEach(link => {
    link.addEventListener('click', stopPropagation);
  });
});

onBeforeUnmount(() => {
  const links = checkboxTextRef.value.querySelectorAll('a');
  links.forEach(link => {
    link.removeEventListener('click', stopPropagation);
  });
});
</script>

<template>
    <q-checkbox
        class="base-checkbox"
        v-model="localModelValue"
        :disable="props.disabled"
    ><span ref="checkboxTextRef" v-html="$props.label"></span></q-checkbox>
</template>

<style lang="scss">

.q-checkbox {
    &__inner {
        &--truthy {
            color: $color-primary !important;
        }
    }
}

</style>