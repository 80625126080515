<script setup lang="ts">

import { inject, ref } from "vue";
import BaseDialog from "@/components/base/BaseDialog.vue";
import BaseButton from '@/components/base/BaseButton.vue';
import { useForm } from 'vee-validate';
import { useI18n } from 'vue-i18n';
import { bootstrap } from "vue-gtag";
import { VueCookies } from "vue-cookies";

const cookies = inject<VueCookies>('$cookies')

// REFS
const { t } = useI18n();
const cookieConsentDialog = ref<InstanceType<typeof BaseDialog>>();

const { handleSubmit, meta } = useForm();

// FUNCTIONS
function openDialog() {
    cookieConsentDialog.value?.openDialog();
}

function closeDialog() {
    cookieConsentDialog.value?.closeDialog();
}

const accept = handleSubmit(() => {
    bootstrap().then((gtag) => {
        cookies.set('consent', 1)
    })
    window.clarity('consent');
    closeDialog();
});

const refuse = handleSubmit(() => {
    cookies.set('consent', 0)
    closeDialog();
});


defineExpose({
    openDialog,
    closeDialog,
});

</script>

<template>
    <BaseDialog ref="cookieConsentDialog" :is-dirty="true" :position="'bottom'" :have-close-button="false">
        <template v-slot:title>
            <div class="demo-title">
                {{ t('dialog.cookieConsent.title') }}
                <q-icon name="cookie"/>
            </div>
        </template>
        <template v-slot:content>
            <div>
                <p>
                    {{ t('dialog.cookieConsent.why') }}
                </p>
                <p>
                    {{ t('dialog.cookieConsent.description') }}
                </p>
            </div>

            <div class="row no-wrap justify-end q-gutter-md">

                <BaseButton color="secondary" @click="refuse">{{ t('dialog.cookieConsent.accept-only') }}</BaseButton>
                <BaseButton color="primary" @click="accept">{{ t('dialog.cookieConsent.accept-all') }}</BaseButton>
            </div>
        </template>
    </BaseDialog>
</template>

<style lang="scss">
.demo {


    &-title {
        font-size: $medium-title;
    }

}

.base-dialog {
    background-color: $color-neutral-background !important;
}

</style>