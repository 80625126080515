import { currentLocale } from "@/plugins/i18n";

function isOverflown(text: string, count: number) {
  if (!text) return
  return text.length > count;
}


function getTranslation(field: any, key: string){
  if (!field[key]) return '[MISSING]';
  let language_code = currentLocale();
  const languageList = ["fr", "en"]
  language_code = language_code === "fr" ? "fr" : "en";
  const defaultLanguage = languageList.find(l => l !== language_code)!
  if(!(language_code in field[key])){
    return field[key][defaultLanguage];
  }
  return field[key][language_code];
}

// Method to remove timestamp from URL
function removeTimestamp(url: string): string {
  if (url) {
    return url.split("&t=")[0];
  }
  return '';
}

export { isOverflown, removeTimestamp, getTranslation };
