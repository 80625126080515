import { Config } from '@/config';
import apiService from "@/services/api.service";
import axios, { CancelTokenSource } from 'axios';
import { useCommunityStore } from '@/store/communityStore';
import { DTOCustomPage } from '@/store/customPage.interface';


const CancelToken = axios.CancelToken;
let cancelTokenPlatforms: CancelTokenSource | null = null;


class CustomPageService {
  async getCustomPages(): Promise<DTOCustomPage[]> {

    if (cancelTokenPlatforms) {
      cancelTokenPlatforms.cancel('Multiple requests.');
    }
    cancelTokenPlatforms = CancelToken.source();

    const response = await apiService.get(`${Config.api.backend.urlV2}/community/pages`);
    const customPageData: any = response.data;
    if (customPageData) {
      const communityStore = useCommunityStore();
      communityStore.setCustomPage(customPageData);
    }
    return response.data;
  }

}

export const customPageService = new CustomPageService();
