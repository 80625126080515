import { createApp } from "vue";
import App from "./App.vue";
import { createPinia } from "pinia";

import router from "./plugins/router";
import { options, quasar } from "./plugins/quasar";
import { i18n } from "./plugins/i18n";
import VueCookies from 'vue-cookies';
import VueGtag from "vue-gtag";
// Import icon libraries
import '@quasar/extras/material-icons/material-icons.css'
import '@quasar/extras/material-icons-outlined/material-icons-outlined.css'
import 'mapbox-gl/dist/mapbox-gl.css';

createApp(App)
    .use(router)
    .use(createPinia())
    .use(i18n)
    .use(quasar, options)
    .use(VueCookies, { expires: '1m' })
    .use(VueGtag, {
        bootstrap: false,
        config: { id: "G-E1YZP106T0" }
    }, router)
    .mount("#app");
