<script setup lang="ts">

import { getTranslation } from "@/utils/data-display-utils";

// PROPS
const props = defineProps<{
    statistiques: any,
    labels: any,
    results?: any
}>();


</script>
<template>
    <div class="wrapper">
        <div class="rate-results" v-for="value, index in props.statistiques">
            <div class="rate-results__label">
                {{ getTranslation(props.labels[index - 1], 'label') }}
                <div class="rate-results__label-icon" v-if="props.results && props.results.value_id[0]  === props.labels[index - 1].value">
                    <q-icon color="primary" size="sm" name="check_circle"/>
                </div>
            </div>
            <div class="rate-results__value">
                {{ value }}%
            </div>
        </div>
    </div>
</template>
<style lang="scss">
.wrapper {
    max-height: 80px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    font-size: $body-text;
    gap: 0 $space-md;

    .rate-results {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: $space-sm;

        &__label {
            display: flex;
            gap: $space-sm;
        }

        &__value {
            display: flex;
            flex-grow: 2;
            justify-content: end;
            padding-right: $space-md;
        }
    }
}
</style>