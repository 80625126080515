<script setup lang="ts">

import BaseDialog from './BaseDialog.vue';
import BaseLink from './BaseLink.vue';
import { ref } from "vue";
import { constant } from "@/constant";
import LiteYouTubeEmbed from 'vue-lite-youtube-embed'
import 'vue-lite-youtube-embed/style.css'
import mime from 'mime-types';


// REFS
const mediaDialog = ref<InstanceType<typeof BaseDialog>>();

const props = defineProps<{
    type: number;
    link?: string;
    title: string;
    content?: any;
    image?: string;
}>();


// FUNCTIONS
function openDialog() {
    mediaDialog.value?.openDialog();
}


function closeDialog() {
    mediaDialog.value?.closeDialog();
}


defineExpose({
    openDialog,
    closeDialog,
});
const slide = ref(1);

const open = (url: string) => {
    window.open(url, '_blank');
}

const getBytes = (bytes: number) => {
    const sufixes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    return !bytes && '0 Bytes' || (bytes / Math.pow(1024, i)).toFixed(2) + " " + sufixes[i];
};

</script>

<template>
    <div class="base-media" @click="openDialog()">
        <div class="base-media__type">
            <div v-if="props.type === constant.Context.Type.File">
                <q-icon
                    class="icon"
                    name="attach_file"
                />
            </div>
            <div v-else-if="props.type ===  constant.Context.Type.Text">
                <q-icon
                    class="icon"
                    name="o_description"
                />
            </div>
            <div v-else-if="props.type === constant.Context.Type.Image">
                <q-icon
                    class="icon"
                    name="o_image"
                />
            </div>
            <div v-else-if="props.type === constant.Context.Type.Video">
                <q-icon
                    class="icon"
                    name="o_videocam"
                />
            </div>
            <div v-else>
                <q-icon
                    class="icon"
                    name="o_link"
                />
            </div>
        </div>
        <div v-if="props.type != constant.Context.Type.Link" class="base-media__descriptive-text">
            {{ props.title }}
            <BaseDialog ref="mediaDialog">
                <template v-slot:title>
                    <span class="title">{{ props.title }}</span>
                </template>
                <template v-slot:content>
                    <div v-if="props.type === constant.Context.Type.Text" v-html="props.content"></div>
                    <template v-if="props.type === constant.Context.Type.Image">
                        <div class="q-pa-md full-height">
                            <q-carousel
                                v-model="slide"
                                swipeable
                                animated
                                control-color="primary"
                                navigation
                                infinite
                                padding
                                arrows
                                class="rounded-borders"
                            >
                                <q-carousel-slide v-for="(image, index) in props.content" :name="index+1">
                                    <img :src="image.url" height="100%" width="100%">
                                    <div class="row  no-wrap justify-between">
                                        <i>{{ image.name }}</i>
                                        <q-btn @click="open(`${image.url}`);">
                                            <q-icon name="fullscreen"></q-icon>
                                        </q-btn>
                                    </div>
                                </q-carousel-slide>
                            </q-carousel>
                        </div>
                    </template>
                    <LiteYouTubeEmbed v-if="props.type === constant.Context.Type.Video"
                                      :id="props.content"
                                      :title="props.title"
                    />
                    <template v-if="props.type === constant.Context.Type.File">
                        <div v-for="file in props.content" class="q-mb-sm">
                            <q-btn style="pointer-events: auto;"
                                   class="row rounded-borders  content-file-download q-pa-sm"
                                   :href="file.url"
                                   target="_blank"
                            >
                                <div
                                    class="file-download-type"
                                >
                                    {{
                                        mime.extension(file.mimetype)
                                    }}
                                </div>
                                <div class="column col-8">

                                    <div class="row no-wrap">
                                        <span class="text-bold">{{ file.name }} &nbsp;</span>
                                        <span class="text-italic">({{ getBytes(file.size) }})</span>
                                    </div>
                                </div>
                                <q-icon name="download" class=" col-2 text-h6"/>
                            </q-btn>
                        </div>
                    </template>

                </template>
            </BaseDialog>
        </div>
        <div v-else>
            <BaseLink :text="props.title" :destination="props.content"/>
        </div>

    </div>
</template>

<style lang="scss">


.base-media {
    display: flex;
    flex-direction: row;
    border-radius: 8px;
    font-size: $sub-header;
    padding: 2px $space-md;
    cursor: pointer;
    font-weight: 500;

    &:hover {
        color: $color-neutral-grey-400-50;
    }

    &__type {
        padding: 0 10px 0 0;

        .icon {
            font-size: $small-title;
        }
    }

    &__descriptive-text {
        width: auto;
        font-size: $sub-header;
    }


}

.title {
    font-weight: bold;
}

.content-file-download {
    display: flex;
    padding: 16px;
    align-items: center;

    .file-download-type {
        border-radius: 8px;
        color: #00aeef;
        padding: 16px;
        align-items: center;
        justify-content: center;
        display: flex;
        margin-right: 16px;
        letter-spacing: .05em;
        width: 48px;
        height: 48px;
        border: 2px solid;
    }
}

</style>