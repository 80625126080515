<script setup lang="ts">

import { useI18n } from "vue-i18n";
import { useCommunityStore } from "@/store/communityStore";
import { currentLocale } from "@/plugins/i18n";
import { enCA, frCA } from "date-fns/locale";
import { intlFormat } from "date-fns";

import SocialNetworks from "./SocialNetworks.vue";
import KeyInfo from "@/components/KeyInfo.vue";


// PROPS
const props = defineProps<{
    startDate?: Date | null;
    endDate?: Date | null;
    timeLeft?: number;
    isClosed: boolean;
    description: string;
    image?: string;
    keyInformation: object;
    isMultiTheme: boolean;
    isPrivate: boolean;
}>();


// CONST
const { t } = useI18n();
const communityStore = useCommunityStore();


// FUNCTIONS
function formatDateTest(date: Date) {
    const locale = currentLocale() === 'fr' ? frCA : enCA
    return intlFormat(date, { locale });
}
</script>

<template>
    <div class="consultation-card">
        <img
            v-if="props.image"
            class="consultation-card__image"
            :src="props.image"
            alt="card-image"
        />
        <div class="consultation-card__content">
            <div class="consultation-card__title-section">
				<span class="consultation-card__title">
                    <h1>{{ props.description }}</h1>
				</span>
            </div>
            
            <div v-if="props.startDate && props.endDate" class="consultation-card__date">
                {{formatDateTest(new Date(props.startDate)) }} - {{formatDateTest(new Date(props.endDate)) }}
            </div>
            <div v-else-if="props.endDate" class="consultation-card__date">
                {{$t('consultation.end-date')}}: {{formatDateTest(new Date(props.endDate)) }}
            </div>
            <div class="consultation-card__infos">
                <div class="consultation-card__key-infos">
                    <KeyInfo
                        type="consultation"
                        isSmall
                        :key-information="props.keyInformation"
                    />
                </div>
                <q-chip square icon="lock" label="Private" v-if="props.isPrivate"/>
            </div>
            <SocialNetworks :community="communityStore.community"/>
        </div>
    </div>
</template>

<style lang="scss">
.consultation-card {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    transition: all 200ms ease;

    &:hover {
        box-shadow: 0 6px 10px 0 rgba($color-neutral-grey-400-50, 0.2);
        // TODO: Improve outline transition, for now, let's only use a shadow
        transition: all 200ms ease;
    }

    &__infos {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    &__content {
        padding: $space-md;
        width: 100%;
    }

    &__tags {
        display: flex;
        gap: $space-sm;
    }

    &__title {
        align-items: center;
        line-height: 100%;
        margin-top: 0px;
        margin-bottom: 0px;

        &-section {
            display: flex;
            align-items: center;
            padding: $space-sm 0;
        }
    }

    &__date {
        color: $color-neutral-grey-400-50;
    }

    &__image {
        width: 100%;
        max-height: 162px;
        object-fit: cover;
        border-radius: 8px 8px 0px 0px;
    }
}
</style>
