export function base64ToFile(base64Image: string, fileName: string) {
    const array = base64Image.split(',');
    const mime = array[0]?.match(/:(.*?);/)?.[1];
    const decodedString = atob(array[1]);
    let stringLength = decodedString.length;
    const u8array = new Uint8Array(stringLength);

    while (stringLength--) {
        u8array[stringLength] = decodedString.charCodeAt(stringLength);
    }

    return new File([u8array], fileName, { type: mime });
}

export function downloadFile(file: Blob | MediaSource) {
    const url = URL.createObjectURL(file);

    const link = document.createElement('a');
    link.href = url;

    if (file instanceof Blob) {
        link.download = file.name;
    } else {
        link.download = 'mediaSourceFile';
    }

    link.click();

    URL.revokeObjectURL(url);
}
