<script setup lang="ts">
// PROPS
const props = defineProps<{
	color?: string;
	iconColor?: string;
	icon?: string;
	iconLeft?: boolean;
	iconRight?: boolean;
}>();
</script>
<template>
	<div class="base-tag" :class="props.color">
		<q-icon 
			class="base-tag__icon" 
			v-if="props.icon && props.iconLeft" 
			:color="props.iconColor" 
			:name="props.icon" 
		/>
		<slot name="title"></slot>
		<q-icon 
			class="base-tag__icon" 
			v-if="props.icon && props.iconRight" 
			:color="props.iconColor" 
			:name="props.icon" 
		/>
		<slot name="number"></slot>

		
	</div>
</template>

<style lang="scss">
.base-tag {
	align-items: center;
	background-color: $color-primary-12;
	border-radius: 4px;
	color: $color-neutral-black;
	display: flex;
	font-size: $small-text;
	min-height: 20px;
	padding: 0 $space-xs;
	width: fit-content;

	&__icon {
		font-size: $body-text;
		padding-right: $space-xs;
	}

	&.green, .agree {
		background: $color-green-12;
	}

	&.red, .disagree {
		background: $color-red-12;
	}

	&.blue {
		background: $color-primary-darken;
	}

	&.orange {
		background: $color-orange-12;
	}

	&.grey {
		background: $color-neutral-grey-200;
		color: $color-neutral-grey-400;
	}

	&.grey-disable {
		background: $color-neutral-grey-100;
		color: $color-neutral-grey-400-50;
	}
	&.white{
		background: $color-neutral-white;
		color: $color-primary-darken;
	}
	&.grey-blue{
		background: $color-neutral-grey-200;
		color: $color-primary-darken;
	}

	&.blue-grey{
		color: $color-neutral-white;
		background-color: $color-primary-darken;
	}

	&.transparent {
		background-color: transparent;
	}
}
</style>
