<script setup lang="ts">
import { computed, ref, onMounted, inject } from "vue";
import { useRoute, useRouter } from "vue-router";
import { Consultation } from "@/store/consultation.interface";
import BaseContextBox from "@/components/base/BaseContextBox.vue";
import BaseButtonToggle from "@/components/base/BaseButtonToggle.vue";
import consultationHeader from "@/components/consultationHeader.vue";
import { useCommunityStore } from '@/store/communityStore';
import { useI18n } from "vue-i18n";
import { setLocale } from "@/plugins/i18n";
import { useUserStore } from "@/store/userStore";
import { getTranslation, removeTimestamp } from "@/utils/data-display-utils";
import { authService } from "@/services/auth.service";
import { storeToRefs } from "pinia";
import { VueCookies } from "vue-cookies";
import { constant } from "@/constant"
import ConsultationPrivateNoAccessDialog from "@/components/dialogs/ConsultationPrivateNoAccessDialog.vue";

const cookies = inject<VueCookies>('$cookies')

// STORE
const communityStore = useCommunityStore();
const userStore = useUserStore();
const { community } = storeToRefs(communityStore)

// CONST
const { t } = useI18n();
const router = useRouter();
const route = useRoute();

// REFS
const toggleStateView = ref('openValue');
const consultationPrivateNoAccessDialogRef = ref(null);

const language = route.params.lang;
if (language && community.value.languageEnabled.includes(language)) {
    cookies.set('locale', language)
    setLocale(language);
}

// COMPUTED
const openState = computed(() => {
    return {
        name: "consultation-state.opened",
        icon: "lock_open",
        quantity: communityStore.openConsultations.length
    }
});

const closedState = computed(() => {
    return {
        name: "consultation-state.closed",
        icon: "lock_clock",
        quantity: communityStore.closedConsultations.length
    }
});

const archivedState = computed(() => {
    return {
        name: "consultation-state.archived",
        icon: "archive",
        quantity: filterConsultations(communityStore.archivedConsultations).length
    }
});

const prelaunchState = computed(() => {
    return {
        name: "consultation-state.preLaunched",
        icon: "pending",
        quantity: filterConsultations(communityStore.preLaunchedConsultations).length
    }
});

const getConsultationState = computed(() => {
    let stateMessage = '';
    switch (toggleStateView.value) {
        case 'openValue':
            stateMessage = t("consultation.no-open-consultation");
            break;
        case 'closedValue':
            stateMessage = t("consultation.no-closed-consultation");
            break;
        case 'archivedValue':
            stateMessage = t("consultation.no-archived-consultation");
            break;
        case 'preLaunchedValue':
            stateMessage = t("consultation.no-preLaunched-consultation");
            break;
        default:
            stateMessage = ''; // Handle default case
    }
    return stateMessage;
});

function filterConsultations(consultations: Consultation[]) {
    return consultations.filter(x => authService.isAdmin() || authService.isEditor(x.id));
}

const filteredConsultations = computed(() => {
    if (!(authService.isAdmin() || authService.isEditor())) {
        switch (toggleStateView.value) {
            case 'closedValue':
                return communityStore.closedConsultations;
            default:
                return communityStore.openConsultations;
        }
    } else {
        switch (toggleStateView.value) {
            case 'closedValue':
                return communityStore.closedConsultations;
            case 'archivedValue':
                return filterConsultations(communityStore.archivedConsultations);
            case 'preLaunchedValue':
                return filterConsultations(communityStore.preLaunchedConsultations);
            default:
                return communityStore.openConsultations;
        }
    }
});

// FUNCTIONS
onMounted(async () => {
    document.title = getTranslation(community.value, 'title');
})

function changeViewState(stateName: string) {
    toggleStateView.value = stateName;
}

const needLogin = ref(false);

function enterToCard(consultation: Consultation) {
    const userIsAllowed = communityStore.isUserAllowedAccessConsultation(consultation, userStore.user)
    if (userIsAllowed.allowed) {
        new Date(consultation.beginDate).getTime()
        router.push({
            name: "consultations",
            params: { slug: consultation.slug }
        });
    } else if (userIsAllowed.reason === 'NotAllowedByAdmin') {
        openConsultationPrivateNoAccessPopup();
    } else if (userIsAllowed.reason === 'login') {
        needLogin.value = true;
        openConsultationPrivateNoAccessPopup()
    }
}

function isConsultationClosed(consultation: Consultation) {
    return communityStore.closedConsultations.some(closedConsultation => closedConsultation.id === consultation.id);
}

function openConsultationPrivateNoAccessPopup() {
    consultationPrivateNoAccessDialogRef.value.openDialog();
}


</script>

<template>
    <div class="community">
        <div class="community__header">
            <img
                :src="removeTimestamp(communityStore.community.bannerFile.url)"
                alt="community-header"
            />
        </div>
        <div class="community__content">
            <div class="community__identification">
                <div class="community__logo">
                    <img
                        :src="removeTimestamp(communityStore.community.logoFile.url)"
                        alt="community-logo"
                    />
                </div>
                <div class="community__title">
                    {{ getTranslation(communityStore.community, 'title') }}
                </div>
                <BaseContextBox class="community__context">
                    <template v-slot:title>
                        <div>{{ $t("context.about") }}</div>
                    </template>
                    <template v-slot:content>
                        <div>
                            {{ getTranslation(communityStore.community, 'description') }}
                        </div>
                    </template>
                </BaseContextBox>
            </div>
            <div class="community__content-wrapper">
                <div class="community__consultation-cards">
                    <BaseButtonToggle
                        class="community__consultation-state"
                        v-model="toggleStateView"
                        :open-state="openState"
                        :closed-state="closedState"
                        :archived-state="archivedState"
                        :preLaunched-state="prelaunchState"
                        :isAdmin="authService.isAdmin() || authService.isEditor()"
                    />
                    <div
                        v-if="filteredConsultations.length"
                        v-for="consultation in filteredConsultations"
                        class="community__consultation-card"
                        @click="enterToCard(consultation)"
                    >
                        <consultation-header
                            :tagText="getTranslation(consultation, 'description')"
                            :tagColor="'grey'"
                            :icon="'edit'"
                            :startDate="new Date(consultation.beginDate).getTime()"
                            :endDate="new Date(consultation.endDate).getTime()"
                            :timeLeft="communityStore.getConsultationTimeLeft(consultation)"
                            :is-closed="isConsultationClosed(consultation)"
                            :description="getTranslation(consultation, 'title')"
                            :image="consultation.bannerFile ? consultation.bannerFile.url : removeTimestamp(communityStore.community.bannerFile.url)"
                            :key-information="consultation.summary"
                            :is-multi-theme="consultation.section.length>1"
                            :is-private="consultation.type === constant.Consultation.Type.Private"
                        />
                    </div>
                    <div class="community__consultation-card--empty" v-else>
                        {{ getConsultationState }}
                    </div>
                </div>
            </div>
        </div>
        <ConsultationPrivateNoAccessDialog ref="consultationPrivateNoAccessDialogRef" :home-link="false"
                                           :need-login="needLogin"/>
    </div>
</template>

<style lang="scss">
.community {
    &__header {
        max-height: 360px;

        img {
            width: 100%;
            height: 100%;
            max-height: 360px;
        }
    }

    &__content {
        padding-top: $space-xl;
    }

    &__content-wrapper {
        display: flex;
        flex-direction: row;

        align-items: center;
        gap: $space-md;
    }

    &__context {
        width: 730px;
    }

    &__identification {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: $space-md;
    }

    &__logo {
        width: $space-2xl;
        height: $space-2xl;
        border-radius: 50%;
        border: 1px solid $color-primary-12;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $color-neutral-white;
        overflow: hidden;

        img {
            max-width: 80%;
            max-height: 80%;
        }
    }

    &__title {
        font-weight: bold;
        font-size: $h3;
        max-width: 580px;
        text-align: center;
        display: flex;
        justify-self: center;
    }

    &__consultation-state {
        margin: $space-md;
    }

    &__consultation-cards {
        min-height: 250px;
        display: flex;
        flex-direction: column;
        margin: $space-md auto $space-2xl auto;
        max-width: 760px;
        width: 100%;
        gap: 20px;
    }

    &__consultation-card {
        background-color: $color-neutral-white;
        border-radius: 8px;
        margin: 0 $space-md;

        &--empty {
            margin-top: $space-lg;
            text-align: center;
            background: $color-neutral-grey-200;
            border-radius: 8px;
            padding: 32px;
            color: $color-neutral-grey-400-50;
        }
    }
}

@media screen and (max-width: $breakpoint-lg) {
    .community {

        &__consultation-state {
            margin: $space-md auto;
        }
    }
}

@media screen and (max-width: $breakpoint-md) {
    .community {

        &__consultation-state {
            margin: $space-md;
        }

        &__consultation-cards {
            max-width: auto;
        }

    }
}

@media screen and (max-width: $breakpoint-sm) {
    .community {

        .key-infos {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;

            &__info.is-title {
                width: 35%;
            }
        }

        &__content {
            display: flex;
            flex-direction: column;
            width: inherit;
        }

        &__identification {
            justify-content: center;
            max-width: 760px;
            margin: $space-md;
        }

        &__context {
            width: 100%;
        }

        &__consultation-cards {
            margin: $space-lg $space-md;
            gap: $space-md;
        }

        &__consultation-card {
            margin: 0;
        }

        &__consultation-state {
            width: 100%;
            margin: 0;
        }

    }
}
</style>
