<script setup lang="ts">

import { ref } from 'vue';
import BaseButton from '@/components/base/BaseButton.vue';
import BaseCheckbox from '@/components/base/BaseCheckbox.vue'

import { useI18n } from 'vue-i18n';

// REFS
//const { t } = useI18n();

// PROPS
const props = defineProps<{
    acceptConditions: boolean
}>();

// EMITS
const emit = defineEmits<{
    (e: "cancel"): void;
    (e: "update", screen: string): void;
    (e: "conditions", value: string): void;
}>();

// FUNCTIONS
function onSubmit (screen) {
    emit('update', screen);
}

function updateValue (value) {
    emit('conditions', value)
}

function closeDialog () {
    emit('cancel');
}
</script>

<template>
    <div class="terms">
        <div class="terms__title">
            {{ $t('terms.terms-of-service')}}
        </div>
        <div class="terms__description">
            {{ $t('dialog.proposition.description')}}
        </div>
        <div class="terms__checkbox">
            <BaseCheckbox :model="props.acceptConditions" :label="$t('terms.accept-conditions')" @update="updateValue($event)"/> 
        </div>
        <div class="terms__buttons">
            <BaseButton @click="closeDialog">{{ $t('buttons.cancel')}}</BaseButton>
            <BaseButton :disabled="!props.acceptConditions" class="action" @click="onSubmit('proposition')">{{ $t('buttons.next')}}</BaseButton>
        </div>
    </div>
</template>

<style lang="scss">
.terms {
    display: flex;
    flex-direction: column;
    gap: $space-sm;

    &__title {
        font-size: $header;
        font-weight: bold;
    }

    &__buttons {
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: $space-sm;
    }
}

@media screen and (min-width: $breakpoint-md) {
	.terms {
        &__buttons {
            justify-content: end;
            gap: $space-lg;
            padding: 0 $space-lg;
        }
    }
}

</style>