<script setup lang="ts">

import BaseQuestionSlider from "@/components/base/BaseQuestionSlider.vue";

// PROPS
const props = defineProps<{
    levels: any,
    statistiques?: any,
    showResult?: boolean
}>();


// EMITS
const emit = defineEmits<{
    (e: "change", response: any): void;
}>();

// FUNCTIONS
function changeValue(value: any) {
    emit("change", value);
}

console.log(props.statistiques)
</script>

<template>
    <div class="slider-container">
        <div class="slider-container__title q-mb-md" v-if="!showResult">
            <span>{{ $t('form.steps.levels') }}</span>
        </div>

        <div v-if="!showResult" class="question">
            <div class="slider-container__slider" v-for="slider in props.levels">

                <BaseQuestionSlider :slider="slider" @change="changeValue($event)"/>

            </div>
        </div>

        <div v-if="showResult" class="result">
            <div class="result_container" v-for="slider in props.levels">

                <BaseQuestionSlider v-if="statistiques && statistiques.step[slider.answerId][0]" :slider="slider"
                                    results
                                    :average="statistiques.step[slider.answerId][0].average"/>
            </div>
        </div>

    </div>

</template>

<style lang="scss">
.slider-container {
    width: 100%;
    border-radius: 8px;
    margin: $space-sm 0;
    padding: $space-xs;

    &__result {
        display: flex;
        flex-direction: column;
    }

    .result {
        display: flex;
        flex-direction: row;
        width: inherit;
        gap: $space-md;

        &_container {
            width: 100%;
        }
    }
}
</style>