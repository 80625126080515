<script setup lang="ts">
import { computed } from "vue";
import { paths } from "@/utils/paths";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import BaseButton from "@/components/base/BaseButton.vue";

// CONST
const { t } = useI18n();
const router = useRouter();

// COMPUTED
const text = computed(() => {
	return `${t("not-found.content")}`;
});

const textLines = computed(() => {
	const lines = [];
	let currentLine = "";
	for (let i = 0; i < text.value.length; i++) {
		const char = text.value.charAt(i);
		currentLine += char;
		if (char === "," || char === ".") {
			lines.push(currentLine.trim());
			currentLine = "";
		}
	}
	if (currentLine.length > 0) {
		lines.push(currentLine.trim());
	}
	return lines;
});

function backToHome() {
	router.push(paths.root);
}

function joinUsMail() {
	window.location.href = `mailto:info@cocoriko.org`;
}
</script>

<template>
	<div class="not-found">
		<img
			class="not-found__logo"
			src="@/assets/logos/logo-cocoriko.svg"
			alt="logo-cocoriko"
		/>
		<div class="not-found__title">
			{{ $t("not-found.title") }}
		</div>
		<div class="not-found__content">
			<div v-for="(line, index) in textLines" :key="index">
				{{ line }}
			</div>
		</div>

		<div class="not-found__buttons">
			<BaseButton
				primary
				:text="$t('not-found.back-home')"
				:right-icon="'o_arrow_forward'"
				@click="backToHome()"
			/>
			<BaseButton
				secondary
				:text="$t('not-found.join-us')"
				@click="joinUsMail()"
			/>
		</div>
	</div>
</template>

<style lang="scss">
.not-found {
	margin-left: $space-3xl;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	height: 100%;
	flex-wrap: wrap;

	&__logo {
		height: 64px;
	}

	&__title {
		font-size: $h1;
		font-weight: bold;
		margin: 32px 0;
	}

	&__content {
		font-size: $small-title;
		font-weight: bold;
	}

	&__buttons {
		display: flex;
		gap: $space-md;
		margin: $space-xl 0;
	}

	&__button {
		color: white !important;

		&:hover {
			text-decoration: none;
		}
	}
}

@media screen and (max-width: $breakpoint-lg) {
	.not-found {
		align-items: center;
		margin: 0;

		&__title {
			text-align: center;
		}

		&__content {
			text-align: center;
		}
	}
}

@media screen and (max-width: $breakpoint-sm) {
	.not-found {
		align-items: center;
		margin: 0;

		&__logo {
			height: 48px;
		}

		&__title {
			font-size: $large-title;
			text-align: center;
		}

		&__content {
			font-size: $sub-header;
			text-align: center;
		}
	}
}
</style>
