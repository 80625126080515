import { LanguageTranslation } from "@/store/language.interface";

export interface DTOCustomPage {
    id: number;
    slug: string;
    created_at: string;
    updated_at: string;
    status: number;
    type: number;
    community_id: number;
    title: LanguageTranslation;
    content: LanguageTranslation;
}

export interface CustomPage {
    id: number;
    slug: string;
    createdAt: string;
    updatedAt: string;
    status: number;
    type: number;
    communityId: number;
    title: LanguageTranslation;
    content: LanguageTranslation;
}

export function parseCustomPage(customPageDTO: DTOCustomPage): CustomPage {
    return {
        id: customPageDTO.id,
        slug: customPageDTO.slug,
        status: customPageDTO.status,
        type: customPageDTO.type,
        communityId: customPageDTO.community_id,
        title: customPageDTO.title,
        content: customPageDTO.content,
        createdAt: customPageDTO.created_at,
        updatedAt: customPageDTO.updated_at,
    };
}