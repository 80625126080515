<script setup lang="ts">

// PROPS
const props = defineProps<{
  status: string;
  message: string;
}>();

</script>

<template>
    <div class="base-notification">
        <div v-if="status === 'success'" class="base-notification__success">
            <q-icon name="check"/>
        </div>
        <div v-else class="base-notification__error">
            <q-icon name="error_outline"/>
        </div>
        <div class="base-notification__content">
            <div v-if="status === 'success'" class="base-notification__content-title">
                Success
            </div>
            <div v-else class="base-notification__content-title">
                Error
            </div>
            <div class="base-notification__content-message">
                {{ props.message }}
            </div>
        </div>
        
    </div>
</template>

<style lang="scss">
.base-notification {
    position: fixed;
    bottom: 10px;
    right: 10px;
    display: flex;
    width: 280px;
    height: auto;
    z-index: 2;
    background-color: $color-neutral-white;
    border-radius: 8px;

    .q-icon{
        font-size: $large-title;
        color: $color-neutral-white;
    }

    &__success {
        background-color: $color-green;
        border-radius: 8px 0px 0px 8px;
        width: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__error {
        background-color: $color-red;
        border-radius: 8px 0px 0px 8px;
        width: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__content {
        display: flex;
        flex-direction: column;
        padding: $space-sm;

        &-title {
            width: 100%;
            font-weight: bold;
        } 
    }
}
</style>