<script setup lang="ts">

import { ref } from "vue";
import BaseDialog from "@/components/base/BaseDialog.vue";
import { useI18n } from 'vue-i18n';


// REFS
const { t } = useI18n();
const commentFlaggedDialog = ref<InstanceType<typeof BaseDialog>>();


// FUNCTIONS
function openDialog() {
    commentFlaggedDialog.value?.openDialog();
}

function closeDialog() {
    commentFlaggedDialog.value?.closeDialog();
}


defineExpose({
    openDialog,
    closeDialog,
});

</script>

<template>
    <BaseDialog ref="commentFlaggedDialog">
        <template v-slot:title>
            <div class="demo-title">
                {{ t('dialog.commentFlagged.title') }}
            </div>
        </template>
        <template v-slot:content>
            <div>
                <p>
                    {{ t('dialog.commentFlagged.content') }}
                </p>
                <a :href="t('dialog.commentFlagged.link_href')" target="_blank">{{
                        t('dialog.commentFlagged.link')
                    }}</a>
            </div>
        </template>
    </BaseDialog>
</template>

<style lang="scss">
.demo {


    &-title {
        font-size: $medium-title;
    }

}

.base-dialog {
    background-color: $color-neutral-background !important;
}

</style>