<script setup lang="ts">

// PROPS
import { onMounted, ref, watch } from "vue";

const props = defineProps<{
    openQuestion: any,
    response?: any,
    results?: any,
    showResult?: boolean
}>();

// REFS
let textAreaValue = ref<string>(props.response && props.response[0].value_text);
let valueObject = ref<any>({
    valueText: props.response && props.response[0].value_text,
    answerId: props.openQuestion.answerId
});

onMounted(() => {
    if (props.openQuestion.results) {
        textAreaValue.value = props.openQuestion.results[props.openQuestion.answerId];
    }
});


// EMITS
const emit = defineEmits<{
    (e: "change", response: any): void;
}>();

// FUNCTIONS
function changeValue(value: any) {
    textAreaValue.value = value
    valueObject.value.valueText = value;
    emit("change", valueObject.value);
}


// WATCH
watch(textAreaValue, (newValue) => {
        valueObject.value.valueText = newValue
    }
);

</script>

<template>
    <div class="slider-container">
        <label for="open-question">
            <q-input
                @update:model-value="changeValue"
                type="textarea"
                :disable="showResult"
                square
                outlined
                name="open-question"
                :model-value="textAreaValue"
                :placeholder="$t('form.form-placeholder')"
            />
        </label>
    </div>

</template>

<style lang="scss">
.slider-container {
    width: 100%;
    //background-color: $color-neutral-grey-100;
    border-radius: 8px;
    margin: $space-sm 0;
    padding: $space-xs;

    &__results {
        display: flex;
        flex-direction: column;

        &-data {
            display: flex;
            justify-content: space-between;
        }
    }
}
</style>