<script setup lang="ts">

import { useI18n } from "vue-i18n";

import { QSpinnerTail } from 'quasar';

// CONST
const { t } = useI18n();

</script>

<template>
    <div class="loading-page">
        <q-spinner-tail
          color="primary"
          size="4em"
        />
        <div class="loading-page__text">{{ $t('loading') }}</div>
    </div>
</template>

<style lang="scss">
.loading-page {
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &__text {
        font-size: $small-title;
    }
}

@media screen and (max-width: $breakpoint-sm) {
	.loading-page {
        .q-spinner{
            height: 3em;
            width: 3em;
        }
    }
}
</style>