<script setup lang="ts">

import { onMounted, ref } from "vue";

import BaseDialog from "@/components/base/BaseDialog.vue";
import BaseButton from '@/components/base/BaseButton.vue';
import demographicQuestions from '@/components/questions/demographicQuestions.vue';
import nameQuestions from '@/components/questions/nameQuestions.vue';

import { useForm } from 'vee-validate';
import * as yup from 'yup';
import { useI18n } from 'vue-i18n';
import { useUserStore } from "@/store/userStore";
import { userService } from "@/services/user.service";


// REFS
const { t } = useI18n();
const demographicDialogRef = ref<InstanceType<typeof BaseDialog>>();
const hasUserName = ref<boolean>();
const regex = /^[A-Za-z]\d[A-Za-z] \d[A-Za-z]\d$/;
const userStore = useUserStore();


// LIFECYCLE
onMounted(() => {
    userStore.loadGenders();
    hasUserName.value = userStore.validateUserName;
});

// VALIDATIONS
const validationSchema = yup.object().shape({
    postalCode: yup.string().matches(regex, () => t('errors.type.postal-code')).required(() => t('errors.required')),
    gender: yup.object().required(() => t('errors.required')),
    birthYear: yup.number()
        .required(() => t('errors.required'))
        .integer(() => t('errors.birth-date.integer'))
        .typeError(() => t('errors.birth-date.number'))
        .min(1900, () => t('errors.birth-date.min'))
        .max(new Date().getFullYear(), () => t('errors.birth-date.max')),
    firstName: yup.string().required(() => t('errors.required')),
    lastName: yup.string().required(() => t('errors.required')),
})

const { handleSubmit, meta, resetForm } = useForm({
    validationSchema,
});


// FUNCTIONS
function openDialog() {
    demographicDialogRef.value?.openDialog();
}

function closeDialog() {
    demographicDialogRef.value?.closeDialog();
    resetForm();
}

const onSubmit = handleSubmit(async (values) => {
    const userData = {
        year_birth: values.birthYear,
        postal_code: values.postalCode,
        gender: values.gender.value,
        email: userStore.user.email,
        last_name: values.lastName,
        first_name: values.firstName
    }
    const response = await userService.updateUser(userData);
    if (response.success) {
        closeDialog();
    }
});

defineExpose({
    openDialog,
    closeDialog,
});

</script>

<template>
    <BaseDialog ref="demographicDialogRef">
        <template v-slot:title>
            <div class="demo-title">
                {{ $t('demographics.title') }}
            </div>
        </template>
        <template v-slot:content>
            <form class="demo-data">
                <div class="demo-data__info">
                    <div class="demo-data__name-label">
                        {{ $t('demographics.info') }}
                    </div>
                    <demographic-questions/>
                </div>
                <div class="demo-data__name">
                    <div class="demo-data__name-label">
                        {{ $t('demographics.comment') }}
                    </div>
                    <name-questions/>
                </div>
                <div class="demo-actions">
                    <BaseButton :disabled="!meta.valid" class="action" @click="onSubmit">{{ $t('buttons.submit') }}</BaseButton>
                </div>
            </form>
        </template>
    </BaseDialog>
</template>

<style lang="scss">
.demo {
    &-data {
        display: flex;
        flex-direction: column;
        gap: 10px;

        &__name {
            padding: $space-sm 0 0;
        }

        &__name-label, &__info-label {
            padding: 0 0 $space-sm;
            font-size: $small-text;
        }
    }

    &-title {
        font-size: $small-title;
    }

    &-actions {
        display: flex;
        justify-content: end;
        margin-top: $space-md;
    }
}

.base-dialog {
    background-color: $color-neutral-background !important;

    &__title {
        padding: $space-md $space-xl 0 $space-md !important;
    }

    &__content {
        padding: $space-sm $space-md $space-md !important;
    }
}

.error {
    color: $color-red;
}
</style>