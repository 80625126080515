<script setup lang="ts">
import { computed, ref } from "vue";
import BaseDialog from "@/components/base/BaseDialog.vue";
import BaseButton from '@/components/base/BaseButton.vue';
import { useForm } from 'vee-validate';
import { useI18n } from 'vue-i18n';
import BaseInputVerificationCode from "@/components/base/BaseInputVerificationCode.vue";
import {mailingListService} from "@/services/mailingList.service";

const props = defineProps<{
  email: string;
  consultation_id: number
}>();

// REFS
const { t } = useI18n();
const confirmEmailSubscriptionDialogRef = ref<InstanceType<typeof BaseDialog>>();

const { handleSubmit, meta } = useForm();
const code = ref<string>('');
// New ref to track error state
const hasError = ref(false);
// FUNCTIONS
function openDialog() {
  confirmEmailSubscriptionDialogRef.value?.openDialog();
  hasError.value = false; // Reset error state when opening dialog
  code.value = ''
}

function closeDialog() {
  confirmEmailSubscriptionDialogRef.value?.closeDialog();
}

const submit = handleSubmit(async () => {
  const response = await mailingListService.checkVerificationCode(props.email, code.value);
  if(response.success){
    await mailingListService.subscribe('consultation', props.consultation_id, response.data.user_id);
    closeDialog();
  }else{
    hasError.value = true; // Set error state to true
  }
});

// Check if the code is a valid number
const codeValid = computed(() => {
  const isNumeric = /^[0-9]+$/.test(code.value); // Regular expression to check if code contains only digits
  return (
    isNumeric &&
    code.value !== null &&
    code.value.length === 5
  );
});

defineExpose({
  openDialog,
  closeDialog,
});
</script>

<template>
  <BaseDialog ref="confirmEmailSubscriptionDialogRef" :have-close-button="true" :persistent="true">
    <template v-slot:title>
      <div class="confirm-email-subscription-dialog-title q-ma-md">
        {{ t('dialog.confirmEmailSubscription.title') }}
      </div>
    </template>
    <template v-slot:content>
      <div class="confirm-email-subscription-dialog-content">
        <div class="confirm-email-subscription-dialog-content__label q-ma-md">
           {{ t('dialog.confirmEmailSubscription.inputLabel') }}
        </div>
        <div class="confirm-email-subscription-dialog-content__input q-ma-sm">
          <BaseInputVerificationCode  v-model="code"/>
        </div>
        <div v-if="hasError" class="confirm-email-subscription-dialog-content__error-message text-red-7 q">
           {{ t('dialog.confirmEmailSubscription.wrongCode') }}
        </div>
      </div>
      <div class="row no-wrap justify-end q-gutter-md q-mt-md">
        <BaseButton color="primary" @click="submit" :disabled="!codeValid">
          {{ t('dialog.confirmEmailSubscription.checkCode') }}
        </BaseButton>
      </div>
    </template>
  </BaseDialog>
</template>
<style>
.confirm-email-subscription-dialog-content{
  display: flex;
  flex-direction: column;
  align-items: center;

}


</style>